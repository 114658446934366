import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo2 from "../../assets/img/rhokit-logo.png";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  createCustomerWallet,
  customerCards,
  doKYC,
  getAllowedWalletTypes,
} from "../../lib/astra";

export default function VerifyKYC() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [walletDetailsCreated, setAllowedWalletTypes] = useState({});
  const [user, setUser] = useState({});
  let { userId } = useParams();
  const hasRunOnce = useRef(false);
  const [unverifiedKycsResponse, setUnverifiedKycsResponse] = useState([]);

  const verifyUserIdentity = async () => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      setUser(userSnapshot.data());
      try {
        await doKYC(userSnapshot.data().customerId).then(async (response) => {
          if (response.length > 0) {
            response.forEach(async (kyc) => {
              if (!kyc.passed) {
                setUnverifiedKycsResponse((prev) => [...prev, kyc]);
              }
              setLoading(false);
            });
          }
        });
        await getAllowedWalletTypes(userSnapshot.data().customerId).then(
          async (response) => {
            const allowedWallets = response.filter(
              (wallet) =>
                wallet.allowed &&
                (wallet.walletTypeId === 5080 || // DTB TIER ONE
                  wallet.walletTypeId === 5081 || // DTB TIER TWO
                  wallet.walletTypeId === 5082) // DTB TIER THREE
            );

            if (allowedWallets.length === 0) {
              toast.error(
                "No wallet type allowed to create. Please upload all required documents and try again."
              );
              toast.error(
                "Please reach out to us if you have any questions."
              );
              return;
            }

            // if allowedWallets has walletTypeId that is equal to 5082 then use it
            let walletToCreate = allowedWallets.find(
              (wallet) => wallet.walletTypeId === 5082
            );

            if (!walletToCreate) {
              // if allowedWallets has walletTypeId that is equal to 5081 then use it
              walletToCreate = allowedWallets.find(
                (wallet) => wallet.walletTypeId === 5081
              );
            }

            if (!walletToCreate) {
              // if allowedWallets has walletTypeId that is equal to 5080 then use it
              walletToCreate = allowedWallets.find(
                (wallet) => wallet.walletTypeId === 5080
              );
            }

            await createCustomerWallet(
              userSnapshot.data().customerId,
              walletToCreate.walletTypeId,
              userSnapshot.data().firstName,
              userSnapshot.data().lastName
            ).then(async (response) => {
              setLoading(false);
              setAllowedWalletTypes(response);
              toast.success("Wallet created successfully");
              // create wallet user
              await addDoc(collection(db, "user-wallets"), {
                ...response,
              });
            });
          }
        );
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("User not found");
      navigate("/register");
    }
  };

  useEffect(() => {
    if (!hasRunOnce.current) {
      verifyUserIdentity();
      hasRunOnce.current = true;
    }
  }, []);

  return (
    <div className="register">
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <img src={logo2} alt="Logo" />
              </div>

              <div className="account-box">
                <div className="account-wrapper">
                  <p className="account-subtitle">Verifying your identity</p>
                  {unverifiedKycsResponse.length > 0 &&
                    unverifiedKycsResponse.map(
                      (kyc, i) =>
                        kyc.avps.length > 0 &&
                        kyc.avps[0].att !== "PROOF_OF_ADDRESS_DOCID" && (
                          <div
                            key={i}
                            className="alert alert-danger"
                            role="alert"
                          >
                            {kyc.avps[0].att} could not be verified. Please
                            click{" "}
                            <Link
                              className="text-primary"
                              to={`/register/${userId}/upload-documents`}
                            >
                              here
                            </Link>{" "}
                            to reupload the document
                          </div>
                        )
                    )}
                  <div>
                    {loading && (
                      <div className="text-center">
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: "3rem", height: "3rem" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                    {Object.keys(walletDetailsCreated).length > 0 && (
                      <>
                        <div className="alert alert-success" role="alert">
                          A wallet has been created for you. Please note down
                          the details below
                        </div>
                        <h4>Wallets Created</h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-block">
                              <div className="row">
                                <div className="col">
                                  <label>Name</label>
                                </div>
                                <div className="col">
                                  <label>Wallet Type</label>
                                </div>
                                <div className="col">
                                  <label>Account No</label>
                                </div>
                                <div className="col">
                                  <label>Balance</label>
                                </div>
                              </div>
                              <div
                                className="row"
                                key={walletDetailsCreated.accountNumber}
                              >
                                <div className="col">
                                  <p>{walletDetailsCreated.name}</p>
                                </div>
                                <div className="col">
                                  <p>{walletDetailsCreated.walletTypeId}</p>
                                </div>
                                <div className="col">
                                  <p>{walletDetailsCreated.accountNumber}</p>
                                </div>
                                <div className="col">
                                  <p>{walletDetailsCreated.currentBalance}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <button
                              className="btn btn-primary"
                              onClick={() => navigate("/")}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}

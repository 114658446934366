import React, {useState, useEffect, useContext}  from "react";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc, getDoc, query, where } from 'firebase/firestore';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import SubmittedTimeSheetForm from "./submittedTimeSheetForm.js";
import { UserContext } from "../../../InitialPage/App.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const SubmittedTimeSheets = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredSearchData, setFilteredSearchData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [formData, setFormData] = useState({
        employeeName: '',
        projectName: '',
        timesheetDescription:'',
    });

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const [editTimesheetId, setEditTimesheetId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const handleStatusChange = async (timesheetId, newStatus) => {
        // const { userRole } = useContext(UserContext);

        try {
            const user = auth.currentUser; 
            let approvingRole = '';

            if (user) {
                // Fetch the user document from the "users" collection
                const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', user.email)));
                if (!userQuerySnapshot.empty) {
                    const userData = userQuerySnapshot.docs[0].data();
                    if (userData.employeeName) {
                        approvingRole = userData.employeeName;
                    } else if (userData.firstName && userData.lastName) {
                        approvingRole = `${userData.firstName} ${userData.lastName}`;
                    } else if (userData.companyName) {
                        approvingRole = userData.companyName;
                    } else {
                        approvingRole = 'Unknown User';
                    }
                } else {
                    approvingRole = 'Unknown User';
                }
            } else {
                approvingRole = 'Anonymous User';
            }

            // Update the status of the timesheet document
            await updateDoc(doc(db, 'timesheets', timesheetId), { status: newStatus, approvingRole });

            // Update the local data state with the updated status and role signature
            const updatedData = data.map(item => {
                if (item.id === itemId) {
                    return { ...item, status: newStatus, approvingRole };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the timesheet data in the database
            await updateDoc(doc(db, 'timesheets', editTimesheetId), formData);

            // Clear the form data and reset the editTimesheetId state
            setFormData({
                employeeName: '',
                projectName: '',
                currentDate: '',
                projectTotalHours: '',
                hoursWorked: '',
                projectRemainingHours: '',
                projectDeadline: '',
                timesheetDescription:'',
            });
            setEditTimesheetId(null);
        } catch (error) {
            console.error('Error updating timesheet:', error);
        }
    };

    const handleDeleteTimesheets = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'timesheets', editTimesheetId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editTimesheetId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting timesheet:', error);
        }
    };
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });

            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            const querySnapshot = await getDocs(collection(db, 'projects'));
            const projectList = [];
            // querySnapshot.forEach((doc) => {
            //     projectList.push({ id: doc.id, ...doc.data() });
            // });
            querySnapshot.forEach((doc) => {
                const projectData = doc.data();

                // Check if the employee is active and not deleted
                if (!projectData.deleted &&
                    projectData.organisationId === userData[0]?.organisationId
                ) {
                    projectList.push({ id: doc.id, ...projectData });
                }
            });
            setProjects(projectList);
        };
        fetchProjects();
    }, [userData]);

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];
            querySnapshot.forEach((doc) => {
                const departmentData = doc.data();
                // Check if the employee is active and not deleted
                if (!departmentData.deleted && departmentData.organisationId === userData[0]?.organisationId) {
                    departmentList.push({ id: doc.id, ...departmentData });
                }
            });
            setDepartments(departmentList);
        };
        fetchDepartments();
    }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'timesheets');
                let querySnapshot;
    
                if (userData && userData[0]?.role !== 'sys-admin') {
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    querySnapshot = await getDocs(colRef);
                }
    
                const allLeaves = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
    
                let filteredLeaves = allLeaves;
    
                if (userData && userData[0]?.role === 'hod') {
                    const userDepartments = departments.filter(department => department.departmentHead === userData[0]?.employeeName);
                    const employeeNames = userDepartments.flatMap(department => department.employeeName);
    
                    filteredLeaves = allLeaves.filter(leave => employeeNames.includes(leave.employeeName));
                }
    
                setData(filteredLeaves);
                setEntriesCount(filteredLeaves.length);
    
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
    
        fetchData();
    }, [userData, departments, loading, searchInput, entriesPerPage, editTimesheetId]);
    

    const selectedMonthYear = selectedDate ? format(selectedDate, 'MMMM yyyy') : ''; // Format selectedDate to 'yyyy-MM' format

    const filteredData = searchInput
    ? filteredSearchData.filter(item => {
        if (selectedDate) {
            const selectedMonthYear = format(selectedDate, 'yyyy-MM');
            const itemMonthYear = item.currentDate ? item.currentDate.slice(0, 7) : '';
            return itemMonthYear === selectedMonthYear && !item.deleted;
        } else {
            return !item.deleted; // Show all entries when selectedDate is null
        }
    })
    : data.filter(item => {
        if (selectedDate) {
            const selectedMonthYear = format(selectedDate, 'yyyy-MM');
            const itemMonthYear = item.currentDate ? item.currentDate.slice(0, 7) : '';
            return itemMonthYear === selectedMonthYear && !item.deleted;
        } else {
            return !item.deleted; // Show all entries when selectedDate is null
        }
    });

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            (item.employeeName && item.employeeName.toLowerCase().includes(input.toLowerCase())) ||
            (item.projectName && item.projectName.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredSearchData(filtered);
    };

    const clearSearch = () => {
        setSearchInput('');
        setSelectedDate(null); // Set selectedDate to null to have no date selected
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Timesheets</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/timesheets/submitted-timesheets">Timesheets</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Submitted Timesheets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                                <label htmlFor="monthPicker">Select Month and Year:</label>
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    showMonthYearPicker
                                                    dateFormat="yyyy-MM"
                                                    className="form-control"
                                                />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <button className="btn btn-custom1 mt-4" onClick={clearSearch}>Clear</button>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        <th>Date</th>
                                                        <th>Projects</th>
                                                        <th>Total Hours</th>
                                                        <th>Remaining Hours</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Approved By</th>
                                                        <th className="text-end">Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filteredData.slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.employeeName}</td>
                                                            <td>{item.currentDate}</td>
                                                            <td>{item.projectName}</td>
                                                            <td>{item.projectTotalHours}</td>
                                                            <td>{item.projectRemainingHours}</td>
                                                            <td>{item.timesheetDescription}</td>
                                                            <td>
                                                                <div className="dropdown action-label text-center">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'approved' ? 'success' : item.status === 'disapproved' ? 'danger' : 'purple'}`}></i>
                                                                        {item.status === 'approved' ? 'Approved' : item.status === 'disapproved' ? 'Denied' : 'New'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'approved')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Approve
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'disapproved')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deny
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'new')}>
                                                                            <i className="fa-regular fa-circle-dot text-purple me-1"></i> New
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="signature-cell">{item.approvingRole}</td>
                                                            <td>
                                                                <div className="dropdown dropdown-action text-end">
                                                                    <Link
                                                                        to="#"
                                                                        className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="material-icons">more_vert</i>
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#edit_todaywork"
                                                                            onClick={() => setEditTimesheetId(item.id)}>
                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                        </Link>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#delete_workdetail"
                                                                            onClick={() => setEditTimesheetId(item.id)}>
                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-footer">
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_info">
                                            {searchInput
                                                ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <SubmittedTimeSheetForm/>

                            {/* Edit Today Work Modal */}
                            <div
                                id="edit_todaywork"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div
                                    className="modal-dialog modal-dialog-centered modal-lg"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Work Details</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="input-block col-sm-12">
                                                        <label>
                                                            Employee <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select form-control"
                                                            name="employeeName"
                                                            value={formData.employeeName}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">Select an employee</option>
                                                            {employees.map(employee => (
                                                                <option>
                                                                    {employee.employeeName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="input-block col-sm-12">
                                                        <label>
                                                            Project <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select form-control"
                                                            name="projectName"
                                                            value={formData.projectName}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">Select a project</option>
                                                            {projects.map(project => (
                                                                <option key={project.id} value={project.projectName}>
                                                                    {project.projectName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="input-block">
                                                    <label>
                                                        Description <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea
                                                        rows={4}
                                                        className="form-control"
                                                        type="text"
                                                        value={formData.timesheetDescription}
                                                        onChange={handleInputChange}
                                                        name="timesheetDescription"
                                                    />
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Today Work Modal */}

                            {/* Delete Today Work Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_workdetail"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Work Details</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDeleteTimesheets}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Delete Today Work Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubmittedTimeSheets;

import React, { useState, useEffect }  from "react";
import { db } from "../../firebase.js";
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import {Avatar_02} from "../../Entryfile/imagepath.js";
import { ToastContainer, toast, Slide } from "react-toastify";
import {countries} from "countries-list";
import { banks } from "../../lib/astra.js";
const auth = getAuth();

const EmployeeProfileForm = () => {
    const currentUser = auth.currentUser;
    const storage = getStorage();
    const [selfieFile, setSelfieFile] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [countryNames, setCountryNames] = useState([]);

    useEffect(() => {
        // Extract country names from countries object
        const countryNamesArray = Object.values(countries).map(country => country.name);
        // Set the countryNames state with the list of country names
        setCountryNames(countryNamesArray);
    }, []); 

    const [formData, setFormData] = useState({
        selfieURL: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: {
            line1: '',
            city: '',
            country: '',
        },
        maritalStatus: '',
        employmentOfSpouse: '',
        noOfChildren: '',
        familyMembers:{
            familyName1: '',
            relationship1: '',
            familyPhone1: '',
            familyName2: '',
            relationship2: '',
            familyPhone2: '',
        },
        bank: {
            bankName: '',
            accountNumber: '',
            accountName: '',
            bankBranch: '',
            mpesa: '',
        }
    });

    const handleSelfieChange = (e) => {
        const file = e.target.files[0];
        setSelfieFile(file);

        // Read the image file and convert it to a URL
        const reader = new FileReader();
        reader.onload = () => {
            // Update the formData with the new selfieURL
            setFormData(prevData => ({
                ...prevData,
                selfieURL: reader.result
            }));
        };
        reader.readAsDataURL(file);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'city' || name === 'country' || name=== 'line1') {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [name]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleEmergencyContactInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            familyMembers: {
                ...formData.familyMembers,
                [name]: value,
            },
        });
    };

    const handleBankInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            bank: {
                ...formData.bank,
                [name]: value,
            },
        });
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (currentUser && currentUser.email) {
                    const userEmail = currentUser.email;
                    console.log('Fetching data for user email:', userEmail);

                    const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", userEmail)));

                    if (!querySnapshot.empty) {
                        // Assuming there's only one document with the given email
                        const userData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
                        // setFormData(userData);
                        setFormData(prevData => ({
                            ...prevData,
                            ...userData
                        }));
                        console.log('Fetched data:', userData);
                    } else {
                        console.log('No document found for user email:', userEmail);
                    }
                } else {
                    console.log('No current user or email provided.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentUser]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentUser && currentUser.email) {
                const userEmail = currentUser.email;
                console.log('Fetching data for user email:', userEmail);

                const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", userEmail)));

                if (!querySnapshot.empty) {
                    // Assuming there's only one document with the given email
                    const userId = querySnapshot.docs[0].id;
                    console.log('User ID:', userId);

                    // Update the document with the edited form data
                    const colRef = doc(db, 'users', userId);
                    await updateDoc(colRef, formData);

                    toast.success('Profile updated successfully.');
                } else {
                    console.log('No document found for user email:', userEmail);
                }
            } else {
                console.log('No current user or email provided.');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };


    return (
        <>
            {/* Profile Modal */}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="profile_info"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Profile Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile-img-wrap edit-img">
                                            <img
                                                className="inline-block"
                                                src={formData.selfieURL || Avatar_02}
                                                // alt="user"
                                            />
                                            <div className="fileupload btn">
                                                <span className="btn-text">edit</span>
                                                <input className="upload" type="file" />
                                                <input
                                                    className="upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleSelfieChange}
                                                    // value={formData.selfieURL}
                                                />

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="firstName"
                                                        name="firstName"
                                                        autoComplete="off"
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="lastName"
                                                        autoComplete="off"
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        autoComplete="off"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        autoComplete="off"
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Address Line</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="line1"
                                                        autoComplete="off"
                                                        value={formData.address.line1}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="city"
                                                        autoComplete="off"
                                                        value={formData.address.city}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input-block">
                                                    <label>Country</label>
                                                    <select
                                                        className="form-select form-control"
                                                        name="country"
                                                        value={formData.address.country}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {countryNames.sort().map((country) => (
                                                        <option key={country} value={country}>
                                                            {country}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* /Profile Modal */}

            {/* Personal Info Modal */}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="personal_info_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Personal Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Marital status <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="maritalStatus"
                                                autoComplete="off"
                                                value={formData.maritalStatus}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">-</option>
                                                <option value="S">Single</option>
                                                <option value="M">Married</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Employment of spouse</label>
                                            <select
                                                className="form-select form-control"
                                                name="employmentOfSpouse"
                                                autoComplete="off"
                                                value={formData.employmentOfSpouse}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">-</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>No. of children </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="noOfChildren"
                                                autoComplete="off"
                                                value={formData.noOfChildren}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* /Personal Info Modal */}

            {/* Emergency Contact Modal */}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="emergency_contact_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Emergency Contact</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Primary Contact</h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="familyName1"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.familyName1}
                                                        onChange={handleEmergencyContactInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Relationship{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control form-select"
                                                        name="relationship1"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.relationship1}
                                                        onChange={handleEmergencyContactInputChange}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Son">Son</option>
                                                        <option value="Daughter">Daughter</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Phone <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="familyPhone1"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.familyPhone1}
                                                        onChange={handleEmergencyContactInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Secondary Contact</h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="familyName2"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.familyName2}
                                                        onChange={handleEmergencyContactInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Relationship{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control form-select"
                                                        name="relationship2"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.relationship2}
                                                        onChange={handleEmergencyContactInputChange}
                                                    >
                                                        <option value="">-</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Son">Son</option>
                                                        <option value="Daughter">Daughter</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>
                                                        Phone <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="familyPhone2"
                                                        autoComplete="off"
                                                        value={formData.familyMembers.familyPhone2}
                                                        onChange={handleEmergencyContactInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* /Emergency Contact Modal */}

            {/* Bank Info Modal */}
            {formData && Object.keys(formData).length > 0 && (
            <div
                id="wallet_modal"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Bank Information</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>
                                                Bank Name
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="bankName"
                                                autoComplete="off"
                                                value={formData.bank.bankName}
                                                onChange={handleBankInputChange}
                                            >
                                                <option value="">-</option>
                                                {banks.length > 0 &&
                                                    banks
                                                    .sort((a, b) =>
                                                        a.bankName.localeCompare(b.bankName)
                                                    )
                                                    .map((bank, i) => (
                                                        <option value={bank.bankName} key={i}>
                                                        {bank.bankName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Account Number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="accountNumber"
                                                autoComplete="off"
                                                value={formData.bank.accountNumber}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Account Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="accountName"
                                                autoComplete="off"
                                                value={formData.bank.accountName}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Bank Branch</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="bankBranch"
                                                autoComplete="off"
                                                value={formData.bank.bankBranch}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>M-Pesa Number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="mpesa"
                                                autoComplete="off"
                                                value={formData.bank.mpesa}
                                                onChange={handleBankInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            )}
            {/* Bank Info Modal */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
                />
        </>
    )
}

export default EmployeeProfileForm;

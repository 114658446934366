import React, { useContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import PayrollVarianceTable from "./Table/PayrollVarianceTable";
import EmployeeSalaryTable from "./Table/EmployeeSalaryTable";
import PayrollTableShortcuts from "./Table/PayrollTableShortcuts";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "../../../firebase.js";

const auth = getAuth();

function FinanceDashboardHome(){
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!auth.currentUser) {
                toast.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                toast.error(
                    "User not found. Please register an account on astra first on registration page"
                );
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUserData(userDataArray);
        };

        fetchUserData();
    },);

    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                        {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                {/* <h3 className='page-title'>Welcome {user.firstName} {user.lastName}!</h3> */}
                                {/* <h3 className='page-title'>Welcome {user.companyName ? user.companyName : `${user.firstName} ${user.lastName}`}!</h3> */}
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>

                    <div className="row">
                        <PayrollVarianceTable/>
                        <EmployeeSalaryTable/>
                    </div>

                    <div className="row">
                        <PayrollTableShortcuts/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinanceDashboardHome;

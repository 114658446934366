import React, {useState, useEffect, useContext} from 'react';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import BulkLeaveForm from './bulkLeave.js';
import LeaveForm from './leaveForm.js';
import { UserContext } from '../../../InitialPage/App.js';
import { getAuth } from "firebase/auth";
const auth = getAuth();

const LeaveScheduling = () => {
    const { userRole } = useContext(UserContext);
    const [selectedDate, setSelectedDate] = useState(null);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [leaveDates, setLeaveDates] = useState([]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const setshowEvents = false;
    const setshow = false;
    const [showmodel, setshowmodel] = useState(false);
    const [isEditDelete, setiseditdelete] = useState(false);
    const [setevent_title] = useState("");
    const [calenderevent, setcalenderevent] = useState("");
    const [weekendsVisible] = useState(true);

    const handleClose = () => {
        setisnewevent(false);
        setiseditdelete(false);
        setshow(false);
        setshowEvents(false);
        setshowmodel(false);
    };
    const handleEventClick = (clickInfo) => {
        setiseditdelete(false);
        setevent_title(clickInfo.event.title);

        setcalenderevent(clickInfo.event);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchLeaveDates = async () => {
            try {
                const colRef = collection(db, 'issue-leave');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setLeaveDates(dataArray);
            } catch (error) {
                console.error('Error fetching leave dates:', error);
            }
        };

        fetchLeaveDates();
    }, [userData, loading]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Leaves</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hr/leave-management/leave-requests">Leave
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Annual Leave Scheduling</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-auto float-end ms-auto">
                                        <Link
                                            to="#"
                                            className="btn btn-custom"
                                            data-bs-toggle="modal"
                                            data-bs-target="#bulk_leave">
                                            Schedule Bulk Leave
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn btn-custom1"
                                            data-bs-toggle="modal"
                                            data-bs-target="#add_leave">
                                            Schedule Leave
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mt-3 card bg-white calendarIndex">
                                            <div className="card-body">
                                                <FullCalendar
                                                    plugins={[
                                                        dayGridPlugin,
                                                        timeGridPlugin,
                                                        interactionPlugin,
                                                    ]}
                                                    headerToolbar={{
                                                        left: "prev,next today",
                                                        center: "title",
                                                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                                                    }}
                                                    initialView="dayGridMonth"
                                                    editable={true}
                                                    selectable={true}
                                                    selectMirror={true}
                                                    dayMaxEvents={true}
                                                    weekends={true}
                                                    events={leaveDates.map(event => ({
                                                        title: (event.employeeName) || "All",
                                                        start: new Date(event.startDate.seconds * 1000).toISOString(),
                                                        end: new Date(event.endDate.seconds * 1000).toISOString(),
                                                        allDay: true,
                                                        backgroundColor: '#00AAB9',
                                                    }))}
                                                    eventContent={({event}) => (
                                                        <div>
                                                            {event.title}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <BulkLeaveForm/>
                                <LeaveForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaveScheduling;

import React, {useEffect, useState} from "react";
import logo2 from "../../../assets/img/rhokit-logo.png";
import DatePicker from "react-datepicker";
import {Slide, toast, ToastContainer} from "react-toastify";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db, app} from "../../../firebase";
import {countries} from "countries-list";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EmployeeRegister = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [value, setValue] = useState();
    const [countryNames, setCountryNames] = useState([]);
    const kraPinRegex = /^[a-zA-Z0-9]{11}$/;
    const [kraPinError, setKraPinError] = useState('');

    // Define the regex pattern for the text input
    const textInputRegex = /^([A-Z][a-z']{1,}\s?)+$/;

    const auth = getAuth(app);
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    let { userId } = useParams();

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError("");
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
        setRepeatPasswordError("");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleRepeatPasswordVisibility = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    useEffect(() => {
        // Extract country names from countries object
        const countryNamesArray = Object.values(countries).map(country => country.name);
        // Set the countryNames state with the list of country names
        setCountryNames(countryNamesArray);
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const [formData, setFormData] = useState({
        employeeNo: '',
        employeeName: '',
        nssfNo: '',
        nhifNo: '',
        nationalIdentityNumber: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
        address: {
            city: '',
            country: '',
        },
        email: '',
        kraPinNo: '',
        maritalStatus: '',
        qualification: '',
        role: '',
    });


    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        // console.log("Selected date:", date);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'dateOfBirth' ? value : value,
        });
    };

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        if (name === 'city' || name === 'country') {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [name]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const requiredFields =
        [
            'employeeNo',
            'employeeName',
            'email',
            'phone',
            'gender',
            'date',
            'city',
            'country',
            'nationalIdentityNumber',
            'kraPinNo',
            'nssfNo',
            'nhifNo',
            'maritalStatus',
            'qualification',
            'role',
            // 'departmentName',
            'password',
            'repeatPassword',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });


        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Validate KRA PIN
        if (!kraPinRegex.test(formData.kraPinNo)) {
            setKraPinError('KRA PIN must be 11 alphanumeric characters.');
            showToast('Invalid KRA PIN!', 'error');
            return;
        } else {
            setKraPinError('');
        }

        // Validate phone number
        if (formData.phone.length > 15) {
            showToast('Phone number cannot exceed 15 characters!', 'error');
            return;
        }

        // Validate employee name using regex
        if (!textInputRegex.test(formData.employeeName)) {
            showToast('Invalid name format!', 'error');
            return;
        }

        // Check if the country field is empty
        if (!formData.address.country) {
            showToast('Country cannot be empty!', 'error');
            return;
        }

        // Check if the city field is empty
        if (!formData.address.city) {
            showToast('City cannot be empty!', 'error');
            return;
        }

        // If the form is not empty and the fields above are valid, proceed with other validations
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        // Format date of birth
        let dateOfBirthString = null;
        if (selectedDate) {
          const year = selectedDate.getFullYear();
          const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
          const day = String(selectedDate.getDate()).padStart(2, "0");
          dateOfBirthString = `${year}${month}${day}`;
        }

        try {
            setLoading(true);
            const customerId = Math.floor(Math.random() * 1000000);
    
            // const docRef = await addDoc(collection(db, 'add-employee'), {
            //     ...formData,
            //     dateOfBirth: dateOfBirthString,
            // });

            // Check if the user already exists in add-employee collection
            const userQuery = await getDocs(
                query(
                    collection(db, "add-employee"),
                    where("nationalIdentityNumber", "==", formData.nationalIdentityNumber),
                    where("email", "==", formData.email)
                )
            );

            if (!userQuery.empty) {
                // User exists in users collection, create user with email and password
                await createUserWithEmailAndPassword(auth, formData.email, password);
                toast.success("You've already been registered by your organisation. Your password set successfully");
                setLoading(false);
                setTimeout(() => {
                    navigate(`/`);
                }, 2000); 
                return;
            }
            
            const companySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', formData.email)));
            if (!companySnapshot.empty) {
                showToast('User with this email already exists!', 'error');
                return;
            }

            const companyDisplaySnapshot = await getDocs(query(collection(db, 'users'), where('nationalIdentityNumber', '==', formData.nationalIdentityNumber)));
            if (!companyDisplaySnapshot.empty) {
                showToast('User with this ID number already exists!', 'error');
                return;
            }

            const companyPINSnapshot = await getDocs(query(collection(db, 'users'), where('kraPinNo', '==', formData.kraPinNo)));
            if (!companyPINSnapshot.empty) {
                showToast('User with this PIN already exists!', 'error');
                return;
            }

            await addDoc(collection(db, 'users'), {
                ...formData,
                dateOfBirth: dateOfBirthString,
                customerId
            });

            const docRef = await addDoc(collection(db, 'add-employee'), {
                    ...formData,
                    dateOfBirth: dateOfBirthString,
            });

            setLoading(false);

            await createUserWithEmailAndPassword(auth, formData.email, password)
                .then(() => {
                    toast.success("Password set successfully");
                    setLoading(false);
                    navigate(`/`);
                })
                .catch((error) => {
                    setLoading(false);
                    setTimeout(() => {
                        navigate(`/`);
                    }, 3000);
            });

            showToast('User Added Successfully', 'success');
            // resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
            showToast('An error occurred while creating the employee.', 'error');
        }
    };

    return (
        <>
            <div className="register">
                <div className="account-page">
                    <div className="main-wrapper">
                        <div className="account-content">
                            <div className="container">
                                <div className="account-logo">
                                    <img src={logo2} alt="Logo"/>
                                </div>

                                <div className="account-box">
                                    <div className="account-wrapper">
                                        <h3 className="account-title">Register</h3>
                                        <p className="account-subtitle">Access to our dashboard</p>
                                        <p className="text-primary text-xs">
                                            Check with your organisation
                                        </p>
                                        <div>
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Employee Number <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="employeeNo"
                                                                autoComplete="off"
                                                                value={formData.employeeNo}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="employeeName"
                                                                autoComplete="off"
                                                                value={formData.employeeName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Email <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Phone No <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="phone"
                                                                autoComplete="off"
                                                                value={formData.phone}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Gender <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-control form-select"
                                                                name="gender"
                                                                autoComplete="off"
                                                                value={formData.gender}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select Gender</option>
                                                                <option value="M">Male</option>
                                                                <option value="F">Female</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Date of Birth <span
                                                                className="text-danger">*</span></label>
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate}
                                                                    onChange={handleDateChange}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                    name="date"
                                                                    required
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                City <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                autoComplete="off"
                                                                name="city"
                                                                value={formData.address.city}
                                                                onChange={handleInputChange1}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Country <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="country"
                                                                value={formData.address.country}
                                                                onChange={handleInputChange1}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {countryNames.sort().map((country) => (
                                                                    <option key={country} value={country}>
                                                                        {country}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                National ID <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nationalIdentityNumber"
                                                                autoComplete="off"
                                                                value={formData.nationalIdentityNumber}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">KRA PIN No <span
                                                                className="text-danger">*</span> </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="kraPinNo"
                                                                autoComplete="off"
                                                                value={formData.kraPinNo}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                NSSF No <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nssfNo"
                                                                autoComplete="off"
                                                                value={formData.nssfNo}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                NHIF No <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nhifNo"
                                                                autoComplete="off"
                                                                value={formData.nhifNo}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Marital Status <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="maritalStatus"
                                                                value={formData.maritalStatus}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value>Select status</option>
                                                                <option value="S">Single</option>
                                                                <option value="M">Married</option>
                                                                <option value="D">Divorced</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Qualification <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="qualification"
                                                                autoComplete="off"
                                                                value={formData.qualification}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Role <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                autoComplete="off"
                                                                name="role"
                                                                value={formData.role}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value>Select Role</option>
                                                                {/* <option value="sys-admin">System Admin</option> */}
                                                                {/* <option value="admin">Company Admin</option> */}
                                                                <option value="hod">Department Head</option>
                                                                <option value="employee">Employee</option>
                                                                <option value="finance">Finance</option>
                                                                <option value="hr">HR</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Department <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="departmentName"
                                                                value={formData.departmentName}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select a department</option>
                                                                {departments.map(department => (
                                                                    <option>
                                                                        {department.departmentName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label className="col-form-label">
                                                                        Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div style={{position: "relative"}}>
                                                                <input
                                                                    type={showPassword ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        passwordError && "is-invalid"
                                                                    }`}
                                                                    name="password"
                                                                    value={password}
                                                                    onChange={handlePasswordChange}
                                                                    placeholder="Enter Password"
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={showPassword ? faEye : faEyeSlash}
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "5%",
                                                                        top: "30%",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={togglePasswordVisibility}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label className="col-form-label">
                                                                        Confirm Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div style={{position: "relative"}}>
                                                                <input
                                                                    type={showRepeatPassword ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        repeatPasswordError && "is-invalid"
                                                                    }`}
                                                                    name="repeatPassword"
                                                                    value={repeatPassword}
                                                                    onChange={handleRepeatPasswordChange}
                                                                    placeholder="Confirm Password"
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={showRepeatPassword ? faEye : faEyeSlash}
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "5%",
                                                                        top: "30%",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={toggleRepeatPasswordVisibility}
                                                                />
                                                            </div>
                                                            <div className="text-danger">
                                                                {repeatPasswordError}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='input-block text-center'>
                                                    <button
                                                        className="btn btn-primary account-btn mt-2" type="submit"
                                                    >
                                                        Register
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default EmployeeRegister;

import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {collection, doc, getDocs, updateDoc, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";

const CompanyTable = () => {
    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        displayName: '',
        email: '',
        physicalAddress: '',
        postCode: '',
        city: '',
    })

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (companyId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-company', companyId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === companyId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'add-company');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted); // Filter out deleted leave categories
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if(editCompanyId){
                    const selectedCompany = dataArray.find(item => item.id === editCompanyId);
                    setFormData(selectedCompany)
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editCompanyId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="card-title">Companies</h3>
                        </div>

                        <div className="col-auto">
                            <Link className="btn btn-primary "
                                  to="/companies/add-company">
                                Add Company
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Email</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(item => (
                                <tr key={item.id}>
                                    <td>
                                        <h2 className="table-avatar">
                                            <Link to={`/companies/company-profile/${item.id}`}>{item.displayName || item.tradingName}</Link>
                                        </h2>
                                    </td>
                                    <td>{item.email}</td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : 'danger'}`}></i>
                                                {item.status === 'active' ? 'Active' : 'Inactive'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'active')}>
                                                    <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                </a>
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                    <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/companies/companies-list">View all companies</Link>
                </div>
            </div>
        </div>
    )
}

export default CompanyTable;

import React, { useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import logo2 from "../../assets/img/rhokit-logo.png";
import {Link} from "react-router-dom";
import {Slide, toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import { useNavigate } from "react-router-dom";
import { registerOrganisation } from "../../lib/astra.js";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

const OrgRegister = () => {
    const [value, setValue] = useState();
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const textInputRegex = /^([a-zA-Z]+\s[a-zA-Z']{0,2}[a-zA-Z]+(\s[a-zA-Z']{0,2}[a-zA-Z]+)?)/;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [emailError, setEmailError] = useState('');
    const kraPinRegex = /^[A-Za-z][0-9]{9}[A-Za-z]$/;
    const [kraPinError, setKraPinError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('KE'); 

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        companyName: '',
        companyNumber: '',
        businessType: '',
        businessRegistrationDate: '',
        email: '',
        taxNumber: '',
        phone1: '',
        // phone2: '',
        industrialClassification: '',
        industrialSector: '',
        // categoryCode: '',
        tradingName: '',
        type: "BUSINESS",
        role: "admin"
    });

    const natureOfBusiness = [
        "Legal Services",
        "Depository Institutions",
        "Non Depository Institutions",
        "Security Brokers",
        "Insurance",
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        if (name === 'email') {
            // If it is the email field, update the state directly without further validation
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            // If it's not the email field, check for invalid characters
            if (invalidPattern.test(value)) {
                // If it contains invalid characters, don't update the state and show an error message
                toast.error('Invalid input: Special characters and symbols are not allowed', 'error');
                return;
            }

            // If the value doesn't contain invalid characters, update the state
            setFormData({
                ...formData,
                [name]: name === 'businessRegistrationDate' ? value : value,
            });
        }
        // setFormData({
        //     ...formData,
        //     [name]: value,
        // });
    };

    const handlePhoneChange = (value, country) => {
        setFormData({
            ...formData,
            phone: value,
        });
        setSelectedCountry(country.countryCode.toUpperCase()); // Update selected country
    };

    const isValidPhone = (phone) => {
        return isValidPhoneNumber(phone, selectedCountry); // Use selected country for validation
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
        toast.error("Please fill in all required fields");
        return;
        }

        // Validate KRA PIN
        if (!kraPinRegex.test(formData.taxNumber)) {
            setKraPinError('PIN must be 11 alphanumeric characters.');
            toast.error('Invalid PIN!', 'error');
            return;
        } else {
            setKraPinError('');
        }

        // Validate phone number
        if (formData.phone.length > 15) {
            toast.error('Phone number cannot exceed 15 characters!', 'error');
            return;
        }

        // Validate phone number
        if (formData.phone.length < 10) {
            toast.error('Phone number cannot be less than 10 characters!', 'error');
            return;
        }

        // Validate email
        if (!emailRegex.test(formData.email)) {
            setEmailError('Email must be valid!');
            toast.error('Invalid email!', 'error');
            return;
        } else {
            setEmailError('');
        }

        let businessRegistrationDateString = null;
        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
            const day = String(selectedDate.getDate()).padStart(2, "0");
            businessRegistrationDateString = `${year}${month}${day}`;
        }

        try {
            setLoading(true);
            // Check if the user already exists with the same 
            const userQuery = await getDocs(
                query(
                collection(db, "users"),
                where("companyName", "==", formData.companyName),
                where("email", "==", formData.email)
                )
            );

            if (!userQuery.empty) {
                // User already exists, show toast and navigate after delay
                setLoading(false);
                toast.error("You already have an account with us. Navigating to the next step...");

                setTimeout(() => {
                const userRef = userQuery.docs[0].id;
                    navigate(`/orgregister/${userRef.id}/add-org-address`);
                }, 3000); // 3000 milliseconds delay (adjust as needed)
                return;
            }

            const companyEmailSnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', formData.email)));
            if (!companyEmailSnapshot.empty) {
                toast.error('Company with this email already exists!', 'error');
                return;
            }

            registerOrganisation(
                formData.companyName,
                formData.companyNumber,
                formData.businessType,
                businessRegistrationDateString,
                formData.email,
                formData.taxNumber,
                value,
                formData.industrialClassification,
                formData.industrialSector,
                formData.tradingName
            )
              .then(async (data) => {
                const updatedFormData = {
                  ...formData,
                  organisationId: data.organisationId,
                  phone: value,
                  businessRegistrationDate: businessRegistrationDateString,
                };
      
                // Add user details to Firestore collection
                const userRef = await addDoc(collection(db, "users"), {
                  ...updatedFormData,
                });
      
                setLoading(false);
                toast.success("User registered successfully");
      
                // Redirect to the next step
                navigate(`/orgregister/${userRef.id}/add-org-address`);
              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
              });
          } catch (error) {
            setLoading(false);
            toast.error(error.message);
          }
    }

    return (
        <>
            <div className="register">
                <div className="account-page">
                    <div className="main-wrapper">
                        <div className="account-content">
                            <div className="container">
                                <div className="account-logo">
                                    <img src={logo2} alt="Logo" />
                                </div>

                                <div className="account-box">
                                    <div className="account-wrapper">
                                        <h3 className="account-title">Register</h3>
                                        <p className="account-subtitle">Access to our dashboard</p>

                                        <div>
                                            <form onSubmit={handleSubmit} noValidate>
                                                <h4>Company Information</h4>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Company Name <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyName"
                                                                autoComplete="off"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Company Number <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyNumber"
                                                                autoComplete="off"
                                                                value={formData.companyNumber}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Business Type <span className="text-danger">*</span></label>
                                                            {/* <input
                                                                className="form-control"
                                                                type="text"
                                                                name="businessType"
                                                                autoComplete="off"
                                                                value={formData.businessType}
                                                                onChange={handleInputChange}
                                                                required
                                                            /> */}
                                                            <select
                                                                className="form-control form-select"
                                                                name="businessType"
                                                                autoComplete="off"
                                                                value={formData.businessType}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select type of business</option>
                                                                <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
                                                                <option value="LIMITED_COMPANY">Limited Company</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Business Registration Date<span className="text-danger">*</span></label>
                                                            {/* <input
                                                                className="form-control"
                                                                type="date"
                                                                name="businessRegistrationDate"
                                                                autoComplete="off"
                                                                value={formData.businessRegistrationDate}
                                                                onChange={handleInputChange}
                                                            /> */}
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate}
                                                                    onChange={handleDateChange}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                    maxDate={new Date()}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Email<span className="text-danger">*</span></label>
                                                            <input
                                                                // className="form-control"
                                                                className={`form-control ${
                                                                    emailError && "is-invalid"
                                                                }`}
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                            <div className="text-danger">{emailError}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Phone Number<span
                                                                className="text-danger">*</span></label>
                                                            {/* <input
                                                                className="form-control"
                                                                type="number"
                                                                name="phone1"
                                                                autoComplete="off"
                                                                value={value}
                                                                onChange={handleInputChange}
                                                                required
                                                            /> */}
                                                            <PhoneInput
                                                                country={'ke'}
                                                                value={formData.phone}
                                                                onChange={handlePhoneChange}
                                                                inputClass="form-control"
                                                                containerClass="phone-input-container"
                                                                required
                                                            />
                                                            {formData.phone && !isValidPhone(formData.phone) && (
                                                                <span className="text-danger">Invalid phone number</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Tax Number<span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="taxNumber"
                                                                autoComplete="off"
                                                                value={formData.taxNumber}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    

                                                    {/* <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Phone 2</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="phone2"
                                                                autoComplete="off"
                                                                value={formData.phone2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Industrial Classification <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-control"
                                                                type="text"
                                                                name="industrialClassification"
                                                                autoComplete="off"
                                                                value={formData.industrialClassification}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                            <option value>Select Nature of Business</option>
                                                                {natureOfBusiness.map((business) => (
                                                                    <option key={business} value={business}>
                                                                        {business}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Industrial Sector<span className="text-danger">*</span> </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="industrialSector"
                                                                autoComplete="off"
                                                                value={formData.industrialSector}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Category Code</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="categoryCode"
                                                                autoComplete="off"
                                                                value={formData.categoryCode}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Trading Name<span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="tradingName"
                                                                autoComplete="off"
                                                                value={formData.tradingName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-block text-center">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="submit"
                                                            disabled={loading}
                                                        >
                                                            {loading ? "Registering..." : "Next Step"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="account-footer">
                                                <p>
                                                    Already have an account? <Link to="/">Login</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default OrgRegister;

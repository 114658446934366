import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImg from '../assets/img/oops1.png';

const NotFound = () => {
  return (
    <div style={styles.container}>
      <img src={notFoundImg} alt="404 Not Found" style={styles.image} />
      <h1 style={styles.heading}>Oops! Page Not Found</h1>
      <p style={styles.text}>The page you are looking for doesn't exist.</p>
      <Link to="/" style={styles.button}>Go Home</Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    // padding: '20px',
  },
  image: {
    width: '500px',
    height: 'auto',
    marginBottom: '5px',
  },
  heading: {
    fontSize: '2em',
    color: '#333',
    marginBottom: '10px',
  },
  text: {
    fontSize: '1.2em',
    color: '#666',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    color: '#fff',
    backgroundColor: '#145c9c',
    textDecoration: 'none',
    borderRadius: '5px',
  },
};

export default NotFound;

import React from 'react';
import NoWifi from '../assets/img/no-wifi.png'

const NoInternet = () => {
    return (
      <div style={styles.container}>
        <img src={NoWifi} alt="No Internet" style={styles.image} />
        <h1 style={styles.heading}>Oops! No Internet Connection</h1>
        <p style={styles.text}>Please check your internet connection and try again.</p>
      </div>
    );
  };
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f9f9f9',
      textAlign: 'center',
      padding: '20px',
    },
    image: {
      width: '300px',
      height: 'auto',
      marginBottom: '20px',
    },
    heading: {
      fontSize: '2em',
      color: '#333',
      marginBottom: '10px',
    },
    text: {
      fontSize: '1.2em',
      color: '#666',
    },
  };

export default NoInternet;

import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const BenefitsForm = () => {
    const [formData, setFormData] = useState({
        employeeName:'',
        lifeInsurance:'',
        disabilityInsurance:'',
        personalRelief:'',
        insuranceRelief:'',
        medicalFundRelief:'',
        otherReliefs:'',
        paye:'',
        nhif:'',
        nssf:'',
        otherDeductions: '',
    });
    const [employees, setEmployees] = useState([]);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            // Form is invalid, do not proceed with submission
            showToast('Form cannot be empty!', 'error');
            return;
        }

        try {
            const docRef = await addDoc(collection(db, 'add-benefit'), formData);
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Benefit Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };
    return (
        <>
            <div id="add_benefit" className="modal custom-modal fade" role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Benefit</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                            <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Employee Name<span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="employeeName"
                                                value = {formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Life Insurance</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="lifeInsurance"
                                                autoComplete="off"
                                                value = {formData.lifeInsurance}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Disability Insurance</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="disabilityInsurance"
                                            autoComplete="off"
                                            value = {formData.disabilityInsurance}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h4 className="text-primary">Reliefs</h4>
                                        <div className="input-block">
                                            <label>Personal Relief</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="personalRelief"
                                                autoComplete="off"
                                                value = {formData.personalRelief}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>Insurance Relief</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="insuranceRelief"
                                                autoComplete="off"
                                                value = {formData.insuranceRelief}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>Medical Fund Relief</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="medicalFundRelief"
                                                autoComplete="off"
                                                value = {formData.medicalFundRelief}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>Other Reliefs</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="otherReliefs"
                                                autoComplete="off"
                                                value = {formData.otherReliefs}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h4 className="text-primary">Deductions</h4>
                                        <div className="input-block">
                                            <label>PAYE</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="paye"
                                                autoComplete="off"
                                                value = {formData.paye}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>NHIF</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nhif"
                                                autoComplete="off"
                                                value = {formData.nhif}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>NSSF</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nssf"
                                                autoComplete="off"
                                                value = {formData.nssf}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block">
                                            <label>Other Deductions</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="otherDeductions"
                                                autoComplete="off"
                                                value = {formData.otherDeductions}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default BenefitsForm;

import { ASTRA_BASE_URL, ASTRA_IDENTITY, ASTRA_PASSWORD, keys } from "../keys";

// generate a random 36-character UUID
const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// generate a random 6 character string
const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 8);
};

export const banks = [
  {
    bankCode: "0001",
    bankName: "KENYA COMMERCIAL BANK LTD",
    ccCode: "1",
    shortName: "KCB",
    bicCode: "KCBLKENX",
    bic: "KCBLKENX",
  },
  {
    bankCode: "0002",
    bankName: "STANDARD CHARTERED",
    ccCode: "2",
    shortName: "SCB",
    bicCode: "SCBLKENA",
    bic: "SCBLKENA",
  },
  {
    bankCode: "0003",
    bankName: "ABSA BANK KENYA LIMITED",
    ccCode: "3",
    shortName: "BBK",
    bicCode: "BARCKENX",
    bic: "BARCKENX",
  },
  {
    bankCode: "0005",
    bankName: "BANK OF INDIA",
    ccCode: "5",
    shortName: "BOI",
    bicCode: "BKIDKENA",
    bic: "BKIDKENA",
  },
  {
    bankCode: "0006",
    bankName: "BANK OF BARODA",
    ccCode: "6",
    shortName: "BOB",
    bicCode: "BARBKENA",
    bic: "BARBKENA",
  },
  {
    bankCode: "0007",
    bankName: "NCBA BANK KENYA PLC",
    ccCode: "7",
    shortName: "NCBA",
    bicCode: "CBAFKENX",
    bic: "CBAFKENX",
  },
  {
    bankCode: "0008",
    bankName: "HABIB BANK LTD",
    ccCode: "8",
    shortName: "HABB",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0009",
    bankName: "CENTRAL BANK OF KENYA",
    ccCode: "9",
    shortName: "CBK",
    bicCode: "CBKEKENA",
    bic: "CBKEKENA",
  },
  {
    bankCode: "0010",
    bankName: "PRIME BANK LIMITED",
    ccCode: "10",
    shortName: "PMB",
    bicCode: "PRIEKENX",
    bic: "PRIEKENX",
  },
  {
    bankCode: "0011",
    bankName: "CO-OPERATIVE BANK",
    ccCode: "11",
    shortName: "COOP",
    bicCode: "KCOOKENA",
    bic: "KCOOKENA",
  },
  {
    bankCode: "0012",
    bankName: "NATIONAL BANK OF KENYA",
    ccCode: "12",
    shortName: "NBK",
    bicCode: "NBKEKENX",
    bic: "NBKEKENX",
  },
  {
    bankCode: "0014",
    bankName: "M-ORIENTAL COMMERCIAL BANK",
    ccCode: "14",
    shortName: "OCB",
    bicCode: "MORBKENA",
    bic: "MORBKENA",
  },
  {
    bankCode: "0015",
    bankName: "FIRST AMERICAN BANK OF KENYA",
    ccCode: "7",
    shortName: "FAB",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0016",
    bankName: "CITIBANK N.A.",
    ccCode: "16",
    shortName: "CITI",
    bicCode: "CITIKENA",
    bic: "CITIKENA",
  },
  {
    bankCode: "0017",
    bankName: "HABIB BANK A.G.",
    ccCode: "17",
    shortName: "HZUR",
    bicCode: "HBZUKENA",
    bic: "HBZUKENA",
  },
  {
    bankCode: "0018",
    bankName: "MIDDLE EAST BANK (K) LIMITED",
    ccCode: "18",
    shortName: "MEB",
    bicCode: "MIEKKENA",
    bic: "MIEKKENA",
  },
  {
    bankCode: "0019",
    bankName: "BANK OF AFRICA KENYA LTD",
    ccCode: "19",
    shortName: "BOA",
    bicCode: "AFRIKENX",
    bic: "AFRIKENX",
  },
  {
    bankCode: "0020",
    bankName: "DUBAI BANK OF KENYA LTD",
    ccCode: "20",
    shortName: "DUB",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0023",
    bankName: "CONSOLIDATED BANK OF KENYA LTD",
    ccCode: "23",
    shortName: "CONS",
    bicCode: "CONKKENA",
    bic: "CONKKENA",
  },
  {
    bankCode: "0025",
    bankName: "CREDIT BANK LTD",
    ccCode: "25",
    shortName: "CBL",
    bicCode: "CRBTKENA",
    bic: "CRBTKENA",
  },
  {
    bankCode: "0026",
    bankName: "TRANS-NATIONAL BANK",
    ccCode: "26",
    shortName: "TNBL",
    bicCode: "ABNGKENA",
    bic: "ABNGKENA",
  },
  {
    bankCode: "0030",
    bankName: "CHASE BANK (KENYA) LIMITED",
    ccCode: "30",
    shortName: "CHAS",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0031",
    bankName: "STANBIC BANK KENYA LIMITED",
    ccCode: "31",
    shortName: "STAN",
    bicCode: "SBICKENX",
    bic: "SBICKENX",
  },
  {
    bankCode: "0035",
    bankName: "AFRICAN BANKING CORPORATION",
    ccCode: "35",
    shortName: "ABC",
    bicCode: "ABCLKENA",
    bic: "ABCLKENA",
  },
  {
    bankCode: "0039",
    bankName: "IMPERIAL BANK LIMITED",
    ccCode: "39",
    shortName: "IMBK",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0042",
    bankName: "GIRO BANK LTD",
    ccCode: "42",
    shortName: "GIRO",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0043",
    bankName: "ECOBANK KENYA LTD",
    ccCode: "43",
    shortName: "ECO",
    bicCode: "ECOCKENA",
    bic: "ECOCKENA",
  },
  {
    bankCode: "0049",
    bankName: "SPIRE BANK LTD",
    ccCode: "68",
    shortName: "EQCB",
    bicCode: "SPBLKENA",
    bic: "EQBLKENA",
  },
  {
    bankCode: "0050",
    bankName: "PARAMOUNT BANK LTD",
    ccCode: "50",
    shortName: "PARA",
    bicCode: "PAUTKENA",
    bic: "PAUTKENA",
  },
  {
    bankCode: "0051",
    bankName: "JAMII BORA BANK LTD",
    ccCode: "51",
    shortName: "JMI",
    bicCode: "CIFIKENA",
    bic: "CIFIKENA",
  },
  {
    bankCode: "0053",
    bankName: "GUARANTY TRUST BANK",
    ccCode: "53",
    shortName: "GTB",
    bicCode: "GTBIKENA",
    bic: "GTBIKENA",
  },
  {
    bankCode: "0054",
    bankName: "VICTORIA COMMERCIAL BANK LTD",
    ccCode: "54",
    shortName: "VCB",
    bicCode: "VICMKENA",
    bic: "VICMKENA",
  },
  {
    bankCode: "0055",
    bankName: "GUARDIAN BANK",
    ccCode: "55",
    shortName: "GUAD",
    bicCode: "GUARKENA",
    bic: "GUARKENA",
  },
  {
    bankCode: "0057",
    bankName: "INVESTMENTS AND MORTGAGES",
    ccCode: "57",
    shortName: "I&M",
    bicCode: "IMBLKENA",
    bic: "IMBLKENA",
  },
  {
    bankCode: "0059",
    bankName: "DEVELOPMENT BANK OF KENYA",
    ccCode: "59",
    shortName: "DVBK",
    bicCode: "DEVKKENA",
    bic: "DEVKKENA",
  },
  {
    bankCode: "0060",
    bankName: "SBM BANK",
    ccCode: "60",
    shortName: "SBM",
    bicCode: "SBMKKENA",
    bic: "SBMKKENA",
  },
  {
    bankCode: "0061",
    bankName: "HF GROUP LTD",
    ccCode: "61",
    shortName: "HFG",
    bicCode: "HFCOKENA",
    bic: "HFCOKENA",
  },
  {
    bankCode: "0062",
    bankName: "KENYA POST OFFICE SAVING BANK",
    ccCode: "16",
    shortName: "KPSB",
    bicCode: "POSBKENA",
    bic: "CITIKENA",
  },
  {
    bankCode: "0063",
    bankName: "DIAMOND TRUST BANK",
    ccCode: "63",
    shortName: "DTB",
    bicCode: "DTKEKENA",
    bic: "DTKEKENA",
  },
  {
    bankCode: "0064",
    bankName: "CHARTERHOUSE BANK LTD",
    ccCode: "64",
    shortName: "CHAT",
    bicCode: "NULL",
    bic: "NULL",
  },
  {
    bankCode: "0065",
    bankName: "COMMERCIAL INTERNATIONAL BANK (CIB) KENYA LIMITED",
    ccCode: "65",
    shortName: "MFB",
    bicCode: "MYBKKENA",
    bic: "MYBKKENA",
  },
  {
    bankCode: "0066",
    bankName: "SIDIAN BANK",
    ccCode: "66",
    shortName: "SDN ",
    bicCode: "KREPKENA",
    bic: "KREPKENA",
  },
  {
    bankCode: "0068",
    bankName: "EQUITY BANK",
    ccCode: "68",
    shortName: "EQBA",
    bicCode: "EQBLKENA",
    bic: "EQBLKENA",
  },
  {
    bankCode: "0070",
    bankName: "FAMILY BANK",
    ccCode: "70",
    shortName: "FAM",
    bicCode: "FABLKENA",
    bic: "FABLKENA",
  },
  {
    bankCode: "0072",
    bankName: "GULF BANK",
    ccCode: "72",
    shortName: "GAF",
    bicCode: "GAFRKENA",
    bic: "GAFRKENA",
  },
  {
    bankCode: "0074",
    bankName: "PREMIER BANK KENYA LIMITED",
    ccCode: "74",
    shortName: "FCB",
    bicCode: "IFCBKENX",
    bic: "IFCBKENX",
  },
  {
    bankCode: "0075",
    bankName: "DIB BANK KENYA",
    ccCode: "75",
    shortName: "DIB",
    bicCode: "DUIBKENA",
    bic: "DUIBKENA",
  },
  {
    bankCode: "0076",
    bankName: "UNITED BANK FOR AFRICA KENYA",
    ccCode: "76",
    shortName: "UBA",
    bicCode: "UNAFKENA",
    bic: "UNAFKENA",
  },
  {
    bankCode: "0078",
    bankName: "KENYA WOMEN MICROFINANCE BANK",
    ccCode: "78",
    shortName: "KWFT",
    bicCode: "KWMIKENX",
    bic: "KWMIKENX",
  },
  {
    bankCode: "0079",
    bankName: "FAULU MICROFINANCE BANK",
    ccCode: "1",
    shortName: "FMB",
    bicCode: "FAUMKENA",
    bic: "KCBLKENX",
  },
];

// authentication To Astra Admin
const authenticationToAstra = async () => {
  const response = await fetch(`${ASTRA_BASE_URL}/authentication/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      identity: ASTRA_IDENTITY,
      password: ASTRA_PASSWORD,
    }),
  });

  const responseData = await response.json();
  return responseData;
};

// register a customer
export const registerCustomer = async (
  firstName,
  lastName,
  phone1,
  gender,
  maritalStatus,
  birthCountry,
  birthCity,
  nationalIdentityNumber,
  dateOfBirth,
  title
) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        externalUniqueId: generateUUID(),
        locale: "EN",
        status: "ACTIVE",
        firstName,
        lastName,
        phone1,
        gender,
        maritalStatus,
        birthCountry,
        birthCity,
        nationalIdentityNumber,
        dateOfBirth,
        title,
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Add a new address for the customer
export const addAddress = async (
  customerId,
  addressType,
  city,
  country,
  line1,
  state,
  line2 = "",
  code = ""
) => {
  const authentication = await authenticationToAstra();
  const requestBody = {
    addressType,
    city,
    country,
    line1,
    state,
  };

  if (line2 !== "") {
    requestBody.line2 = line2;
  }

  if (code !== "") {
    requestBody.code = code;
  }

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/addresses`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// upload a document for the customer
export const uploadCustomerDocument = async (
  documentType,
  customerId,
  base64EncodedDocument,
  mediaType
) => {
  const authentication = await authenticationToAstra();

  let API_URL = `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/documents`;

  if (
    documentType === "FACIAL_PHOTO" ||
    documentType === "NATIONAL_IDENTITY" ||
    documentType === "BACK_OF_NATIONAL_IDENTITY" ||
    documentType === "PASSPORT"
  ) {
    API_URL = `${API_URL}?performOcr=true&validateDocType=true`;
  }

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authentication.headerValue,
    },
    body: JSON.stringify({
      base64EncodedDocument,
      documentType,
      mediaType,
    }),
  });

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// do KYC
export const doKYC = async (customerId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/ratify`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        type: "NORMAL",
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    const formattedResponse = [
      responseData.selfieIsASelfie,
      responseData.selfieIsLegitimate,
      responseData.selfieMatchesNationalIdentity,
      responseData.firstNameMatchesNationalIdentity,
      responseData.lastNameMatchesNationalIdentity,
      responseData.identityNumberMatchesNationalIdentity,
      responseData.nationalIdentityIsANationalIdentity,
      responseData.nationalIdentityIsLegitimate,
      responseData.backOfNationalIdentityIsLegitimate,
      responseData.idNumberMatchesBackOfNationalIdentity,
      responseData.nameMatchesBackOfNationalIdentity,
      responseData.dateOfBirthMatchesBackOfNationalIdentity,
      responseData.sexMatchesBackOfNationalIdentity,
      responseData.dateOfBirthMatchesNationalIdentity,
      responseData.worldCheck,
      responseData.taxPayerNameMatchesNationalDatabase,
      responseData.taxDocumentMatchesNationalDatabase,
      responseData.taxDocumentIsLegitimate,
      responseData.firstNameMatchesTaxDocument,
      responseData.lastNameMatchesTaxDocument,
      responseData.addressMatchesProofOfAddress,
    ];
    return formattedResponse;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Get Allowed Walllet Types of the customer
export const getAllowedWalletTypes = async (customerId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/wallet-types`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Create Customer Walllet
export const createCustomerWallet = async (
  customerId,
  walletTypeId,
  firstName,
  lastName
) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/wallets`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        externalUniqueId: generateUUID(),
        name: `${firstName} ${lastName}`,
        description: `${firstName} ${lastName} Wallet`,
        walletTypeId,
        status: "ACTIVE",
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// mpesa stk push to wallet
export const initiateStkPush = async (
  WalletId,
  amount,
  phone,
  description = ""
) => {
  try {
    const authentication = await authenticationToAstra();

    const response = await fetch(
      `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/payments`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authentication.headerValue,
        },
        body: JSON.stringify({
          additionalFields: [
            {
              id: "merchantWalletId",
              value: WalletId,
            },
          ],
          amount,
          currency: "KES",
          externalUniqueId: generateUUID(),
          externalWalletId: WalletId,
          phone,
          externalWalletType: "SFCM",
          description: description || "STK Push",
          type: "KE_DTB_STK_PUSH",
          status: "Pending",
        }),
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      if (Array.isArray(responseData) && responseData.length > 0) {
        const errors = responseData
          .map((error) => `${error.description}`)
          .join("\n");
        throw new Error(errors);
      } else {
        throw new Error("Failed to initiate STK push due to an unknown error.");
      }
    }

    // return responseData;
    return { ...responseData, status: "Success" };
  } catch (error) {
    if (error.response && error.response.data) {
      const serverErrors = error.response.data.errors || [];
      const formattedErrors = serverErrors
        .map(
          (err) =>
            `${err.code}: ${err.description} (Severity: ${err.severity}, Type: ${err.type})`
        )
        .join("\n");
      throw new Error(formattedErrors);
    } else {
      throw new Error(error.message);
    }
  }
};

// Upgrade JWT with Override
const updateJWTWithOverride = async (intentId, jwt, otp) => {
  try {
    const response = await fetch(`${ASTRA_BASE_URL}/authentication/jwt`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        intentId,
        jwt,
        otp,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error updating JWT with override:", errorData);
      throw new Error("Failed to update JWT with override. Check JWT format and content.");
    }

    const responseData = await response.json();
    console.log("Updated JWT Response:", responseData);
    return responseData;
  } catch (error) {
    console.error("Update JWT Error:", error);
    throw new Error("Failed to update JWT with override. Check console for details.");
  }
};

// Withdrawal from wallet
export const initiateWithdrawal = async (
  WalletId,
  amount,
  type,
  deliverToPhone = "",
  description = "",
  accountName = "",
  accountNumber = "",
  bankCode = ""
) => {
  try {
    const authentication = await authenticationToAstra();

    let requestBody = "";

    if (type === "KE_DTB_MPESA") {
      // mpesa withdrawal
      requestBody = JSON.stringify({
        deliverToPhone,
        reference: generateRandomString(),
        amount,
        description: description || "Withdrawal from wallet through M-Pesa",
        type,
        externalUniqueId: generateUUID(),
        status: "Pending",
      });
    } else if (type === "KE_DTB_PESALINK") {
      // pesalink withdrawal
      requestBody = JSON.stringify({
        accountName,
        accountNumber,
        branchCode: bankCode,
        bankCode,
        amount,
        description: description || "Withdrawal from wallet through PesaLink",
        type,
        externalUniqueId: generateUUID(),
        reference: generateRandomString(),
        location: "kenya",
        currency: "KES",
        status: "Pending",
      });
    } else {
      throw new Error("Unsupported withdrawal type.");
    }

    const response = await fetch(
      `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/wallets/${WalletId}/withdrawals`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: authentication.headerValue,
        },
        body: requestBody,
      }
    );

    const responseData = await response.json();
    console.log("Withdrawal Response:", responseData);

    const scaHeader = response.headers.get("Sca");
    console.log("SCA Header:", scaHeader);
    const idValue = scaHeader ? scaHeader.split(";")[0].split("=")[1] : null;
    console.log("SCA ID Value:", idValue);

    // if (!response.ok) {
    //   if (Array.isArray(responseData) && responseData.length > 0) {
    //     const errors = responseData
    //       .map((error) => `${error.description}`)
    //       .join("\n");
    //     throw new Error(errors);
    //   } else {
    //     throw new Error(
    //       "Failed to initiate withdrawal due to an unknown error."
    //     );
    //   }
    // }

    console.log(responseData[0].code);

    if (responseData[0].code === "SEC004" && idValue) {
      const otp = prompt("Please enter the OTP received on your phone:");
      if (!otp) {
        throw new Error("OTP is required.");
      }

      console.log("OTP:", otp)
      console.log("Old jwt", authentication.headerValue.split("Bearer ")[1]);
      const jwtUpgradeResponse = await updateJWTWithOverride(idValue, authentication.headerValue.split("Bearer ")[1], otp);
      console.log("JWT Upgrade Response:", jwtUpgradeResponse);
      // const upgradedJwt = jwtUpgradeResponse.jwt;
      const upgradedJwt = jwtUpgradeResponse.headerValue.split("Bearer ")[1];
      console.log("Upgraded JWT:", upgradedJwt);

      const retryResponse = await fetch(
        `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/wallets/${WalletId}/withdrawals`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${upgradedJwt}`,
          },
          body: requestBody,
        }
      );

      if (!retryResponse.ok) {
        const retryErrorData = await retryResponse.json();
        console.error("Retry Withdrawal Error:", retryErrorData);
        throw new Error("Failed to retry withdrawal. Check console for details.");
      }

      const retryData = await retryResponse.json();
      console.log("Retry Withdrawal Response:", retryData);
      return { ...retryData, status: "Success" };
    }

    // return responseData;
    return { ...responseData, status: "Success" };
  } catch (error) {
    if (error.response && error.response.data) {
      const serverErrors = error.response.data.errors || [];
      const formattedErrors = serverErrors
        .map(
          (err) =>
            `${err.code}: ${err.description} (Severity: ${err.severity}, Type: ${err.type})`
        )
        .join("\n");
      throw new Error(formattedErrors);
    } else {
      throw new Error(error.message);
    }
  }
};

// Get List Of Wallets Belonging To The Customer
export const getCustomerWallets = async (customerId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/customers/${customerId}/wallets`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Get Wallets Transactions

export const getWalletTransactions = async (walletId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/wallets/${walletId}/transactions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};


// register an organisation
export const registerOrganisation = async (
  name,
  companyNumber,
  businessType,
  businessRegistrationDate,
  email,
  taxNumber,
  phone1,
  // phone2,
  industrialClassification,
  industrialSector,
  // categoryCode,
  tradingName
) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        externalUniqueId: generateUUID(),
        // locale: "EN",
        type: "BUSINESS",
        name,
        companyNumber,
        businessType,
        businessRegistrationDate,
        email,
        taxNumber,
        phone1,
        // phone2,
        industrialClassification,
        industrialSector,
        // categoryCode,
        tradingName,
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Add a new address for the organisation
export const addOrgAddress = async (
  organisationId,
  addressType,
  city,
  country,
  line1,
  state,
  line2 = "",
  code = ""
) => {
  const authentication = await authenticationToAstra();
  const requestBody = {
    addressType,
    city,
    country,
    line1,
    state,
  };

  if (line2 !== "") {
    requestBody.line2 = line2;
  }

  if (code !== "") {
    requestBody.code = code;
  }

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/addresses`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// upload a document for the organisation
export const uploadOrganisationDocument = async (
  documentType,
  organisationId,
  base64EncodedDocument,
  mediaType
) => {
  const authentication = await authenticationToAstra();

  let API_URL = `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/documents`;

  if (
    documentType === "CERT_OF_REGISTRATION" ||
    documentType === "CERT_OF_INCORP" ||
    documentType === "BUSINESS_LICENSE" ||
    documentType === "PROOF_OF_ADDRESS" ||
    documentType === "TAX" ||
    documentType === "BANK_STATEMENT"
  ) {
    API_URL = `${API_URL}?performOcr=true&validateDocType=true`;
  }

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authentication.headerValue,
    },
    body: JSON.stringify({
      base64EncodedDocument,
      documentType,
      mediaType,
    }),
  });

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};


// Add a new position holder for the organisation
export const addPositionHolder = async (
  organisationId,
  customerId,
  position
) => {
  const authentication = await authenticationToAstra();
  const requestBody = {
    customerId,
    position
  };

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/positions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// do KYB
export const doKYB = async (organisationId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/ratify`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        type: "NORMAL",
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    const formattedResponse = [
      responseData.AddressMatchesCertOfReg,
      responseData.AddressMatchesCertofincorp,
      responseData.AddressMatchesProofOfAddress,
      responseData.BankStatementIsLegitimate,
      responseData.BusinessLicenseIsLegitimate,
      responseData.CertOfIncorpIsLegitimate,
      responseData.CertOfRegIsLegitimate,
      responseData.CompanyNameMatchesBusinessLicense,
      responseData.CompanyNameMatchesCertOfIncorp,
      responseData.CompanyNameMatchesCertOfReg,
      responseData.CompanyNameMatchesVerifiedBankStatement,
      responseData.CompanyNumberMatchesCertOfIncorp,
      responseData.CompanyNumberMatchesCertOfReg,
      responseData.CompanyRegistrationDateMatchesCertOfIncorp,
      responseData.CompanyRegistrationDateMatchesCertOfReg,
      responseData.CurrentYearMatchesBusinessLicense,
      responseData.TaxDocumentIsLegitimate,
      responseData.TaxDocumentMatchesNationalDatabase,
      responseData.TaxNumberIsLegitimate,
      responseData.TaxNumberMatchesNationalDatabase,
      responseData.TaxNumberMatchesTaxDocument,
      responseData.TaxPayerNameMatchesNationalDatabase,
      responseData.WorldCheck,
    ];
    return formattedResponse;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Get Allowed Walllet Types of the organisation
export const getAllowedOrganisationWalletTypes = async (organisationId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/wallet-types`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Create Organisation Walllet
export const createOrganisationWallet = async (
  organisationId,
  walletTypeId,
  companyName,
  // lastName
) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/wallets`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
      body: JSON.stringify({
        externalUniqueId: generateUUID(),
        name: `${companyName}`,
        description: `${companyName}'s Wallet`,
        walletTypeId,
        status: "ACTIVE",
      }),
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Get List Of Wallets Belonging To The Org
export const getOrganisationWallets = async (organisationId) => {
  const authentication = await authenticationToAstra();

  const response = await fetch(
    `${ASTRA_BASE_URL}/tenants/${authentication.tenantId}/organisations/${organisationId}/wallets`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authentication.headerValue,
      },
    }
  );

  if (response.status === 200) {
    const responseData = await response.json();
    return responseData;
  } else if (response.status === 400) {
    const errorData = await response.json();
    throw new Error(errorData[0].description);
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const LeaveTable = () => {
    const [approvals, setApprovals] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [firstApproval, setFirstApproval] = useState(false);
    const [secondApproval, setSecondApproval] = useState(false);
    const [editLeaveId, setEditLeaveId] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [otherLeaveCount, setOtherLeaveCount] = useState(0);
    const [annualLeaveCount, setAnnualLeaveCount] = useState(0);
    const [remainingLeaveCount, setRemainingLeaveCount] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('all');

    const [formData, setFormData] = useState({
        // employeeName:'',
        leaveCategory:'',
        startDate: '',
        endDate: '',
        noOfDays: 0,
        remainingLeaves: 0,
    });

    const getStatusColor = (status) => {
        switch (status) {
            case 'approved':
                return 'success';
            case 'pending':
                return 'warning';
            case 'denied':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    const filterData = () => {
        const statusFilter = (item) => {
            const firstStatus = item.firstStatus || 'pending';
            const secondStatus = item.secondStatus || 'pending';

            if (!selectedStatus) {
                return true;
            }

            const { all, approved, denied, pending, attended } = selectedStatus;
            const isAttended = new Date() > (item.endDate ? item.endDate.toDate() : null);
            if (all) {
                return true;
            } else if (approved) {
                return firstStatus === 'approved' && secondStatus === 'approved';
            } else if (denied) {
                return firstStatus === 'denied' && secondStatus === 'denied';
            } else if (pending) {
                return firstStatus === 'pending' && secondStatus === 'pending';
            } else if (attended){
                return (firstStatus === 'approved' && secondStatus === 'approved') && isAttended;
            } else{
                return true;
            }
        };

        const textFilter = (item) =>
            item.employeeName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.leaveCategory.toLowerCase().includes(searchInput.toLowerCase());

        if (searchInput === '' && !selectedDate) {
            return data.filter(item => !item.deleted && statusFilter(item));
        } else {
            return filteredData.filter(item => !item.deleted && statusFilter(item) && textFilter(item));
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'issue-leave');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);

                // Initialize approvals state
                const initialApprovals = dataArray.reduce((acc, item) => {
                    acc[item.id] = {
                        firstApproval: { status: item.firstStatus || 'pending', message: item.firstStatusMessage || '' },
                        secondApproval: { status: item.secondStatus || 'pending', message: item.secondStatusMessage || '' },
                    };
                    return acc;
                }, {});
                setApprovals(initialApprovals);

                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editLeaveId) {
                    const selectedLeave = dataArray.find(item => item.id === editLeaveId);
                    setFormData(selectedLeave);
                }

                console.log(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData, loading, editLeaveId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);


    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Leave Requests</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Leave Category</th>
                                <th>First Approval</th>
                                <th>Second Approval</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(item => (
                                <tr key={item.id}>
                                    <td>{item.employeeName || "All"}</td>
                                    <td>{item.leaveCategory}</td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${getStatusColor(approvals[item.id]?.firstApproval?.status || 'pending')}`}></i>
                                                {approvals[item.id]?.firstApproval?.status === 'approved' ? 'Approved' : approvals[item.id]?.firstApproval?.status === 'denied' ? 'Denied' : 'Pending'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">No access</a>
                                            </div>

                                        </div>
                                    </td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${getStatusColor(approvals[item.id]?.secondApproval?.status || 'pending')}`}></i>
                                                {approvals[item.id]?.secondApproval?.status === 'approved' ? 'Approved' : approvals[item.id]?.secondApproval?.status === 'denied' ? 'Denied' : 'Pending'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">No access</a>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/employees/employee-leaves">View all leave requests</Link>
                </div>
            </div>
        </div>
    )
}

export default LeaveTable;

import React, { useState, useEffect, useContext } from "react";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import BenefitsForm from "./benefitsForm.js";
import { UserContext } from "../../../InitialPage/App.js";

const BenefitsList = () => {
    const { userRole } = useContext(UserContext);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const [formData, setFormData] = useState({
        employeeName:'',
        lifeInsurance:'',
        disabilityInsurance:'',
        personalRelief:'',
        insuranceRelief:'',
        medicalFundRelief:'',
        otherReliefs:'',
        paye:'',
        nhif:'',
        nssf:'',
        otherDeductions: '',
    });
    const [focused, setFocused] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    // const [editFormData, setEditFormData] = useState(null);
    const [editBenefitId, setEditBenefitId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'approved':
                return 'success';
            case 'pending':
                return 'warning';
            case 'denied':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((benefit) =>
            benefit.employeeName.toLowerCase().includes(input.toLowerCase()) ||
            benefit.lifeInsurance.toLowerCase().includes(input.toLowerCase()) ||
            benefit.disabilityInsurance.toLowerCase().includes(input.toLowerCase()) ||
            benefit.personalRelief.toLowerCase().includes(input.toLowerCase()) ||
            benefit.medicalFundRelief.toLowerCase().includes(input.toLowerCase()) ||
            benefit.paye.toLowerCase().includes(input.toLowerCase()) ||
            benefit.nssf.toLowerCase().includes(input.toLowerCase()) ||
            benefit.nhif.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(benefit => !benefit.deleted);
        } else {
            return filteredData.filter(benefit => !benefit.deleted);
        }
    };

    const handleStatusChange = async (benefitId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-benefit', benefitId), { status: newStatus });

            const updatedData = data.map(benefit => {
                if (benefit.id === benefitId) {
                    return { ...benefit, status: newStatus };
                }
                return benefit;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the benefit data in the database
            await updateDoc(doc(db, 'add-benefit', editBenefitId), formData);

            // Clear the form data and reset the editBenefitId state
            setFormData({
                employeeName:'',
                lifeInsurance:'',
                disabilityInsurance:'',
                personalRelief:'',
                insuranceRelief:'',
                medicalFundRelief:'',
                otherReliefs:'',
                paye:'',
                nhif:'',
                nssf:'',
                otherDeductions: '',
            });
            setEditBenefitId(null);
        } catch (error) {
            console.error('Error updating benefit:', error);
        }
    };

    const handleDeleteBenefit = async (benefitId) => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'add-benefit', editBenefitId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(benefit =>
                benefit.id === editBenefitId ? { ...benefit, deleted: true } : benefit
            ));

        } catch (error) {
            console.error('Error deleting employee:', error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
        try {
            const colRef = collection(db, 'add-benefit');
            const querySnapshot = await getDocs(colRef);
            const dataArray = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(benefit => !benefit.deleted);
            setData(dataArray);
            setEntriesCount(dataArray.length);
            if (editBenefitId) {
                const selectedBenefit = dataArray.find(benefit => benefit.id === editBenefitId);
                setFormData(selectedBenefit);
            }
            console.log('Fetched data:', dataArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, [editBenefitId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Benefits</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hr/benefits-management/benefits-list">Benefits
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Benefits List</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_benefit">
                                                <i className="fa fa-plus"/> Add Benefit
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <table className="table table-striped custom-table datatable table-hover mt-4">
                                            <thead>
                                            <tr>
                                                <th>Employee Name</th>
                                                <th>Benefits Name</th>
                                                <th>Status</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <>
                                                {filterData().slice(0, entriesPerPage).map(benefit => (
                                                    <React.Fragment>
                                                        <tr className="clickable" data-toggle="collapse"
                                                            id={`row${benefit.id}`} data-target={`.row${benefit.id}`}
                                                            key={benefit.id}>
                                                            <td>{benefit.employeeName}</td>
                                                            <td>
                                                                <Link className="btn btn-sm btn-primary" to="#">
                                                                    View Benefits
                                                                </Link>

                                                            </td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${getStatusColor(benefit.status)}`}></i>
                                                                        {benefit.status === 'approved' ? 'Approved' : benefit.status === 'denied' ? 'Denied' : 'Pending'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(benefit.id, 'approved')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Approve
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(benefit.id, 'pending')}>
                                                                            <i className="fa-regular fa-circle-dot text-warning me-1"></i> Pending
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(benefit.id, 'denied')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deny
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="action-icon dropdown-toggle"
                                                                       data-bs-toggle="dropdown"
                                                                       aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#edit_benefit"
                                                                           onClick={() => setEditBenefitId(benefit.id)}><i
                                                                            className="fa-solid fa-pencil m-r-5"></i>
                                                                            Edit</a>
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#delete_benefit"
                                                                           onClick={() => setEditBenefitId(benefit.id)}><i
                                                                            className="fa-regular fa-trash-can m-r-5"></i>
                                                                            Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr className={`collapse row${benefit.id}`}>
                                                            <td></td>
                                                            <td>
                                                                {benefit.lifeInsurance && (
                                                                    <div>
                                                                        Life Insurance = {benefit.lifeInsurance}
                                                                    </div>
                                                                )}
                                                                {benefit.disabilityInsurance && (
                                                                    <div>
                                                                        Disability Insurance
                                                                        = {benefit.disabilityInsurance}
                                                                    </div>
                                                                )}
                                                                {benefit.personalRelief && (
                                                                    <div>
                                                                        Personal Relief = {benefit.personalRelief}
                                                                    </div>
                                                                )}
                                                                {benefit.insuranceRelief && (
                                                                    <div>
                                                                        Insurance Relief = {benefit.insuranceRelief}
                                                                    </div>
                                                                )}
                                                                {benefit.medicalFundRelief && (
                                                                    <div>
                                                                        Medical Fund Relief
                                                                        = {benefit.medicalFundRelief}
                                                                    </div>
                                                                )}
                                                                {benefit.otherReliefs && (
                                                                    <div>
                                                                        Other Relief = {benefit.otherReliefs}
                                                                    </div>
                                                                )}
                                                                {benefit.otherDeductions && (
                                                                    <div>
                                                                        Other Deductions = {benefit.otherDeductions}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                            </tbody>
                                        </table>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <BenefitsForm/>

                            {/*Edit benefit Modal*/}
                            <div id="edit_benefit" className="modal custom-modal fade" role="dialog">
                                <div
                                    className="modal-dialog modal-dialog-centered modal-md"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Staff benefit</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Employee Name<span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="employeeName"
                                                                autoComplete="off"
                                                                value={formData.employeeName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>Life Insurance</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="lifeInsurance"
                                                                autoComplete="off"
                                                                value={formData.lifeInsurance}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label>Disability Insurance</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="disabilityInsurance"
                                                            autoComplete="off"
                                                            value={formData.disabilityInsurance}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <h4 className="text-primary">Reliefs</h4>
                                                        <div className="input-block">
                                                            <label>Personal Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="personalRelief"
                                                                autoComplete="off"
                                                                value={formData.personalRelief}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>Insurance Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="insuranceRelief"
                                                                autoComplete="off"
                                                                value={formData.insuranceRelief}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>Medical Fund Relief</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="medicalFundRelief"
                                                                autoComplete="off"
                                                                value={formData.medicalFundRelief}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>Other Reliefs</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="otherReliefs"
                                                                autoComplete="off"
                                                                value={formData.otherReliefs}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h4 className="text-primary">Deductions</h4>
                                                        <div className="input-block">
                                                            <label>PAYE</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="paye"
                                                                autoComplete="off"
                                                                value={formData.paye}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>NHIF</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nhif"
                                                                autoComplete="off"
                                                                value={formData.nhif}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>NSSF</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nssf"
                                                                autoComplete="off"
                                                                value={formData.nssf}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>Other Deductions</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="otherDeductions"
                                                                autoComplete="off"
                                                                value={formData.otherDeductions}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Edit benefit Modal*/}

                            {/*Delete benefit Modal*/}
                            <div
                                className="modal custom-modal fade"
                                id="delete_benefit"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">

                                            <div className="form-header">
                                                <h3>Delete benefit</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              onClick={handleDeleteBenefit} data-bs-dismiss="modal">
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Delete benefit Modal*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BenefitsList;

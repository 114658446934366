import React from 'react';
import { useParams } from 'react-router-dom';
import SysAdminDashboard from './system-admin/SysAdminDashboard.js';
import AdminDashboard from './admin/AdminDashboard.js';
import HRDashboard from './hr/HRDashboard.js';
import HODDashboard from './hod/HODDashboard.js';
import FinanceDashboard from './finance/FinanceDashboard.js';
import EmployeeDashboard from './employee/EmployeeDashboard.js';

const Dashboard = (props) => {
  const { role } = useParams();

  const renderDashboardContent = (role) => {
    switch (role) {
      case 'sys-admin':
        return <SysAdminDashboard />;
      case 'admin':
        return <AdminDashboard />;
      case 'hr':
        return <HRDashboard />;
      case 'hod':
        return <HODDashboard />;
      case 'finance':
        return <FinanceDashboard />;
      case 'employee':
        return <EmployeeDashboard />;
      default:
        return <div>Invalid role</div>;
    }
  };

  return (
    <div>
      {renderDashboardContent(role)}
    </div>
  );
};

export default Dashboard;

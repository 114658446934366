import React, {useState, useEffect}  from "react";
import { db } from '../../firebase.js';
import { collection, addDoc } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EmployeeDepartmentForm = () => {
    const [formData, setFormData] = useState({
        departmentName: '',
        departmentHead: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            // Form is invalid, do not proceed with submission
            showToast('Form cannot be empty!', 'error');
            return;
        }

        try {
            const docRef = await addDoc(collection(db, 'departments'), formData);
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Department Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            {/* Add Department Modal */}
            <div
                id="add_department"
                className="modal custom-modal fade"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Department</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="input-block">
                                    <label>
                                        Department Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="departmentName"
                                        autoComplete="off"
                                        value={formData.departmentName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="input-block">
                                    <label>
                                        Department Head <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="departmentHead"
                                        autoComplete="off"
                                        value={formData.departmentHead}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department Modal */}r

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default EmployeeDepartmentForm;

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, getDocs, query, where, updateDoc, doc} from "firebase/firestore";
import {db} from "../../../../firebase";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const EmployeeSalaryTable = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [editSalaryId, setEditSalaryId] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        month: '',
        employeeName:[],
        currency: '',
        basicSalary: 0,
        insuranceContribution: 0,
    });

    const [resultsData, setResultsData] = useState({
        personalRelief: 2400,
        permissibleLimit: 20000,
        totalAllowance: 0,
        totalNonCashPay: 0,
        totalCashPay: 0,
        rentOfHouseComputed: 0,
        netValueOfHousing: 0,
        totalGrossPay: 0,
        pensionCashPay: 0,
        totalAmountOfBenefit: 0,
        insuranceRelief: 0,
        taxablePay: 0,
        taxPayable: 0,
        payePayable:0,
        nhifContribution:0,
        nssfContribution:0,
        totalDeductions: 0,
        // housingLevy: 0,
        netPay: 0,
    });

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (salaryId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-salary', salaryId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === salaryId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };


    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRefSalary = collection(db, 'add-salary');
                // const querySnapshotSalary = await getDocs(colRefSalary);
                let querySnapshotSalary;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshotSalary = await getDocs(query(colRefSalary, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshotSalary = await getDocs(colRefSalary);
                }

                const dataArraySalary = querySnapshotSalary.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);

                const dataWithAdditionalInfo = await Promise.all(dataArraySalary.map(async item => {
                    const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('employeeName', '==', item.employeeName)));
                    const employeeData = employeeQuerySnapshot.docs[0]?.data(); // Added check here
                    if (employeeData) {
                        return {
                            id: item.id,
                            ...item,
                            employeeName: employeeData.employeeName,
                            email: employeeData.email,
                            qualification: employeeData.qualification,
                            totalGrossPay: item.totalGrossPay,
                            netPay: item.netPay,
                        };

                    } else {
                        return null; // Return null if no matching document is found
                    }
                }));

                const filteredData = dataWithAdditionalInfo.filter(item => item !== null); // Filter out null entries

                setData(filteredData);
                setEntriesCount(filteredData.length);

                if (editSalaryId) {
                    const selectedSalary = dataArraySalary.find(item => item.id === editSalaryId);
                    setFormData(selectedSalary);
                    setResultsData(selectedSalary);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData, loading, editSalaryId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Employee Salary</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Gross Salary</th>
                                <th>Net Pay</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(item => (
                                <tr key={item.id}>
                                    <td>{item.employeeName}</td>
                                    <td>{item.totalGrossPay && new Intl.NumberFormat().format(item.totalGrossPay)}</td>
                                    <td>{item.netPay && new Intl.NumberFormat().format(item.netPay)}</td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${item.status === 'approved' ? 'success' : item.status === 'disapproved' ? 'danger' : 'purple'}`}></i>
                                                {item.status === 'approved' ? 'Approved' : item.status === 'disapproved' ? 'Denied' : 'New'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'approved')}>
                                                    <i className="fa-regular fa-circle-dot text-success me-1"></i> Approve
                                                </a>
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'disapproved')}>
                                                    <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deny
                                                </a>
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'new')}>
                                                    <i className="fa-regular fa-circle-dot text-purple me-1"></i> New
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/payroll/employee-salary">View all employees salaries</Link>
                </div>
            </div>
        </div>
    )
}

export default EmployeeSalaryTable;

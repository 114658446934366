import React from "react";
import {Link} from "react-router-dom";

const PayrollTableShortcuts = () => {
    return (
        <div className="col-md-12 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Payroll Shortcuts</h3>
                </div>
                <div className="card-body">
                    <div className="container">
                        <div className="row staff-grid-row">
                            <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4 mt-4">
                                <div className="profile-widget">
                                    <Link className="btn  btn-primary"
                                          to="/finance/payroll/previous-payroll">
                                        Run Payroll
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4 mt-4">
                                <div className="profile-widget">
                                    <Link className="btn  btn-primary"
                                          to="/finance/payroll/payroll-items">
                                        Manage Payroll Items
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-4 mt-4">
                                <div className="profile-widget">
                                    <Link className="btn  btn-primary"
                                          to="/finance/payroll/downloads">
                                        Generate Payroll Reports
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayrollTableShortcuts;

import React, {useState, useEffect}  from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs, where, query } from 'firebase/firestore';
import Select from "react-select";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import { getAuth } from "firebase/auth";
import { UserContext } from "../../../InitialPage/App.js";

import 'react-toastify/dist/ReactToastify.css';
const auth = getAuth();

const HodDepartmentForm = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        departmentName: '',
        departmentHead: '',
        employeeName: [],
        organisationId: 0,
    });

    const [employees, setEmployees] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();
    }, [userData, selectedCompany]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        // Check if the field is the departmentName field
        if (name === 'departmentName') {
            // If it is the departmentName field, check for invalid characters
            if (invalidPattern.test(value)) {
                // If it contains invalid characters, don't update the state and show an error message
                showToast('Invalid input: Special characters and symbols are not allowed', 'error');
                return;
            }
        }

        // Update the state for other fields
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };



    const requiredFields =
        [
            'departmentName',
            'departmentHead',
            'employeeName',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty, including the employeeName field
        const isFormEmpty = Object.entries(formData).every(([key, value]) => {
            if (Array.isArray(value)) {
                return value.length === 0; // Check if array is empty
            } else {
                return !value; // Check if other types of values are empty
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check if the employeeName field is empty (i.e., no options are selected)
        if (formData.employeeName.length === 0) {
            showToast('Employees cannot be empty!', 'error');
            return;
        }


        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        // Check if Department already exists
        const existingDeptQuery = query(collection(db, 'departments'), where('departmentName', '==', formData.departmentName));
        const existingDeptSnapshot = await getDocs(existingDeptQuery);

        if (!existingDeptSnapshot.empty) {
            showToast('Department already exists!', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'departments'), {
            //     ...formData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'departments'), {
                    ...formData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'departments'), {
                    ...formData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Department Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            {/* Add Department Modal */}
            <div
                id="add_hod_department"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Department</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>
                                                Department Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="departmentName"
                                                autoComplete="off"
                                                value={formData.departmentName}
                                                onChange={handleInputChange}
                                                required

                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>
                                                Department Head <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="departmentHead"
                                                autoComplete="off"
                                                value={formData.departmentHead}
                                                onChange={handleInputChange}
                                                required
                                            >
                                            <option value="">Select a department head</option>
                                            {employees.map(employee => (
                                                <option>
                                                    {employee.employeeName}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Select Employees <span className="text-danger">*</span></label>
                                            <Select
                                                isMulti
                                                name="employeeName"
                                                options={employees.map(employee => ({ value: employee.employeeName, label: employee.employeeName }))}
                                                value={formData.employeeName.map(selected => ({ value: selected, label: selected }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setFormData({ ...formData, employeeName: selectedValues });
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department Modal */}


            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default HodDepartmentForm;

import React, {useContext, useState, useEffect} from "react";
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import {UserContext} from "../../../../InitialPage/App";
import {Link, useParams} from "react-router-dom";
import {Applogo} from "../../../../Entryfile/imagepath";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase.js";
import html2pdf from 'html2pdf.js';
import {format} from "date-fns";
import {toast, ToastContainer, Slide} from "react-toastify";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const AnnualMusterRoll = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState(false);
    const { id } = useParams();
    const [varianceData, setVarianceData] = useState([]);
    const [employeeData, setEmployeeData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [kraPinNo, setkraPinNo] = useState(null);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const formattedYear = format(selectedDate, 'yyyy');

    const formattedMonth = format(selectedDate, 'MMMM');
    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployeeSalaries = async () => {
            try {
                // setLoading(true);

                const currentMonth = new Date().getMonth() + 1; // Get current month (1-indexed)
                const currentYear = new Date().getFullYear(); // Get current year
                const currentYearString = `${currentYear}`; // Format current year as 'YYYY'
                const currentMonthString = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`; // Format current month as 'YYYY-MM'

                const salariesQuery = query(collection(db, 'add-salary'), where('month', '==', currentMonthString));

                let salaryDocs;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    salaryDocs = await getDocs(query(salariesQuery, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    salaryDocs = await getDocs(salariesQuery);
                }

                const employeeDataMap = {}; // Object to store aggregated data by employeeName

                for (const doc of salaryDocs.docs) {
                    const employeeSalaryData = doc.data();
                    const employeeName = employeeSalaryData.employeeName;
                    // Initialize if employeeName doesn't exist in the map
                    if (!employeeDataMap[employeeName]) {
                        employeeDataMap[employeeName] = {
                            ...employeeSalaryData,
                            totalSalary: 0, // Initialize totalSalary
                            role: '', // Add other necessary fields
                            location: '', // Add other necessary fields
                            departmentName: '' // Add other necessary fields
                        };
                    }

                    // Increment salaries
                    employeeDataMap[employeeName].basicSalary += employeeSalaryData.basicSalary;
                    employeeDataMap[employeeName].totalCashPay += employeeSalaryData.totalCashPay;
                    employeeDataMap[employeeName].totalNonCashPay += employeeSalaryData.totalNonCashPay;
                    employeeDataMap[employeeName].totalGrossPay += employeeSalaryData.totalGrossPay;
                    employeeDataMap[employeeName].nssfContribution += employeeSalaryData.nssfContribution;
                    employeeDataMap[employeeName].totalDeductions += employeeSalaryData.totalDeductions;
                    employeeDataMap[employeeName].taxablePay += employeeSalaryData.taxablePay;
                    employeeDataMap[employeeName].taxPayable += employeeSalaryData.taxPayable;
                    employeeDataMap[employeeName].personalRelief += employeeSalaryData.personalRelief;
                    employeeDataMap[employeeName].insuranceRelief += employeeSalaryData.insuranceRelief;
                    employeeDataMap[employeeName].payePayable += employeeSalaryData.payePayable;
                    employeeDataMap[employeeName].nhifContribution += employeeSalaryData.nhifContribution;
                    employeeDataMap[employeeName].totalDeductions += employeeSalaryData.nhifContribution;
                    employeeDataMap[employeeName].netPay += employeeSalaryData.netPay;

                    // Fetch additional employee data from 'add-employee' collection if not already fetched
                    if (!employeeDataMap[employeeName].role) {
                        const employeeQuery = query(collection(db, 'add-employee'), where('employeeName', '==', employeeName));
                        const employeeDocs = await getDocs(employeeQuery);

                        if (!employeeDocs.empty) {
                            const employeeData = employeeDocs.docs[0].data();
                            employeeData.email = employeeDocs.docs[0].data().email;
                            // console.log(`Email fetched for employeeName: ${employeeData.email}`);
                            // handleEmailSend(employeeData.email);
                            employeeDataMap[employeeName].role = employeeData.role;
                            employeeDataMap[employeeName].location = employeeData.location;
                        }
                    }

                    // Fetch additional department data from 'departments' collection if not already fetched
                    if (!employeeDataMap[employeeName].departmentName) {
                        const departmentQuery = query(collection(db, 'departments'), where('employeeName', '==', employeeName));
                        const departmentDocs = await getDocs(departmentQuery);

                        if (!departmentDocs.empty) {
                            const departmentData = departmentDocs.docs[0].data();
                            employeeDataMap[employeeName].departmentName = departmentData.departmentName;
                        }
                    }
                }

                const employeeDataArray = Object.values(employeeDataMap); // Convert map values to array
                setEmployeeData(employeeDataArray);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee salaries:', error);
                setLoading(false);
            }
        };

        fetchEmployeeSalaries();
    }, [userData, loading]);


    useEffect(() => {
        const fetchPayrollData = async () => {
            try {
                // setLoading(true);

                const colRef = collection(db, 'add-salary');

                let payrollQuerySnapshot;

                if (userData && userData[0]?.role === 'sys-admin') {
                    const adminQuery = query(colRef, where('organisationId', '==', parseInt(selectedCompanyId)));
                    payrollQuerySnapshot = await getDocs(adminQuery);
                } else {
                    const userQuery = query(colRef, where('organisationId', '==', userData[0]?.organisationId));
                    payrollQuerySnapshot = await getDocs(userQuery);
                }
                
                const payrollData = payrollQuerySnapshot.docs.map(doc => doc.data());

                // Extract year from selectedDate
                const selectedYear = selectedDate.getFullYear();

                // Filter data for the selected year and previous year
                const currentYearData = payrollData.filter(data => {
                    if (data.month) {
                        const year = parseInt(data.month.split('-')[0]);
                        return year === selectedYear;
                    }
                    return false; // Exclude data without a defined month
                });

                const previousYear = selectedYear - 1;
                const previousYearData = payrollData.filter(data => {
                    if (data.month) {
                        const year = parseInt(data.month.split('-')[0]);
                        return year === previousYear;
                    }
                    return false; // Exclude data without a defined month
                });

                // Calculate totals for current year
                const currentYearTotal = calculateTotals(currentYearData);

                // Calculate totals for previous year
                const previousYearTotal = calculateTotals(previousYearData);

                // Calculate variance between current and previous year
                const varianceData = calculateYearlyVariance(currentYearTotal, previousYearTotal);

                console.log("Current Year Total:", currentYearTotal);
                console.log("Previous Year Total:", previousYearTotal);
                console.log("Yearly Variance Data:", varianceData);

                // Set the variance data to state
                setVarianceData(varianceData);
                setLoading(false);

            } catch (error) {
                console.error("Error fetching payroll data:", error);
                setLoading(false);
            }
        };

        // Function to calculate totals for a given dataset
        const calculateTotals = (data) => {
            return {
                paye: calculateTotal(data, 'payePayable'),
                nssf: calculateTotal(data, 'nssfContribution'),
                nhif: calculateTotal(data, 'nhifContribution'),
                netpay: calculateTotal(data, 'netPay'),
                employeeCount: data.length
            };
        };

        // Function to calculate total of a specific field in a dataset
        const calculateTotal = (data, field) => {
            return data.reduce((total, item) => {
                if (item[field] !== undefined) {
                    const value = parseFloat(item[field]);
                    return isNaN(value) ? total : total + value;
                }
                return total;
            }, 0);
        };

        // Function to calculate variance between current and previous year
        const calculateYearlyVariance = (currentYearTotal, previousYearTotal) => {
            const varianceData = [];
            for (const key in currentYearTotal) {
                if (currentYearTotal.hasOwnProperty(key) && key !== 'employeeCount') {
                    const currentValue = currentYearTotal[key];
                    const previousValue = previousYearTotal[key];
                    const variance = calculatePercentageVariance(currentValue, previousValue);
                    varianceData.push({ y: key.toUpperCase(), "Previous Year": previousValue.toFixed(2), "Current Year": currentValue.toFixed(2), "Variance (%)": variance.toFixed(2) });
                }
            }
            return varianceData;
        };

        // Function to calculate percentage variance between two values
        const calculatePercentageVariance = (currentValue, previousValue) => {
            const variance = ((currentValue - previousValue) / previousValue) * 100;
            return isNaN(variance) ? 0 : Math.abs(variance);
        };

        fetchPayrollData();
    }, [selectedDate]);


    const handlePDFExport = () => {
        const element = document.getElementById('muster-roll'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .set({
                pagebreak: { mode: 'avoid-all' }, // Prevent page breaks within content
                filename: 'annual-muster-roll.pdf',
                margin: [0, 0],
                jsPDF: { format: 'a3', orientation: 'landscape' }, // Set PDF page size and orientation
                html2canvas: { scale: 3 } // Adjust scaling if needed
            })
            .save();
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('muster-roll').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const generateCSV = () => {
        const csvRows = [];

        // Header row
        const header = [
            'Employee Name',
            'Role',
            'Department',
            'Location',
            'Currency',
            'Basic Salary (Kshs)',
            'Total Cash Pay',
            'Total Non Cash Pay',
            'Total Gross Pay',
            'NSSF Contribution',
            'Chargeable Pay',
            'Tax Charged',
            'Personal Relief',
            'Insurance Relief',
            'PAYE Tax (J-K) (Kshs)',
            'NHIF Contribution',
            'Total Deductions',
            'Net Salary'
        ];
        csvRows.push(header.join(','));

        // Data rows
        employeeData.forEach(employeeData => {
            const row = [
                employeeData.employeeName,
                employeeData.role,
                employeeData.departmentName,
                employeeData.location,
                employeeData.currency,
                employeeData.basicSalary || '',
                employeeData.totalCashPay || '',
                employeeData.totalNonCashPay || '',
                employeeData.totalGrossPay || '',
                employeeData.nssfContribution || '',
                employeeData.taxablePay || '',
                employeeData.taxPayable || '',
                employeeData.personalRelief || '',
                employeeData.insuranceRelief || '',
                employeeData.payePayable || '',
                employeeData.nhifContribution || '',
                employeeData.totalDeductions || '',
                employeeData.netPay.toFixed(2) || ''
            ];
            csvRows.push(row.join(','));
        });

        // Join rows with newline character
        const csvString = csvRows.join('\n');

        // Create a Blob object and initiate download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'annual-muster-roll.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleEmailSend = () => { //(recipientEmail)
        const AnnualMusterRollContainer = document.getElementById('muster-roll');
        
        if (!AnnualMusterRollContainer) {
            console.error('Annual Muster Roll container not found.');
            showToast('Error: Annual Muster Roll container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(AnnualMusterRollContainer)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "Annuall Muster roll for " + format(new Date(), 'yyyy'),
                        Body: "Please find attached the Annual Muster Roll for this year.",
                        Attachments: [
                            {
                                name: "annual-muster-roll.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('Annual Muster Roll sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending Annual Muster Roll via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Annual Muster Roll</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Annual Muster Roll</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i
                                                    className="fa fa-mail-bulk fa-lg"/> Email
                                                </button> */}
                                                <button className="btn btn-white" onClick={generateCSV}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                <button className="btn btn-white" onClick={handlePDFExport}><i
                                                    className="fa fa-file-pdf fa-lg"/> PDF
                                                </button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" id="muster-roll">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Annual Muster Roll for {formattedYear}</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        <img src={Applogo} className="inv-logo" alt=""/>
                                                        {/* <p>Employer's Name: <strong>******</strong></p> */}
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Annual Muster Roll</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table_wrapper">
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    {/* <th></th> */}
                                                                    {/* <th></th> */}
                                                                    <th></th>
                                                                    <th>Currency</th>
                                                                    <th>
                                                                        Basic <br/>
                                                                        Salary
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Cash <br/>
                                                                        Earnings <br/>
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Non <br/>
                                                                        Cash <br/>
                                                                        Benefits <br/>
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Gross <br/>
                                                                        Pay
                                                                    </th>
                                                                    <th>
                                                                        NSSF <br/>
                                                                        Contribution

                                                                    </th>
                                                                    <th>
                                                                        Taxable <br/>
                                                                        Earnings
                                                                    </th>
                                                                    <th>
                                                                        Tax <br/>
                                                                        Calculation- <br/>
                                                                        Normal
                                                                    </th>
                                                                    <th>Relief</th>
                                                                    <th>
                                                                        Insurance <br/>
                                                                        Relief
                                                                    </th>
                                                                    <th>PAYE</th>
                                                                    <th>NHIF</th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Deductions
                                                                    </th>
                                                                    <th>
                                                                        Net <br/>
                                                                        Pay
                                                                    </th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th>Number</th>
                                                                    <th>Month</th>
                                                                    <th>Employee</th>
                                                                    <th>
                                                                        Job <br/>
                                                                        Title
                                                                    </th>
                                                                    {/* <th>Department</th> */}
                                                                    {/* <th>Location</th> */}
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {employeeData && employeeData.map((employee, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{employee.month}</td>
                                                                        <td className="table-month">{employee.employeeName}</td>
                                                                        <td>
                                                                            {employee.role}
                                                                        </td>
                                                                        {/* <td>{employee.departmentName}</td> */}
                                                                        {/* <td>{employee.location}</td> */}
                                                                        <td>{employee.currency}</td>
                                                                        <td>{employee.basicSalary.toFixed(2)}</td>
                                                                        <td>{employee.totalCashPay.toFixed(2)}</td>
                                                                        <td>{employee.totalNonCashPay.toFixed(2)}</td>
                                                                        <td>{employee.totalGrossPay.toFixed(2)}</td>
                                                                        <td>{employee.nssfContribution.toFixed(2)}</td>
                                                                        <td>{employee.taxablePay.toFixed(2)}</td>
                                                                        <td>{employee.taxPayable.toFixed(2)}</td>
                                                                        <td>{employee.personalRelief.toFixed(2)}</td>
                                                                        <td>{employee.insuranceRelief.toFixed(2)}</td>
                                                                        <td>{employee.payePayable.toFixed(2)}</td>
                                                                        <td>{employee.nhifContribution.toFixed(2)}</td>
                                                                        <td>{employee.totalDeductions.toFixed(2)}</td>
                                                                        <td>{employee.netPay.toFixed(2)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-bordered-footer">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <div className="table_wrapper">
                                                            <h3 className="text-uppercase">VARIANCE SUMMARY</h3>
                                                            <table className="table table-bordered" id="table-bordered">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>Account</th>
                                                                    <th>
                                                                        First <br/>
                                                                        period <br/>
                                                                    </th>
                                                                    <th>
                                                                        Second <br/>
                                                                        period <br/>
                                                                    </th>
                                                                    <th>Variance</th>
                                                                    <th>
                                                                        Percentage <br/>
                                                                        Variance
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {varianceData.map((data, index) => {
                                                                        // Calculate variance and percentage variance
                                                                        const variance = (data["Current Year"] - data["Previous Year"]).toFixed(2);
                                                                        // const percentageVariance = ((variance / data["Previous Year"]) * 100).toFixed(2);

                                                                        const percentageVariance = data["Previous Year"] == 0
                                                                        ? (data["Current Year"] == 0 ? 0 : "No variance") // If both current and previous values are 0, return 0%, else return 100%
                                                                        : ((variance / data["Previous Year"]) * 100).toFixed(2);
                                                                        // const percentageVariance = ((variance / data["Previous Month"]) * 100).toFixed(2);

                                                                        return (
                                                                            <tr key={index} className="text-center">
                                                                                <td className="table-month">{data.y}</td>
                                                                                <td>{data["Previous Year"]}</td>
                                                                                <td>{data["Current Year"]}</td>
                                                                                <td>{variance}</td>
                                                                                <td>{percentageVariance}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default AnnualMusterRoll;

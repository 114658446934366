import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../../firebase";

const DepartmentsTable = () => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editDepartmentId, setEditDepartmentId] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        departmentName: '',
        departmentHead: '',
        employeeName: [],
    });

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'departments');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editDepartmentId) {
                    const selectedDepartment = dataArray.find(item => item.id === editDepartmentId);
                    setFormData(selectedDepartment);
                }
                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editDepartmentId]);

    // Rendering only the latest 4 records
    const latestFiveRecords = data.slice(0, 5);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Departments</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Department Name</th>
                                <th>Department Head</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {latestFiveRecords.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>{count + index}</td>
                                            <td>{item.departmentName}</td>
                                            <td>{item.departmentHead}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </>
                            </tbody>
                        </table>
                       
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFiveRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/hod/departments/department-list">View all departments</Link>
                </div>
            </div>
        </div>
    )
}

export default DepartmentsTable;

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../../../firebase";

const TeamsTable = () => {
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editTeamId, setEditTeamId] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        teamName: '',
        departmentName: '',
        departmentHead: '',
        employeeName: [],
    });


    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (teamId, newStatus) => {
        try {
            await updateDoc(doc(db, 'teams', teamId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === teamId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'teams');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editTeamId) {
                    const selectedTeam = dataArray.find(item => item.id === editTeamId);
                    setFormData(selectedTeam);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editTeamId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Teams</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable text-center">
                            <thead>
                            <tr>
                                <th>Team Name</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {latestFourRecords.map(item => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>{item.teamName}</td>
                                            <td>
                                                <div className="dropdown action-label mt-2">
                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                       data-bs-toggle="dropdown"
                                                       aria-expanded="false">
                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : 'danger'}`}></i>
                                                        {item.status === 'active' ? 'Active' : 'Inactive'}
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(item.id, 'active')}>
                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                        </a>
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/hod/departments/teams-list">View all teams</Link>
                </div>
            </div>
        </div>
    )
}

export default TeamsTable;

import React, {useContext, useState, useEffect} from "react";
import { db } from '../../../../firebase.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../../../InitialPage/App.js";
import { Applogo } from "../../../../Entryfile/imagepath.js";
import html2pdf from 'html2pdf.js';
import {toast, ToastContainer, Slide} from "react-toastify";
import {format} from "date-fns";
import { getAuth } from "firebase/auth";
const auth = getAuth();

const GeneratedPayrollSummary = () => {
    const { userRole} = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCompanyId = queryParams.get('companyId');

    const [selectedDate, setSelectedDate] = useState(null);
    const [menu, setMenu] = useState(false);
    const [payrollData, setPayrollData] = useState([]); // State to store payroll data
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null);
    const [formattedMonthYear, setFormattedMonthYear] = useState('');

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const month = params.get("month");
        const year = params.get("year");
        if (month && year) {
            setSelectedDate(new Date(parseInt(year), parseInt(month) - 1, 1));
        }
    }, [location.search]);

    useEffect(() => {
        const fetchCompanyName = async () => {
            if (selectedCompanyId) {
                const companyQuery = query(collection(db, 'add-company'), where("organisationId", "==", parseInt(selectedCompanyId)));
                const querySnapshot = await getDocs(companyQuery);

                if (!querySnapshot.empty) {
                    const companyData = querySnapshot.docs[0].data();
                    setCompanyName(companyData.companyName);
                }
            }
        };

        fetchCompanyName();
    }, [selectedCompanyId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!selectedDate) return;

                const formattedMonth = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}`;
                // const q = query(collection(db, 'add-salary'), where("month", "==", formattedMonth));
                let q;
                if (userData && userData[0]?.role !== 'sys-admin') {
                    q = query(collection(db, 'add-salary'), 
                    where("month", "==", formattedMonth), 
                    where("organisationId", "==", userData[0]?.organisationId));
                } else{
                    q = query(collection(db, 'add-salary'), 
                    where("month", "==", formattedMonth), 
                    where("organisationId", "==", parseInt(selectedCompanyId)));
                }

                const querySnapshot = await getDocs(q);

                let basic = 0;
                let totalGrossPay = 0;
                let nssf = 0;
                let paye = 0;
                let nhif = 0
                let net = 0;
                let deductions = 0;

                querySnapshot.forEach(doc => {
                    const data = doc.data();

                    const basicSalary = parseFloat(data.basicSalary) || 0;
                    basic += basicSalary;

                    const grossSalary = parseFloat(data.totalGrossPay) || 0;
                    totalGrossPay += grossSalary;

                    const nssfContribution = parseFloat(data.nssfContribution) || 0;
                    nssf += nssfContribution;

                    const payePayable = parseFloat(data.payePayable) || 0;
                    paye += payePayable;

                    const nhifContribution = parseFloat(data.nhifContribution) || 0;
                    nhif += nhifContribution;

                    const netPay = parseFloat(data.netPay) || 0;
                    net += netPay;

                    const totalDeductions = parseFloat(data.totalDeductions) || 0;
                    deductions += totalDeductions;

                });

                const aggregatedData = [
                    { fieldName: "Total Basic Salary", value: basic },
                    { fieldName: "Total Gross Salary", value: totalGrossPay },
                    { fieldName: "Total NSSF", value: nssf },
                    { fieldName: "Total Paye", value: paye },
                    { fieldName: "Total NHIF", value: nhif },
                    { fieldName: "Total Net Pay", value: net },
                    { fieldName: "Total Deductions", value: deductions },
                ];

                setPayrollData({basic, totalGrossPay, nssf, paye, nhif, net, deductions });
                setLoading(false);
                setError(null);
            } catch (error) {
                console.error("Error fetching payroll data:", error);
                setLoading(false);
                setError(error.message);
            }
        };

        fetchData();
    }, [userData, selectedDate, selectedCompanyId]);

    useEffect(() => {
        if (selectedDate) {
            const monthYear = selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' });
            setFormattedMonthYear(monthYear);
        }
    }, [selectedDate]);

    // console.log(payrollData);

    const handlePDFExport = () => {
        const element = document.getElementById('payroll-summary'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .save('payroll-summary.pdf');
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('payroll-summary').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const generateCSV = () => {
        // Ensure there is data available before generating CSV
        if (!payrollData) return;

        // Define CSV headers and rows
        const headers = [
            "Basic Salary",
            "Total Gross Pay",
            "NSSF",
            "PAYE",
            "NHIF",
            "Total Deductions",
            "Net Salary"
        ];

        const dataRows = [
            [
                payrollData.basic || 0,
                payrollData.totalGrossPay || 0,
                payrollData.nssf || 0,
                payrollData.paye.toFixed(2) || 0,
                payrollData.nhif || 0,
                payrollData.deductions || 0,
                payrollData.net.toFixed(2) || 0
            ]
        ];

        // Combine headers and data rows into a single CSV string
        let csvContent = headers.join(",") + "\n";
        csvContent += dataRows.map(row => row.join(",")).join("\n");

        // Create Blob object with CSV content
        const blob = new Blob([csvContent], { type: "text/csv" });

        // Create download link
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "payroll-summary.csv");

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    const handleEmailSend = () => { //(recipientEmail)
        const payrollSummaryContainer = document.getElementById('payroll-summary');
        
        if (!payrollSummaryContainer) {
            console.error('Payroll summary container not found.');
            showToast('Error: Payroll summary container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(payrollSummaryContainer)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "Payroll summary roll for " + format(new Date(), 'yyyy'),
                        Body: "Please find attached the Payroll summary for this year.",
                        Attachments: [
                            {
                                name: "payroll-summary.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('Payroll summary sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending Payroll summary via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Payroll Summary</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Payroll Summary</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i className="fa fa-mail-bulk fa-lg"/> Email</button> */}
                                                <button className="btn btn-white" onClick={generateCSV}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                <button className="btn btn-white" onClick={handlePDFExport}><i className="fa fa-file-pdf fa-lg"/> PDF</button>
                                                <button className="btn btn-white" onClick={handlePrint}><i className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" id="payroll-summary">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Payroll Summary for the month of {formattedMonthYear}</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        <img src={Applogo} className="inv-logo" alt=""/>
                                                        <h4>{companyName}</h4>
                                                    </div>

                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Payroll Summary</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-secondary">
                                                                    <th colSpan={2}>EARNINGS</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6">Basic Salary</th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{payrollData?.basic && new Intl.NumberFormat().format(payrollData?.basic )||  0}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6">Total Gross Pay</th>
                                                                    <th className="col-sm-6"><span
                                                                        className="float-end"><strong>{payrollData?.totalGrossPay && new Intl.NumberFormat().format(payrollData?.totalGrossPay )||  0}</strong></span>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-secondary">
                                                                    <th colSpan={2}>DEDUCTIONS</th>
                                                                </tr>

                                                                <tr>
                                                                    <th className="col-sm-6"><strong>NSSF</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{payrollData?.nssf && new Intl.NumberFormat().format(payrollData?.nssf )||  0}</span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <th className="col-sm-6"><strong>PAYE</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{payrollData?.paye && new Intl.NumberFormat().format(payrollData?.paye )||  0}</span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <th className="col-sm-6"><strong>NHIF</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{payrollData?.nhif && new Intl.NumberFormat().format(payrollData?.nhif )||  0}</span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Total
                                                                        Deductions</strong></th>
                                                                    <th className="col-sm-6"><span
                                                                        className="float-end">{payrollData?.deductions && new Intl.NumberFormat().format(payrollData?.deductions )||  0}</span>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                            <tr className="table-secondary">
                                                                <th colSpan={2}>NET PAY</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="col-sm-6"><strong>Net Salary</strong>
                                                                </th>
                                                                <th className="col-sm-6"><span
                                                                    className="float-end">{payrollData?.net && new Intl.NumberFormat().format(payrollData?.net )||  0}</span>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default GeneratedPayrollSummary;

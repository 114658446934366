import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const TimesheetTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [filteredSearchData, setFilteredSearchData] = useState(data);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [editTimesheetId, setEditTimesheetId] = useState(null);
    const [formData, setFormData] = useState({
        employeeName: '',
        projectName: '',
        timesheetDescription:'',
    });

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const filteredData = searchInput
        ? filteredSearchData.filter(item => {
            if (selectedDate) {
                const selectedMonthYear = format(selectedDate, 'yyyy-MM');
                const itemMonthYear = item.currentDate.slice(0, 7);
                return itemMonthYear === selectedMonthYear && !item.deleted;
            } else {
                return !item.deleted; // Show all entries when selectedDate is null
            }
        })
        : data.filter(item => {
            if (selectedDate) {
                const selectedMonthYear = format(selectedDate, 'yyyy-MM');
                const itemMonthYear = item.currentDate.slice(0, 7);
                return itemMonthYear === selectedMonthYear && !item.deleted;
            } else {
                return !item.deleted; // Show all entries when selectedDate is null
            }
        });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'timesheets');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editTimesheetId) {
                    const selectedTimesheet = dataArray.find(item => item.id === editTimesheetId);
                    setFormData(selectedTimesheet);
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData, loading, editTimesheetId]);

    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Submitted Timesheets</h3>
                </div>
                <div className="card-body text-center">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Projects</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(item => (
                                <tr key={item.id}>
                                    <td>{item.employeeName}</td>
                                    <td>{item.projectName}</td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${item.status === 'approved' ? 'success' : item.status === 'disapproved' ? 'danger' : 'purple'}`}></i>
                                                {item.status === 'approved' ? 'Approved' : item.status === 'disapproved' ? 'Denied' : 'New'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item">No access</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/hod/timesheets/submitted-timesheets">View all timesheets</Link>
                </div>
            </div>
        </div>
    )
}

export default TimesheetTable;

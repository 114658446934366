import React, {useState, useEffect}  from "react";
import { db } from '../../firebase.js';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeDesignationForm = () => {
    const [formData, setFormData] = useState({
        employeeName: '',
        designationName: '',
        departmentName: '',
    });

    const [employees, setEmployees] = useState([]);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];
            querySnapshot.forEach((doc) => {
                departmentList.push({ id: doc.id, ...doc.data() });
            });
            setDepartments(departmentList);
        };
        fetchDepartments();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            // Form is invalid, do not proceed with submission
            showToast('Form cannot be empty!', 'error');
            return;
        }

        try {
            const docRef = await addDoc(collection(db, 'designations'), formData);
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Designation Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            {/* Add Designation Modal */}
            <div
                id="add_designation"
                className="modal custom-modal fade"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Designation</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                    <div className="input-block">
                                        <label>Select Employee<span className="text-danger">*</span></label>
                                        <select
                                            className="form-select form-control"
                                            name="employeeName"
                                            value = {formData.employeeName}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select an employee</option>
                                            {employees.map(employee => (
                                                 <option>
                                                    {employee.employeeName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                <div className="input-block">
                                    <label>
                                        Designation Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="designationName"
                                        autoComplete="off"
                                        value={formData.designationName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="input-block">
                                        <label>Select Department<span className="text-danger">*</span></label>
                                        <select
                                            className="form-select form-control"
                                            name="departmentName"
                                            value = {formData.departmentName}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select a department</option>
                                            {departments.map(department => (
                                                 <option>
                                                    {department.departmentName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Designation Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default EmployeeDesignationForm;

import React, {useContext, useState, useEffect} from "react";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import TeamsTable from "./Table/TeamsTable";
import DepartmentsTable from "./Table/DepartmentsTable";
import LeaveCategoriesTable from "./Table/LeaveCategoriesTable";
import LeaveTable from "./Table/LeaveTable";
import TimesheetTable from "./Table/TimesheetTable";
import LeaveSchedulingTable from "./Table/LeaveSchedulingTable";
import PayrollTableShortcuts from "./Table/PayrollTableShortcuts";
import ProjectsTable from "./Table/ProjectsTable";
import { ToastContainer, toast, Slide } from "react-toastify";

const auth = getAuth();

function HODDashboardHome(){
    const { userRole } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [teams, setTeams] = useState([]);
    const [projects, setProjects] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [timesheets, setTimesheets] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            // const querySnapshot = await getDocs(collection(db, 'teams'));
            // const teamList = [];

            // querySnapshot.forEach((doc) => {
            //     const teamData = doc.data();
            //     if (!teamData.deleted) {
            //         teamList.push({ id: doc.id, ...teamData });
            //     }
            // });

            // setTeams(teamList);
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'teams');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const teamList = [];
                querySnapshot.forEach((doc) => {
                    const teamData = doc.data();
                    if (!teamData.deleted) {
                        teamList.push({ id: doc.id, ...teamData });
                    }
                });
    
                setTeams(teamList);
            }
        };
        fetchTeams();

        const fetchProjects = async () => {
            // const querySnapshot = await getDocs(collection(db, 'projects'));
            // const projectList = [];

            // querySnapshot.forEach((doc) => {
            //     const projectData = doc.data();
            //     if (!projectData.deleted) {
            //         projectList.push({ id: doc.id, ...projectData });
            //     }
            // });

            // setProjects(projectList);
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'projects');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const projectList = [];
                querySnapshot.forEach((doc) => {
                    const projectData = doc.data();
                    if (!projectData.deleted) {
                        projectList.push({ id: doc.id, ...projectData });
                    }
                });
    
                setProjects(projectList);
            }
        };
        fetchProjects();

        const fetchDepartments = async () => {
            // const querySnapshot = await getDocs(collection(db, 'departments'));
            // const departmentList = [];

            // querySnapshot.forEach((doc) => {
            //     const departmentData = doc.data();
            //     if (!departmentData.deleted) {
            //         departmentList.push({ id: doc.id, ...departmentData });
            //     }
            // });

            // setDepartments(departmentList);
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'departments');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const departmentList = [];
                querySnapshot.forEach((doc) => {
                    const departmentData = doc.data();
                    if (!departmentData.deleted) {
                        departmentList.push({ id: doc.id, ...departmentData });
                    }
                });
    
                setDepartments(departmentList);
            }
        };
        fetchDepartments();

        const fetchTimesheets = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                // const querySnapshot = await getDocs(collection(db, 'timesheets'));
                const colRef = collection(db, 'timesheets');
                    // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const timesheetList = [];

                querySnapshot.forEach((doc) => {
                    const timesheetData = doc.data();
                    if (!timesheetData.deleted) {
                        timesheetList.push({ id: doc.id, ...timesheetData });
                    }
                });

                setTimesheets(timesheetList);
            }
        };
        fetchTimesheets();

    }, [userData, loading]);
    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                    {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                {/* <h3 className='page-title'>Welcome {user.companyName ? user.companyName : `${user.firstName} ${user.lastName}`}!</h3> */}
                                {/* <h3 className='page-title'>Welcome {user.firstName} {user.lastName}!</h3> */}
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-circle-user'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{teams.length}</h3>
                                        <span>Teams</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-rectangle-list'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{projects.length}</h3>
                                        <span>Projects</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-regular fa-building'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{departments.length}</h3>
                                        <span>Departments</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-calendar-days'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{timesheets.length}</h3>
                                        <span>Submitted Timesheets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <DepartmentsTable/>
                        <TeamsTable/>
                    </div>

                    <div className="row">
                        <LeaveCategoriesTable/>
                        <LeaveTable/>
                    </div>

                    <div className="row">
                        <TimesheetTable/>
                    </div>

                    <div className="row">
                        <LeaveSchedulingTable/>
                        <ProjectsTable/>
                    </div>

                    <div className="row">
                        <PayrollTableShortcuts/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HODDashboardHome;

import React, {useContext, useEffect, useState} from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import {Link} from "react-router-dom";
import {UserContext} from "../../InitialPage/App";
import {collection, onSnapshot, query, where, getDocs} from "firebase/firestore";
import {db} from "../../firebase";
import { getAuth } from "firebase/auth";
const auth = getAuth();

const Notifications = () => {
    const { userRole } = useContext(UserContext);
    const [menu, setMenu] = useState(false);

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);


    useEffect(() => {
        if (userData.length > 0 && userData[0].organisationId) {
            const unsubscribe = onSnapshot(
                query(
                    collection(db, "projects"),
                    where("organisationId", "==", userData[0].organisationId)
                ),
                (snapshot) => {
                    const updatedNotifications = [];
                    snapshot.forEach((doc) => {
                        const project = { id: doc.id, ...doc.data() };
                        updatedNotifications.push({
                            id: project.id,
                            contents: "New project added",
                            contents_2: `${project.projectName}`,
                        });
                    });
                    setNotifications(updatedNotifications);
                }
            );

            // Clean up
            return () => unsubscribe();
        }
    }, [userData]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="page-title">Notifications</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Notifications</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="activity">
                                            <div className="activity-box">
                                                {notifications.map((notification) => (
                                                    <ul className="activity-list">
                                                        <li>
                                                            <div className="activity-content">
                                                                <div className="">
                                                                    {notification.contents}<br/>
                                                                    <p className="timeline-content">
                                                                        {notification.contents_2}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notifications;

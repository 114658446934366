import React, {useState, useEffect, useContext} from 'react';
import { db } from '../../../../firebase.js';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { Applogo } from "../../../../Entryfile/imagepath.js";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import numberToWords from 'number-to-words';
import { UserContext } from '../../../../InitialPage/App.js';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';
import axios from "axios";
import {toast, ToastContainer, Slide} from "react-toastify";
import emailjs from '@emailjs/browser';
import jsPDF from 'jspdf';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const Payslip = ({ payslip }) => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    const { ids } = useParams();
    const selectedEmployeeIds = ids.split(',');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeDataList, setEmployeeDataList] = useState([]);
    const [employeeData, setEmployeeData] = useState(null);
    const [qualification, setQualification] = useState(null);
    const [netSalaryInWords, setNetSalaryInWords] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employeeDataArray = [];

                if (!userData || userData.length === 0 || !userData[0].organisationId) {
                    console.error("User data is not available or incomplete.", userData);
                    // setError("User data is not available or incomplete.");
                    setLoading(false);
                    return;
                }

                const organisationId = userData[0].organisationId;
            // console.log("Using organisationId:", organisationId);

            if (selectedEmployeeIds.includes('all')) {
                // Fetch all employee data from add-employee collection
                const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('organisationId', '==', organisationId)));

                for (const doc of employeeQuerySnapshot.docs) {
                    const employeeData = doc.data();
                    employeeData.id = doc.id;
                    // console.log("Fetched employee data:", employeeData);

                    // Fetch salary data from add-salary collection based on employeeName
                    const salaryQuery = query(collection(db, 'add-salary'), where('employeeName', '==', employeeData.employeeName));
                    const salaryQuerySnapshot = await getDocs(salaryQuery);

                    if (!salaryQuerySnapshot.empty) {
                        const salaryData = salaryQuerySnapshot.docs[0].data();
                        // console.log("Fetched salary data for employee:", employeeData.employeeName, salaryData);
                        employeeData.month = salaryData.month;
                        employeeData.basicSalary = salaryData.basicSalary;
                        employeeData.totalGrossPay = salaryData.totalGrossPay;
                        employeeData.netPay = salaryData.netPay;
                        employeeData.payePayable = salaryData.payePayable;
                        employeeData.nssfContribution = salaryData.nssfContribution;
                        employeeData.nhifContribution = salaryData.nhifContribution;
                        employeeData.taxablePay = salaryData.taxablePay;
                        employeeData.taxPayable = salaryData.taxPayable;
                        employeeData.totalCashPay = salaryData.totalCashPay;
                        employeeData.totalNonCashPay = salaryData.totalNonCashPay;
                        employeeData.totalAmountOfBenefit = salaryData.totalAmountOfBenefit;
                        employeeDataArray.push(employeeData); // Only push if salary data exists
                    } else {
                        // console.warn(`Salary data not found for employeeName: ${employeeData.employeeName}`);
                    }
                }
                } else {
                    // Fetch data for selected employee IDs from add-employee collection
                    const promises = selectedEmployeeIds.map(async id => {
                        try {
                            // Fetch employee data from add-employee collection
                            const employeeDocRef = doc(db, 'add-employee', id);
                            const employeeDocSnapshot = await getDoc(employeeDocRef);
    
                            if (employeeDocSnapshot.exists()) {
                                const employeeData = employeeDocSnapshot.data();
                                employeeData.id = id;
    
                                // Fetch salary data from add-salary collection based on employeeName
                                const salaryQuery = query(collection(db, 'add-salary'), where('employeeName', '==', employeeData.employeeName));
                                const salaryQuerySnapshot = await getDocs(salaryQuery);
    
                                if (!salaryQuerySnapshot.empty) {
                                    const salaryData = salaryQuerySnapshot.docs[0].data();
                                    employeeData.month = salaryData.month;
                                    employeeData.basicSalary = salaryData.basicSalary;
                                    employeeData.totalGrossPay = salaryData.totalGrossPay;
                                    employeeData.netPay = salaryData.netPay;
                                    employeeData.payePayable = salaryData.payePayable;
                                    employeeData.nssfContribution = salaryData.nssfContribution;
                                    employeeData.nhifContribution = salaryData.nhifContribution;
                                    employeeData.taxablePay = salaryData.taxablePay;
                                    employeeData.taxPayable = salaryData.taxPayable;
                                    employeeData.totalCashPay = salaryData.totalCashPay;
                                    employeeData.totalNonCashPay = salaryData.totalNonCashPay;
                                    employeeData.totalAmountOfBenefit = salaryData.totalAmountOfBenefit;
                                } else {
                                    console.error(`Salary data not found for employeeName: ${employeeData.employeeName}`);
                                }
    
                                // Fetch company data based on organisationId
                                const companyQuery = query(
                                    collection(db, 'add-company'),
                                    where('organisationId', '==', employeeData.organisationId)
                                );
                                const companyDocs = await getDocs(companyQuery);
                                const companyDataArray = companyDocs.docs.map(doc => doc.data());
    
                                if (companyDataArray.length > 0) {
                                    employeeData.companyData = companyDataArray;
                                }
    
                                return employeeData;
                            } else {
                                // Fetch salary data from add-salary collection based on ID
                                const salaryDocRef = doc(db, 'add-salary', id);
                                const salaryDocSnapshot = await getDoc(salaryDocRef);
    
                                if (salaryDocSnapshot.exists()) {
                                    const salaryData = salaryDocSnapshot.data();
                                    salaryData.id = id;
    
                                    // Fetch employee data from add-employee collection based on employeeName
                                    const employeeQuery = query(collection(db, 'add-employee'), where('employeeName', '==', salaryData.employeeName));
                                    const employeeQuerySnapshot = await getDocs(employeeQuery);
    
                                    if (!employeeQuerySnapshot.empty) {
                                        const employeeData = employeeQuerySnapshot.docs[0].data();
                                        salaryData.kraPinNo = employeeData.kraPinNo;
                                        salaryData.qualification = employeeData.qualification;
                                        salaryData.email = employeeData.email;
                                    } else {
                                        console.error(`Employee data not found for employeeName: ${salaryData.employeeName}`);
                                    }
    
                                    // Fetch company data based on organisationId
                                    const companyQuery = query(
                                        collection(db, 'add-company'),
                                        where('organisationId', '==', salaryData.organisationId)
                                    );
                                    const companyDocs = await getDocs(companyQuery);
                                    const companyDataArray = companyDocs.docs.map(doc => doc.data());
    
                                    if (companyDataArray.length > 0) {
                                        salaryData.companyData = companyDataArray;
                                    }
    
                                    return salaryData;
                                } else {
                                    throw new Error(`Employee data not found for ID: ${id}`);
                                }
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            throw error;
                        }
                    });
    
                    const fetchedEmployeeDataArray = await Promise.all(promises);
                    employeeDataArray.push(...fetchedEmployeeDataArray);
                }
                setEmployeeDataList(employeeDataArray);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
    
        fetchData();
    }, [userData, loading, selectedEmployeeIds]);  

    const [netSalaryInWordsList, setNetSalaryInWordsList] = useState([]);

    // useEffect(() => {
    //     // Convert net salary to words for each employee when employeeDataList changes
    //     const netSalaryInWordsArray = employeeDataList.map(employeeData => {
    //         return numberToWords.toWords(employeeData.netPay);
    //     });
    //     setNetSalaryInWordsList(netSalaryInWordsArray);
    // }, [employeeDataList]);
    useEffect(() => {
        const netSalaryInWordsArray = employeeDataList.map(employeeData => {
            return numberToWords.toWords(employeeData.netPay || 0);
        });
        setNetSalaryInWordsList(netSalaryInWordsArray);
    }, [employeeDataList]);
    

    // Function to handle PDF button click
    const handlePDFExport = () => {
        const element = document.getElementById('payslip-container'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .save('payslip.pdf');
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('payslip-container').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const handleEmailSend = () => { //(recipientEmail)
        const payslipContainer = document.getElementById('payslip-container');
        
        if (!payslipContainer) {
            console.error('Payslip container not found.');
            showToast('Error: Payslip container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(payslipContainer)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "Payslip for " + format(new Date(), 'MMMM yyyy'),
                        Body: "Please find attached the payslip for this month.",
                        Attachments: [
                            {
                                name: "payslip.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('Payslip sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending payslip via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    
    // const handleEmailSend = () => {
    //     window.Email.send({
    //         Host : "smtp.elasticemail.com",
    //         Username : "sealpayroll@gmail.com",
    //         Password : "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
    //         To : 'jgitaridev@gmail.com',
    //         From : "sealpayroll@gmail.com",
    //         Subject : "Payslip for " + format(new Date(), 'MMMM yyyy'),
    //         Body : "Please find attached the payslip for this month.",
    //         Attachments : [
    //             {
    //                 name : "payslip.pdf",
    //                 data : 'data:application/pdf;base64,' + window.btoa(document.getElementById('payslip-container').innerHTML)
    //             }]
    //     }).then(
    //         message => {
    //             console.log(message);
    //             showToast('Payslip sent successfully via email', 'success');
    //         }
    //     ).catch(
    //         error => {
    //             console.error('Error sending email:', error);
    //             showToast('Error sending payslip via email', 'error');
    //         }
    //     );
    // };

    const handleCSVExport = () => {
        // Prepare CSV content
        let csvContent = "Employee Name, Salary Month, Basic Salary, Total Allowance Amount, Total Gross Pay, PAYE, NSSF, NHIF, Other Deductions, Total Deductions, Taxable Pay, Tax Payable, Total Cash Pay, Total Non-Cash Pay, Amount of Benefit, Net Pay\n";

        // Append each employee's data to CSV content
        employeeDataList.forEach(employeeData => {
            // Check if payroll data exists
            let otherDeductionsContent = '';
            if (employeeData.payrollData !== undefined && employeeData.payrollData.length > 0) {
                // If payroll data exists, concatenate deduction names and amounts
                // console.log("Payroll Data Exists:", employeeData.payrollData);
                otherDeductionsContent = employeeData.payrollData.map(deduction => deduction.deductionName + ': ' + deduction.deductionAmount).join(', ');
            }

            csvContent += `${employeeData.employeeName}, ${format(new Date(employeeData.month), 'MMMM yyyy')}, ${employeeData.basicSalary || 0}, ${employeeData.totalAllowance || 0}, ${employeeData.totalGrossPay || 0}, ${employeeData.payePayable || 0}, ${employeeData.nssfContribution || 0}, ${employeeData.nhifContribution || 0}, ${otherDeductionsContent || 0}, ${employeeData.totalDeductions || 0}, ${employeeData.taxablePay || 0}, ${employeeData.taxPayable || 0}, ${employeeData.totalCashPay || 0}, ${employeeData.totalNonCashPay.toFixed(2) || 0}, ${employeeData.totalAmountOfBenefit.toFixed(2) || 0}, ${employeeData.netPay || 0}\n`;
        });

        // Create Blob with CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Trigger download
        saveAs(blob, 'payslip.csv');
    };

    const formattedYear = format(selectedDate, 'MMMM-yyyy');
    const formattedMonth = format(selectedDate, 'MMMM');

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Payslip</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item"><Link
                                                    to={`/dashboard/${userRole}`}>Dashboard</Link></li>
                                                <li className="breadcrumb-item active">Payslip</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i className="fa fa-mail-bulk fa-lg"/> Email</button> */}
                                                <button className="btn btn-white" onClick={handleCSVExport}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                {/* <button className="btn btn-white" onClick={handleCSVExport}>CSV</button> */}
                                                <button className="btn btn-white" onClick={handlePDFExport}><i className="fa fa-file-pdf fa-lg"/> PDF</button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {error && <div>Error: {error}</div>}
                                {!error && (
                                <div className="row" id="payslip-container" style={{pageBreakInside: 'avoid'}}>
                                {employeeDataList.map((employeeData, index) => (
                                    <div key={index} className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Payslip for the month of {format(new Date(employeeData.month), 'MMMM-yyyy')}</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        <img src={Applogo} className="inv-logo" alt=""/>
                                                        <ul className="list-unstyled mb-0">
                                                            {employeeData.companyData && employeeData.companyData.map(company => (
                                                                <React.Fragment key={company.id}>
                                                                    <li>{company.companyName}</li>
                                                                    <li>{company.address?.city}, {company.address?.country}</li>
                                                                </React.Fragment>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Payslip</h3>
                                                            <ul className="list-unstyled">
                                                                <li>Salary Month: <span>{format(new Date(employeeData.month), 'MMMM yyyy')}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b-20">
                                                        <ul className="list-unstyled">
                                                            <li><h5 className="mb-0">
                                                                <strong>{employeeData.employeeName}</strong></h5></li>
                                                            <li><span>{employeeData.role}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-primary">
                                                                    <th colSpan={2}>EARNINGS</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6">Basic Salary</th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.basicSalary && new Intl.NumberFormat().format(employeeData.basicSalary)}</span>
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th className="col-sm-6">Total Allowance Amount</th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.totalCashPay && new Intl.NumberFormat().format(employeeData.totalCashPay) || 0}</span>
                                                                    </td>
                                                                </tr> */}
                                                                <tr>
                                                                    <th className="col-sm-6">Total Gross Pay</th>
                                                                    <th className="col-sm-6"><span
                                                                        className="float-end"><strong>{employeeData.totalGrossPay && new Intl.NumberFormat().format(employeeData.totalGrossPay)}</strong></span>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-primary">
                                                                    <th colSpan={2}>DEDUCTIONS</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>PAYE</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.payePayable && new Intl.NumberFormat().format(employeeData.payePayable)}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>NSSF</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.nssfContribution && new Intl.NumberFormat().format(employeeData.nssfContribution)}</span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <th className="col-sm-6"><strong>NHIF</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.nhifContribution && new Intl.NumberFormat().format(employeeData.nhifContribution)}</span>
                                                                    </td>
                                                                </tr>
                                                                 <tr>
                                                                    <th className="col-sm-6"><strong>Other Deductions</strong></th>
                                                                    <td className="col-sm-6">
                                                                        <span className="float-end">
                                                                            {employeeData.payrollData && employeeData.payrollData.length > 0 ? (
                                                                                employeeData.payrollData.map((deduction, i) => (
                                                                                    <div key={i}>
                                                                                        {deduction.deductionName}: {deduction.deductionAmount && new Intl.NumberFormat().format(deduction.deductionAmount)}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                0
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <th className="col-sm-6"><strong>Total Deductions</strong></th>
                                                                    <th className="col-sm-6"><span
                                                                        className="float-end">{employeeData.totalDeductions && new Intl.NumberFormat().format(employeeData.totalDeductions)}</span>
                                                                    </th>
                                                                </tr> */}
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-primary">
                                                                    <th colSpan={2}>TAX COMPUTATION</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Taxable
                                                                        Pay</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.taxablePay && new Intl.NumberFormat().format(employeeData.taxablePay)}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Tax
                                                                        Payable</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.taxPayable && new Intl.NumberFormat().format(employeeData.taxPayable)}</span>
                                                                    </td>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div>
                                                            <h4 className="m-b-10"><strong></strong></h4>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                <tr className="table-primary">
                                                                    <th colSpan={2}>OTHER INFORMATION</th>
                                                                </tr>
                                                                </thead>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Total Cash Pay</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.totalCashPay && new Intl.NumberFormat().format(employeeData.totalCashPay)}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Total Non-Cash Pay</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.totalNonCashPay && new Intl.NumberFormat().format(employeeData.totalNonCashPay.toFixed(2))}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="col-sm-6"><strong>Amount of Benefit</strong></th>
                                                                    <td className="col-sm-6"><span
                                                                        className="float-end">{employeeData.totalAmountOfBenefit && new Intl.NumberFormat().format(employeeData.totalAmountOfBenefit.toFixed(2))}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                            <tr className="table-primary">
                                                                <th colSpan={2}>NET PAY</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="col-sm-6"><strong>Net Pay</strong>
                                                                </th>
                                                                <th className="col-sm-6"><span
                                                                    className="float-end">{employeeData.netPay && new Intl.NumberFormat().format(employeeData.netPay)} ({netSalaryInWordsList[index]})</span>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                )}
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>

    );
}

export default Payslip;

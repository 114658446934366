import React, {useContext, useState} from "react";
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import {Link} from "react-router-dom";
import {UserContext} from "../../../../InitialPage/App";
import {Applogo} from "../../../../Entryfile/imagepath";

const LeaveEntitlement = () => {
    const { userRole } = useContext(UserContext);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Leaves</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hr/leave-management/hr-leave-reports/leave-entitlement">Leave
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Leave Entitlement</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_hr_leave_entitlement"
                                            >
                                                <i className="fa fa-plus"/> Add Leave Entitlement
                                            </Link>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                <button className="btn btn-white"><i
                                                    className="fa fa-mail-bulk fa-lg"/> Email
                                                </button>
                                                <button className="btn btn-white"><i
                                                    className="fa fa-file-pdf fa-lg"/> PDF
                                                </button>
                                                <button className="btn btn-white"><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" id="p9-container">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Leave Entitlement Report</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                    <img src={Applogo} className="inv-logo" alt=""/>
                                                        <p>Employer's Name: <strong>******</strong></p>
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Leave Entitlement</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Employee Name</th>
                                                                    <th>Leave Category</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* Add Leave Entitlement Modal */}
                            <div
                                id="add_hr_leave_entitlement"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Leave Entitlement</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form noValidate>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Employee Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="employeeName"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>No of Days <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="noOfDays"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Add Leave Entitlement Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaveEntitlement;

import React, {useState, useEffect, useContext} from "react";
import { db } from '../../firebase.js';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import {Avatar_02} from "../../Entryfile/imagepath.js";
import EmployeeOvertimeForm from "./employeeOvertimeForm.js";
import { UserContext } from "../../InitialPage/App.js";

const EmployeeOvertime = () => {
    const { userRole } = useContext(UserContext);
    const [count, setCount] = useState(1);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        employeeName: '',
        overtimeDate: '',
        overtimeHours: '',
        overtimeType: '',
        overtimeDescription: '',
    });
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [employees, setEmployees] = useState([]);

    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);

    const [editOvertimeId, setEditOvertimeId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };


    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.employeeName.toLowerCase().includes(input.toLowerCase()) ||
            item.overtimeDate.toLowerCase().includes(input.toLowerCase()) ||
            item.overtimeType.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const allData = filterData();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
    const currentYear = currentDate.getFullYear();

    const data1 = allData.filter(item => {
        const itemDate = new Date(item.overtimeDate);
        return itemDate.getMonth() + 1 === currentMonth && itemDate.getFullYear() === currentYear;
    });

    const totalEmployees = data1.length;

    const totalOvertimeHours = data1.reduce((total, item) => total + item.overtimeHours,0);

    const pendingOvertimeRequests = data1.filter(item => item.status == 'new').length;

    const deniedOvertimeRequests = data1.filter(item => item.status == 'inactive').length;


    const handleStatusChange = async (overtimeId, newStatus) => {
        try {
            await updateDoc(doc(db, 'overtime', overtimeId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === itemId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the overtime data in the database
            await updateDoc(doc(db, 'overtime', editOvertimeId), formData);

            // Clear the form data and reset the editOvertimeId state
            setFormData({
                employeeName: '',
                overtimeDate: '',
                overtimeHours: '',
                overtimeType: '',
                overtimeDescription: '',
            });
            setEditOvertimeId(null);
        } catch (error) {
            console.error('Error updating overtime:', error);
        }
    };

    const handleDeleteOvertime = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'overtime', editOvertimeId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editOvertimeId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting overtime:', error);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'overtime');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editOvertimeId) {
                    const selectedOvertime = dataArray.find(item => item.id === editOvertimeId);
                    setFormData(selectedOvertime);
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editOvertimeId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Overtime</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/employees/employees-list">Employees</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Overtime</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_overtime">
                                                <i className="fa fa-plus"/> Add Overtime
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                                            <div className="stats-info">
                                                <h6>Overtime Employee</h6>
                                                <h4>
                                                    {totalEmployees}<span> this month</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                                            <div className="stats-info">
                                                <h6>Overtime Hours</h6>
                                                <h4>
                                                    {totalOvertimeHours}<span> this month</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                                            <div className="stats-info">
                                                <h6>Pending Request</h6>
                                                <h4>{pendingOvertimeRequests}</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                                            <div className="stats-info">
                                                <h6>Rejected</h6>
                                                <h4>{deniedOvertimeRequests}</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="table-header">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_length">
                                                            <label>
                                                                Show
                                                                <select className="form-control form-control-sm">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                Entries
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_filter">
                                                            <label>
                                                                Search:
                                                                <input
                                                                    className="form-control form-control-sm"
                                                                    value={searchInput}
                                                                    onChange={handleSearchInputChange}
                                                                />

                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row dt-row mt-4">
                                                <div className="table-responsive">
                                                    <table className="table table-striped custom-table">
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>OT Date</th>
                                                            <th>OT Hours</th>
                                                            <th>OT Type</th>
                                                            <th>Description</th>
                                                            <th>Status</th>
                                                            <th>Approved By</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {filterData().map((item, index) => (
                                                            <tr key={item.id}>
                                                                <td>{count + index}</td>
                                                                <td>
                                                                    <h2 className="table-avatar">
                                                                        <Link to="/employee-profile" className="avatar">
                                                                            <img alt="" src={Avatar_02}/>
                                                                        </Link>
                                                                        <Link to="/employee-profile">
                                                                            {item.employeeName}
                                                                        </Link>
                                                                    </h2>
                                                                </td>
                                                                <td>{item.overtimeDate}</td>
                                                                <td>{item.overtimeHours}</td>
                                                                <td>{item.overtimeType}</td>
                                                                <td>{item.overtimeDescription}</td>
                                                                <td>
                                                                    <div className="dropdown action-label text-center">
                                                                        <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false">
                                                                            <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : item.status === 'new' ? 'purple' : 'danger'}`}></i>
                                                                            {item.status === 'active' ? 'Active' : item.status === 'new' ? 'New' : 'Inactive'}
                                                                        </a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item"
                                                                               onClick={() => handleStatusChange(item.id, 'active')}>
                                                                                <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                                            </a>
                                                                            <a className="dropdown-item"
                                                                               onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                                                <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                                            </a>
                                                                            <a className="dropdown-item"
                                                                               onClick={() => handleStatusChange(item.id, 'new')}>
                                                                                <i className="fa-regular fa-circle-dot text-purple me-1"></i> New
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h2 className="table-avatar">
                                                                        <Link to="/employee-profile" className="avatar">
                                                                            <img alt="" src={Avatar_02}/>
                                                                        </Link>
                                                                        <Link to="/employee-profile">Richard
                                                                            Miles</Link>
                                                                    </h2>
                                                                </td>
                                                                <td>
                                                                    <div className="dropdown dropdown-action text-end">
                                                                        <Link
                                                                            to="#"
                                                                            className="action-icon dropdown-toggle"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            <i className="material-icons">more_vert</i>
                                                                        </Link>
                                                                        <div
                                                                            className="dropdown-menu dropdown-menu-right">
                                                                            <Link
                                                                                className="dropdown-item"
                                                                                to="#"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#edit_overtime"
                                                                                onClick={() => setEditOvertimeId(item.id)}>
                                                                                <i className="fa fa-pencil m-r-5"/> Edit
                                                                            </Link>
                                                                            <Link
                                                                                className="dropdown-item"
                                                                                to="#"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#delete_overtime"
                                                                                onClick={() => setEditOvertimeId(item.id)}>
                                                                                <i className="fa fa-trash m-r-5"/> Delete
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="table-footer">
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="dataTables_info">
                                                        Showing 1 to {entriesCount} of {entriesCount} entries
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <EmployeeOvertimeForm/>

                            {/* Edit Overtime Modal */}
                            <div
                                id="edit_overtime"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Overtime</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="input-block">
                                                    <label>Select Employee</label>
                                                    <select
                                                        className="form-select form-control"
                                                        name="employeeName"
                                                        value={formData.employeeName}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select an employee</option>
                                                        {employees.map(employee => (
                                                            <option>
                                                                {employee.employeeName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="input-block">
                                                    <label>
                                                        Overtime Date <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={selectedDate2}
                                                            onChange={handleDateChange2}
                                                            name="overtimeDate"
                                                            className="form-control floating datetimepicker"
                                                            type="date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-block">
                                                    <label>
                                                        Overtime Hours <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="overtimeHours"
                                                        value={formData.overtimeHours}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="input-block">
                                                    <label>
                                                        Overtime Type <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="overtimeType"
                                                        value={formData.overtimeType}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="input-block">
                                                    <label>
                                                        Description <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea
                                                        rows={4}
                                                        type="text"
                                                        className="form-control"
                                                        name="overtimeDescription"
                                                        value={formData.overtimeDescription}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Overtime Modal */}

                            {/* Delete Overtime Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_overtime"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Overtime</h3>
                                                <p>Are you sure want to Cancel this?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDeleteOvertime}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Overtime Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeOvertime;

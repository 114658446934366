import React, { useContext, useState, useEffect } from "react";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import CompanyTable from "./Table/CompanyTable";
import EmployeesTable from "./Table/EmployeesTable";
import HolidayTable from "./Table/HolidayTable";
import EmployeeSalaryTable from "./Table/EmployeeSalaryTable";
import TimesheetTable from "./Table/TimesheetTable";
import ProjectScheduleTable from "./Table/ProjectScheduleTable";
import PayrollVarianceTable from "./Table/PayrollVarianceTable";
import PayrollTableShortcuts from "./Table/PayrollTableShortcuts";
import { toast } from "react-toastify";

const auth = getAuth();

function AdminDashboardHome(){
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         if (!auth.currentUser) {
    //             toast.error("User not found. Please login again.");
    //             return;
    //         }

    //         const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
    //         const querySnapshot = await getDocs(q);

    //         if (querySnapshot.empty) {
    //             toast.error(
    //                 "User not found. Please register an account on astra first on registration page"
    //             );
    //             return;
    //         }

    //         const userDataArray = querySnapshot.docs.map(doc => ({
    //             id: doc.id,
    //             ...doc.data()
    //         }));
    //         setUserData(userDataArray);
    //     };

    //     fetchUserData();
    // },);

    const [employees, setEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [timesheets, setTimesheets] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            // Check if userData has a defined value before using it in the query
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'add-employee');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();
                    if (employeeData.status === 'active' && !employeeData.deleted) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
    
                setEmployees(employeeList);
            }
        };

        fetchEmployees();

        const fetchCompanies = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'add-company');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const companyList = [];
                querySnapshot.forEach((doc) => {
                    const companyData = doc.data();
                    if (companyData.status === 'active' && !companyData.deleted) {
                        companyList.push({ id: doc.id, ...companyData });
                    }
                });
    
                setCompanies(companyList);
            }
        };

        fetchCompanies();

        const fetchHolidays = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'holidays');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const holidayList = [];
                querySnapshot.forEach((doc) => {
                    const holidayData = doc.data();
                    if (!holidayData.deleted) {
                        holidayList.push({ id: doc.id, ...holidayData });
                    }
                });
    
                setHolidays(holidayList);
            }
        };
        fetchHolidays();

        const fetchTimesheets = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                // const querySnapshot = await getDocs(collection(db, 'timesheets'));
                const colRef = collection(db, 'timesheets');
                    // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const timesheetList = [];

                querySnapshot.forEach((doc) => {
                    const timesheetData = doc.data();
                    if (!timesheetData.deleted) {
                        timesheetList.push({ id: doc.id, ...timesheetData });
                    }
                });

                setTimesheets(timesheetList);
            }
        };
        fetchTimesheets();

    }, [userData, loading]);

    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                    {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-gifts'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{holidays.length}</h3>
                                        <span>Holidays</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-users'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{companies.length}</h3>
                                        <span>Active Companies</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-user'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{employees.length}</h3>
                                        <span>Active Employees</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-regular fa-calendar-days'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{timesheets.length}</h3>
                                        <span>Submitted Timesheets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* <CompanyTable/> */}
                        <EmployeesTable/>
                        <EmployeeSalaryTable/>
                    </div>

                    <div className="row">
                        <TimesheetTable/>
                        <HolidayTable/>
                    </div>

                    {/* <div className="row">
                    </div> */}

                    <div className="row">
                        {/* <PayrollVarianceTable/> */}
                        <ProjectScheduleTable/>
                    </div>

                    <div className="row">
                        <PayrollTableShortcuts/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminDashboardHome;

import React, { useEffect, useState, useContext } from "react";
import { db } from '../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link} from "react-router-dom";
import {TimePicker} from "antd";
import {Avatar_02} from "../../Entryfile/imagepath.js";
import EmployeeShiftSchedulingForm from "./employeeShiftSchedulingForm.js";
import { UserContext } from "../../InitialPage/App.js";
import dayjs from 'dayjs';
import Select from "react-select";
const auth = getAuth();

const EmployeeShiftScheduling = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [focused, setFocused] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);
    const [selectedDate4, setSelectedDate4] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [menu, setMenu] = useState(false);
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredSearchData, setFilteredSearchData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [editShiftId, setEditShiftId] = useState(null);
    const [selectedDay, setSelectedDay] = useState('All Days');

    const [formData, setFormData] = useState({
        shiftType: '',
        workDays: [],
        offDays: [],
        startTime: '00:00:00',
        endTime: '00:00:00',
        breakHours: '',
        workHoursPerDay: 0,
        workHoursPerWeek: 0,
    });

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };
    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };
    const handleDateChange4 = (date) => {
        setSelectedDate4(date);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const options = [
        { value: 'Sunday', label: 'Sunday' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' }
    ]

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };

    const handleOptionsChange = (selectedOptions, actionMeta) => {
        const updatedFormData = { ...formData };

        if (actionMeta.name === 'workDays') {
            updatedFormData.workDays = selectedOptions;
        } else if (actionMeta.name === 'offDays') {
            updatedFormData.offDays = selectedOptions;
        }

        setFormData(updatedFormData);
    };

    const onChangeStartTime = (time, timeString) => {
        setFormData({
            ...formData,
            startTime: timeString
        });
    };

    const onChangeEndTime = (time, timeString) => {
        setFormData({
            ...formData,
            endTime: timeString
        });
    };

    const calculateWorkHours = () => {
        const { workDays, breakHours, startTime, endTime } = formData;

        const breakTime = parseFloat(breakHours) || 0;
        const start = dayjs(startTime, "HH:mm:ss");
        const end = dayjs(endTime, "HH:mm:ss");

        const totalHours = end.diff(start, 'hours', true);
        const workHoursPerDay = totalHours - breakTime;

        const totalWorkDays = workDays.length;
        const workHoursPerWeek = workHoursPerDay * totalWorkDays;

        setFormData({
            ...formData,
            workHoursPerDay: workHoursPerDay.toFixed(2),
            workHoursPerWeek: workHoursPerWeek.toFixed(2),
        });
    };

    useEffect(() => {
        calculateWorkHours();
    }, [formData.workDays, formData.breakHours, formData.startTime, formData.endTime]);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted 
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };

        fetchEmployees();
    }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'shifts');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'shifts');
                    
                let querySnapshot;

                if (userData && (userData[0]?.role === 'admin' || userData[0]?.role === 'hod')) {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else if (userData && userData[0]?.role === 'sys-admin'){
                    querySnapshot = await getDocs(colRef);
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(query(colRef, where('customerId', '==', userData[0]?.customerId)));
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editShiftId) {
                    const selectedShift = dataArray.find(item => item.id === editShiftId);
                    // console.log("selected shift", selectedShift);
                    setFormData(selectedShift);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, entriesPerPage, editShiftId]);

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.employeeName.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        let filtered = data.filter(item => !item.deleted);

        // if (selectedDay !== 'All Days') {
        //     filtered = filtered.filter(item => item.workDays.includes(selectedDay));
        // }
        if (selectedDay !== 'All Days') {
            filtered = filtered.filter(item => item.workDays.some(day => day.label === selectedDay));
        }
        
        if (searchInput !== '') {
            filtered = filtered.filter(item =>
                item.employeeName.toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        return filtered;
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            await updateDoc(doc(db,'shifts', editShiftId),formData);

            setFormData({
                shiftType: '',
                workDays: [],
                offDays: [],
                startTime: '00:00:00',
                endTime: '00:00:00',
                breakHours: '',
                workHoursPerDay: 0,
                workHoursPerWeek: 0,
            });

            setEditShiftId(null);
        }catch (error) {
            console.error('Error updating shifts:', error);
        }
    };

    const handleDelete = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'shifts', editShiftId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editShiftId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting shifts:', error);
        }
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="page-title">Daily Scheduling</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/employees/employees-list">Employees</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Shift Scheduling</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                        {(userRole === 'hr' || userRole === 'hod' || userRole === 'admin') && (
                                            <Link
                                                to="#"
                                                className="btn add-btn m-r-5"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_schedule"
                                            >
                                                {" "}
                                                Assign Shifts
                                            </Link>
                                        )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-6">
                                        <div
                                            className={
                                                focused
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }
                                        >
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                            <label className="focus-label">Employee Name</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="input-block form-focus select-focus">
                                        <select className="form-select form-control" value={selectedDay} onChange={handleDayChange}>
                                            <option value="All Days">Clear</option>
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                            <option value="Sunday">Sunday</option>
                                        </select>
                                            <label className="focus-label">Work Days</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table">
                                                    <thead>
                                                    <tr>
                                                        <th>Scheduled Shift</th>
                                                        <th>Monday</th>
                                                        <th>Tuesday</th>
                                                        <th>Wednesday</th>
                                                        <th>Thursday</th>
                                                        <th>Friday</th>
                                                        <th>Saturday</th>
                                                        <th>Sunday</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map(item => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <h2 className="table-avatar">
                                                                <Link
                                                                    to="#"
                                                                    className="avatar"
                                                                >
                                                                    <img src={Avatar_02}/>
                                                                </Link>
                                                                <Link to="#">
                                                                    {item.employeeName} <span
                                                                    className="table-span">{item.qualification}</span>
                                                                </Link>
                                                            </h2>
                                                        </td>
                                                        {item.workDays.map(day => (
                                                            <td key={day.label}>
                                                                <span className="badge bg-inverse-success">
                                                                    <h2>
                                                                        <span className="username-info m-b-10">
                                                                            {dayjs(item.startTime, 'HH:mm:ss').format('h:mm A')} - {dayjs(item.endTime, 'HH:mm:ss').format('h:mm A')}
                                                                        </span>
                                                                        <span className="userrole-info"> - {item.shiftType}</span>
                                                                    </h2>
                                                                </span>
                                                            </td>
                                                        ))}
                                                        {item.offDays.map(day => (
                                                            <td key={day.label}>
                                                                 <span className="badge bg-inverse-danger">
                                                                    <h2>
                                                                        Off
                                                                    </h2>
                                                                </span>
                                                            </td>
                                                        ))}
                                                        {/* </td> */}
                                                        {/* {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                                                        <td key={day.label}>
                                                            {item.workDays.includes(day) ? (
                                                                <span className="badge bg-inverse-success">
                                                                    <h2>
                                                                    <span className="username-info m-b-10">
                                                                        {dayjs(item.startTime, 'HH:mm:ss').format('h:mm A')} - {dayjs(item.endTime, 'HH:mm:ss').format('h:mm A')}
                                                                    </span>
                                                                        <span className="userrole-info"> - {item.shiftType}</span>
                                                                    </h2>
                                                                </span>
                                                            ) : (
                                                                <span className="badge bg-inverse-danger">
                                                                    <h2>
                                                                        Off
                                                                    </h2>
                                                                </span>
                                                            )}
                                                        </td>
                                                        ))} */}
                                                        <td>
                                                            <div className="dropdown profile-action">
                                                                <Link
                                                                    to="#"
                                                                    className="action-icon dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="material-icons">more_vert</i>
                                                                </Link>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#edit_shift"
                                                                        onClick={() => setEditShiftId(item.id)}
                                                                    >
                                                                        <i className="fa fa-pencil m-r-5"/> Edit
                                                                    </Link>

                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#delete_shift"
                                                                        onClick={() => setEditShiftId(item.id)}
                                                                    >
                                                                        <i className="fa-regular fa-trash-can m-r-5"/> Delete
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    Showing 1 to {entriesCount} of {entriesCount} entries
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <EmployeeShiftSchedulingForm/>

                            {/* Edit Assign Shift Modal */}
                            <div id="edit_shift"
                                 className="modal custom-modal fade"
                                 role="dialog"
                                 data-bs-backdrop="static"
                                 data-bs-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered modal-lg"
                                    role="document"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Assigned Shift</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className='input-block'>
                                                            <label>Shift Type <span className="text-danger">*</span></label>

                                                            <select
                                                                className="form-control form-select"
                                                                name='shiftType'
                                                                value={formData.shiftType}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value>Select Shift Type</option>
                                                                <option value="Regular Shift">Regular Shift</option>
                                                                <option value="Overtime Shift">Overtime Shift</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className='input-block'>
                                                            <label>Work Days <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="workDays"
                                                                options={options.filter(option => !formData.offDays.map(opt => opt.value).includes(option.value))}
                                                                value={formData.workDays}
                                                                onChange={handleOptionsChange}
                                                            />
                                                        </div>
                                                        <p className="text-primary text-xs">
                                                            Kindly ensure all days are catered for
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className='input-block'>
                                                            <label>Off Days <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="offDays"
                                                                options={options.filter(option => !formData.workDays.map(opt => opt.value).includes(option.value))}
                                                                value={formData.offDays}
                                                                onChange={handleOptionsChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Start Time <span className="text-danger">*</span>
                                                            </label>
                                                            <div>
                                                                <TimePicker
                                                                    className="input-group-text"
                                                                    onChange={onChangeStartTime}
                                                                    bordered={false}
                                                                    value={formData.startTime ? dayjs(formData.startTime, "HH:mm:ss") : null}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                End Time <span className="text-danger">*</span>
                                                            </label>
                                                            <TimePicker
                                                                className="input-group-text"
                                                                onChange={onChangeEndTime}
                                                                bordered={false}
                                                                value={formData.endTime ? dayjs(formData.endTime, "HH:mm:ss") : null}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Break Hours / Day
                                                            </label>
                                                            <input
                                                                type='text'
                                                                name='breakHours'
                                                                autoComplete="off"
                                                                className="form-control"
                                                                value={formData.breakHours}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Work Hours / Day
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="workHoursDay"
                                                                value={formData.workHoursPerDay}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Work Hours / Week
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="workHoursWeek"
                                                                value={formData.workHoursPerWeek}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Assign Shift Modal */}

                            {/* Delete Assign Shift Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_shift"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Assigned Shift</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="#" className="btn btn-primary continue-btn"  onClick={handleDelete} data-bs-dismiss="modal">
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to="#"
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Shift Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeShiftScheduling;

/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import { headerlogo } from "../../Entryfile/imagepath.js";
import headerlogo from "../../assets/img/logo-light.png";
import { UserContext } from '../../InitialPage/App.js';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { app, db, messaging } from '../../firebase.js';
import { collection, addDoc, onSnapshot , query, where, getDocs } from 'firebase/firestore';

const auth = getAuth();

const Header = (props) => {
    const layoutMode = document.body.getAttribute("data-layout-mode");

    const handlesidebar = () => {
        document.body.classList.toggle("mini-sidebar");
    };
    const onMenuClik = () => {
        props.onMenuClick();
    };

    const handleLogout = () => {
        const auth = getAuth(app);
        signOut(auth)
            .then(() => {
                // Clear user data from local storage
                localStorage.removeItem('userRole');
                // Redirect to login page
                setUserRole('');
            })
            .catch((error) => {
                console.error('Error signing out:', error);
            });
    };

    const location = useLocation();

    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [notifications, setNotifications] = useState([]);

    // useEffect(() => {
    //     const unsubscribe = onSnapshot(collection(db, "projects"), (snapshot) => {
    //       const updatedNotifications = [];
    //       snapshot.forEach((doc) => {
    //         const project = { id: doc.id, ...doc.data() };
    //         updatedNotifications.push({
    //           id: project.id,
    //         //   name: project.projectName,
    //           contents: "New project added",
    //           contents_2: `${project.projectName}`,
    //         });
    //       });
    //       setNotifications(updatedNotifications);
    //     });

    //     // Clean up
    //     return () => unsubscribe();
    //   }, []);
    useEffect(() => {
        if (userData.length > 0 && userData[0].organisationId) {
            const unsubscribe = onSnapshot(
                query(
                    collection(db, "projects"),
                    where("organisationId", "==", userData[0].organisationId)
                ),
                (snapshot) => {
                    const updatedNotifications = [];
                    snapshot.forEach((doc) => {
                        const project = { id: doc.id, ...doc.data() };
                        updatedNotifications.push({
                            id: project.id,
                            contents: "New project added",
                            contents_2: `${project.projectName}`,
                        });
                    });
                    setNotifications(updatedNotifications);
                }
            );

            // Clean up
            return () => unsubscribe();
        }
    }, [userData]);

    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(prevState => !prevState);
    };

    // Function to send notification
    const sendNotification = (project) => {
        setNotifications((prevNotifications) => [
        ...prevNotifications,
        {
            id: project.id,
            // name: project.name,
            contents: "New project added",
            contents_2: `Project name: ${project.projectName}`,
        },
        ]);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);


    const latestFourRecords = notifications.slice(0, 4);

    return (
        <div className="header" style={{ right: "0px" }}>
            {/* Logo */}
            <div className="header-left">
                <Link to={`/dashboard/${userRole}`} className="logo">
                    <img src={headerlogo} width={50} height={50} alt="" />
                </Link>
            </div>
            {/* /Logo */}
            <Link
                id="toggle_btn"
                to="#"
                style={{
                    display: location.pathname.includes("tasks")
                        ? "none"
                        : location.pathname.includes("compose")
                            ? "none"
                            : "",
                }}
                onClick={handlesidebar}>
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
            </Link>
            {/* Header Title */}
            <div className="page-title-box">
                <h3>RhoPAY</h3>
            </div>
            {/* /Header Title */}
            <Link
                id="mobile_btn"
                className="mobile_btn"
                to="#"
                onClick={() => onMenuClik()}>
                <i className="fa fa-bars" />
            </Link>
            {/* Header Menu */}
            <ul className="nav user-menu">
                {/* Notifications */}
                <li className="nav-item dropdown">
                    <a
                        to="#"
                        className="dropdown-toggle nav-link"
                        onClick={toggleNotifications}>
                        <i className="fa-regular fa-bell"/>{" "}
                        {notifications.length > 0 && (
                            <span className="badge badge-pill">{notifications.length}</span>
                        )}
                    </a>
                    {showNotifications && (
                    <div className={`dropdown-menu dropdown-menu-end notifications show`}>
                        <div className="topnav-dropdown-header">
                            <span className="notification-title">Notifications</span>
                        </div>
                        <div className="noti-content">
                            <ul className="notification-list">
                                {latestFourRecords.map((notification) => (
                                <li className="notification-message" key={notification.id}>
                                    <Link to="/notifications">
                                        <div className="media d-flex">
                                            <div className="media-body">
                                                <p className="noti-details">
                                                <span className="noti-title"></span>{" "}
                                                    {notification.contents}<br/>
                                                    <span className="noti-title">
                                                        {notification.contents_2}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="topnav-dropdown-footer">
                            <Link
                                className="text-primary"
                                onClick={() => localStorage.setItem("minheight", "true")}
                                to="/notifications">
                                View all Notifications
                            </Link>
                        </div>
                    </div>
                    )}
                </li>
                {/* /Notifications */}

                <li className="nav-item dropdown has-arrow main-drop">
                {userData.length > 0 ? (
                    userData.map(user => (
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link"
                            data-bs-toggle="dropdown"
                            key={user.id}
                        >
                            {user.companyName ? (
                                <>
                                <span className="user-img me-1">
                                    <img
                                        src={`https://api.dicebear.com/6.x/initials/svg?seed=${user.companyName}`}
                                        alt="user avatar" />
                                    <span className="status online" />
                                </span><span>{user.companyName}</span>
                                </>
                            ) : user.employeeName ? (
                                <>
                                    <span className="user-img me-1">
                                        <img
                                            src={`https://api.dicebear.com/6.x/initials/svg?seed=${user.employeeName}`}
                                            alt="user avatar"
                                        />
                                        <span className="status online" />
                                    </span>
                                    <span>{user.employeeName}</span>
                                </>
                            ) : (
                                <>
                                <span className="user-img me-1">
                                        <img
                                            src={`https://api.dicebear.com/6.x/initials/svg?seed=${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                                            alt="user avatar" />
                                        <span className="status online" />
                                </span>
                                <span>{user.firstName} {user.lastName}</span>
                                </>
                            )}

                        </Link>
                    ))
                ) : (
                    <Link
                        to="#"
                        className="dropdown-toggle nav-link"
                        data-bs-toggle="dropdown"
                    >
                        <span className="user-img me-1">
                            <img
                                src={`https://api.dicebear.com/6.x/initials/svg?seed=User`}
                                alt="user avatar"
                            />
                            <span className="status online" />
                        </span>
                        <span>User</span>
                    </Link>
                )}

                    <div className="dropdown-menu dropdown-menu-end">
                            <Link className="dropdown-item" to="/profile">
                                My Profile
                            </Link>
                            <Link className="dropdown-item" to="/settings/change-password">
                                Settings
                            </Link>
                            <Link className="dropdown-item" to="/" onClick={handleLogout}>
                                Logout
                            </Link>
                        </div>

                </li>
            </ul>
            {/* /Header Menu */}
            {/* Mobile Menu */}
            <div className="dropdown mobile-user-menu">
                <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i className="fa fa-ellipsis-v" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
                    <Link className="dropdown-item" to="/profile">
                        My Profile
                    </Link>
                    <Link className="dropdown-item" to="/settings/change-password">
                        Settings
                    </Link>
                    <Link className="dropdown-item" to="/">
                        Logout
                    </Link>
                </div>
            </div>
            {/* /Mobile Menu */}
        </div>
    );
};

export default Header;

import React, {useEffect, useState} from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../../firebase";
import {Link} from "react-router-dom";

const PayrollVarianceTable = () => {
    const [lineChartData, setLineChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const fetchPayrollData = async () => {
            try {
                const payrollQuerySnapshot = await getDocs(collection(db, 'add-salary'));
                const payrollData = payrollQuerySnapshot.docs.map(doc => doc.data());

                console.log("payrolldata: ", payrollData)
                // Extract year and month from selectedDate
                const selectedYear = selectedDate.getFullYear();
                const selectedMonth = selectedDate.getMonth() + 1; // Note: January is 0 in JavaScript

                const currentMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === selectedYear && parseInt(month) === selectedMonth;
                    }
                    return false; // Exclude data without a defined month
                });

                const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
                const previousYear = selectedMonth === 1 ? selectedYear - 1 : selectedYear;

                const previousMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === previousYear && parseInt(month) === previousMonth;
                    }
                    return false; // Exclude data without a defined month
                });

                console.log("current data: ", currentMonthData);
                console.log("previous data: ", previousMonthData);


                const currentMonthTotal = {
                    paye: currentMonthData.reduce((total, data) => {
                        if (data.payePayable !== undefined) {
                            const value = parseFloat(data.payePayable);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid paye value:", data.payePayable);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing paye value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nssf: currentMonthData.reduce((total, data) => {
                        if (data.nssfContribution !== undefined) {
                            const value = parseFloat(data.nssfContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nssf value:", data.nssfContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nssf value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nhif: currentMonthData.reduce((total, data) => {
                        if (data.nhifContribution !== undefined) {
                            const value = parseFloat(data.nhifContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nhif value:", data.nhifContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nhif value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0)
                };

                console.log("Current Month Total:", currentMonthTotal);

                const previousMonthTotal = {
                    paye: previousMonthData.reduce((total, data) => {
                        if (data.payePayable !== undefined) {
                            const value = parseFloat(data.payePayable);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid paye value:", data.payePayable);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing paye value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nssf: previousMonthData.reduce((total, data) => {
                        if (data.nssfContribution !== undefined) {
                            const value = parseFloat(data.nssfContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nssf value:", data.nssfContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nssf value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nhif: previousMonthData.reduce((total, data) => {
                        if (data.nhifContribution !== undefined) {
                            const value = parseFloat(data.nhifContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nhif value:", data.nhifContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nhif value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0)
                };

                console.log("Previous Month Total:", previousMonthTotal);

                const calculatePercentageVariance = (currentValue, previousValue) => {
                    const variance = ((currentValue - previousValue) / previousValue) * 100;
                    return isNaN(variance) ? 0 : Math.abs(variance);
                };

                const varianceData = [
                    { y: "paye", "Previous Month": previousMonthTotal.paye, "Current Month": currentMonthTotal.paye },
                    { y: "nssf", "Previous Month": previousMonthTotal.nssf, "Current Month": currentMonthTotal.nssf },
                    { y: "nhif", "Previous Month": previousMonthTotal.nhif, "Current Month": currentMonthTotal.nhif }
                ];

                console.log("Variance Data:", varianceData);

                setLineChartData(varianceData);

            } catch (error) {
                console.error("Error fetching payroll data:", error);
            }
        };

        fetchPayrollData();
    }, [selectedDate]);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title">Payroll Variance</h3>
                </div>
                <div className="card-body">
                    <div className='card'>
                        <div className='card-body'>
                            <div id='chart'>
                                <ResponsiveContainer width="100%" height={355}>
                                    <LineChart
                                        data={lineChartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}>
                                        <CartesianGrid/>
                                        <XAxis dataKey="y"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Line
                                            type="monotone"
                                            dataKey="Previous Month"
                                            stroke="#145c94"
                                            fill="#00c5fb"
                                            strokeWidth={3}
                                            dot={{r: 3}}
                                            activeDot={{r: 7}}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="Current Month"
                                            stroke="#00aab9"
                                            fill="#0253cc"
                                            strokeWidth={3}
                                            dot={{r: 3}}
                                            activeDot={{r: 7}}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Link to="/finance/payroll/payroll-dashboard">Go to payroll dashboard</Link>
                </div>
            </div>
        </div>
    )
}

export default PayrollVarianceTable;

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBRVcU3iUF5S6-CRUHKsc5tFCYqNty9RtI",
  authDomain: "rokit-it.firebaseapp.com",
  databaseURL: "https://rokit-it-default-rtdb.firebaseio.com",
  projectId: "rokit-it",
  storageBucket: "rokit-it.appspot.com",
  messagingSenderId: "1057524437588",
  appId: "1:1057524437588:web:dac5c27095964dfe7ad3c1",
  measurementId: "G-00XMFL9XQ2"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const messaging = getMessaging(app);

// getToken(messaging, {vapidKey: "zdhgb7b08EM0nzYGBXEWr_ls8v8uqZFsyvkK-XGh8Wc"});
// const analytics = getAnalytics(app);

// export default {app, db};
export {app, db, messaging};


import React, { useState, useContext, useEffect } from 'react';
import { db } from '../../firebase.js';
import { collection, addDoc, getDocs, getDoc, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, } from 'firebase/storage';
import Header from '../common/Header.js';
import Sidebar from "../common/Sidebar.js";
import Img_01 from '../../assets/img/img-01.jpg';
import {Link} from "react-router-dom";
import { UserContext } from '../../InitialPage/App.js';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

const auth = getAuth();

const CompanyForm = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    const [data, setData] = useState([]);
    const storage = getStorage();
    const [logoFile, setLogoFile] = useState(null);

    const [menu, setMenu] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleLogoFileChange = (e) => {
        const file = e.target.files[0];
        setLogoFile(file);
      };

      const handleDateChange = (date) => {
        setSelectedDate(date);
    };
      const initialFormData = {
        companyName: '',
        tradingName: '',
        phone: '',
        email: '',
        businessRegistrationDate: '',
        taxNumber: '',
        nhifNo: '',
        nssfNo: '',
        physicalAddress: '',
        postCode: '',
        city: '',
        type: "BUSINESS",
        role: "admin",
    };

    const [formData, setFormData] = useState(initialFormData);
    const [formErrors, setFormErrors] = useState({});
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [emailError, setEmailError] = useState('');
    const kraPinRegex = /^[A-Za-z][0-9]{9}[A-Za-z]$/;
    const [kraPinError, setKraPinError] = useState('');
    const existingLegalName = data.some(item => item.companyName === formData.companyName);
    const existingDisplayName = data.some(item => item.tradingName === formData.tradingName);
    const existingCompanyPin = data.some(item => item.taxNumber === formData.taxNumber);
    const existingCompanyEmail = data.some(item => item.email === formData.email);
    const [selectedCountry, setSelectedCountry] = useState('KE'); 


    if (existingLegalName) {
        showToast('Company Legal name already exists.', 'error');
        return;
    }

    if (existingDisplayName) {
        showToast('Company Display/Trading name already exists.', 'error');
        return;
    }

    if (existingCompanyPin) {
        showToast('Company PIN already exists.', 'error');
        return;
    }

    if (existingCompanyEmail) {
        showToast('Email already exists.', 'error');
        return;
    }


    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        // Check if the field is the email field
        if (name === 'email') {
            // If it is the email field, update the state directly without further validation
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            // If it's not the email field, check for invalid characters
            if (invalidPattern.test(value)) {
                // If it contains invalid characters, don't update the state and show an error message
                showToast('Invalid input: Special characters and symbols are not allowed', 'error');
                return;
            }

            // If the value doesn't contain invalid characters, update the state
            setFormData({
                ...formData,
                [name]: name === 'businessRegistrationDate' ? value : value,
            });
        }
    };

    const handlePhoneChange = (value, country) => {
        setFormData({
            ...formData,
            phone: value,
        });
        setSelectedCountry(country.countryCode.toUpperCase()); // Update selected country
    };

    const isValidPhone = (phone) => {
        return isValidPhoneNumber(phone, selectedCountry); // Use selected country for validation
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            // setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

            // Check user authentication first
            const unsubscribe = auth.onAuthStateChanged(user => {
                if (user) {
                    // User is signed in
                    checkUserAuthentication();
                } else {
                    // No user is signed in
                    // setLoading(false);
                    console.error("User not found. Please login again.");
                }
            });

            // Clean up subscription
            return () => unsubscribe();
    }, []);

    const requiredFields =
        [
            'companyName',
            'tradingName',
            'email',
            'taxNumber',
            'phone',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Validate KRA PIN
        if (!kraPinRegex.test(formData.taxNumber)) {
            setKraPinError('PIN must be 11 alphanumeric characters.');
            showToast('Invalid PIN!', 'error');
            return;
        } else {
            setKraPinError('');
        }

        // Validate phone number
        if (formData.phone.length > 15) {
            showToast('Phone number cannot exceed 15 characters!', 'error');
            return;
        }

        // Validate phone number
        if (formData.phone.length < 10) {
            showToast('Phone number cannot be less than 10 characters!', 'error');
            return;
        }

        // Validate email
        if (!emailRegex.test(formData.email)) {
            setEmailError('Email must be valid!');
            showToast('Invalid email!', 'error');
            return;
        } else {
            setEmailError('');
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        let businessRegString = null;
        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            businessRegString = `${year}-${month}-${day}`;
        }
        try {
            const organisationId = Math.floor(Math.random() * 1000000);
            
            // Check if the legal name already exists in the database
            const companySnapshot = await getDocs(query(collection(db, 'add-company'), where('companyName', '==', formData.companyName)));
            if (!companySnapshot.empty) {
                showToast('Company with this legal name already exists!', 'error');
                return;
            }

            const companyDisplaySnapshot = await getDocs(query(collection(db, 'add-company'), where('tradingName', '==', formData.tradingName)));
            if (!companyDisplaySnapshot.empty) {
                showToast('Company with this display name already exists!', 'error');
                return;
            }

            const companyPINSnapshot = await getDocs(query(collection(db, 'add-company'), where('taxNumber', '==', formData.taxNumber)));
            if (!companyPINSnapshot.empty) {
                showToast('Company with this PIN already exists!', 'error');
                return;
            }

            const companyEmailSnapshot = await getDocs(query(collection(db, 'add-company'), where('email', '==', formData.email)));
            if (!companyEmailSnapshot.empty) {
                showToast('Company with this email already exists!', 'error');
                return;
            }

            let logoURL = null;

            // Check if a logo file is selected
            if (logoFile) {
                // Upload logo file to Firebase Storage
                const logoRef = ref(storage, `company-logos/${logoFile.name}`);
                await uploadBytes(logoRef, logoFile);

                // Get the download URL of the uploaded logo
                logoURL = await getDownloadURL(logoRef);
            }

            // Add the logo URL to the form data
            // const updatedFormData = {
            //     ...formData,
            //     logoURL,
            //     businessRegistrationDate: businessRegString,
            //     organisationId
            // };

            let docRef;

            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'add-company'), {
                    ...formData,
                    logoURL,
                    businessRegistrationDate: businessRegString,
                    organisationId
                });

                await addDoc(collection(db, 'users'), {
                    ...formData,
                    logoURL,
                    businessRegistrationDate: businessRegString,
                    organisationId
                })
                .then(async () => {
                    const requestBody = JSON.stringify({
                        email: formData.email,
                        name: formData.companyName,
                        uid: docRef.id
                    });
                    
                    console.log('Request Body:', requestBody);

                    await fetch(`https://send-password-link-api-6w2fko6g2a-ey.a.run.app`,
                    {
                        method: "POST",
                        body: requestBody,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        console.log('Works!')
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                });

            } else {
                docRef = await addDoc(collection(db, 'add-company'), {
                    ...formData,
                    logoURL,
                    businessRegistrationDate: businessRegString,
                    organisationId
                });

                await addDoc(collection(db, 'users'), {
                    ...formData,
                    logoURL,
                    businessRegistrationDate: businessRegString,
                    organisationId
                })
                .then(async () => {
                    const requestBody = JSON.stringify({
                        email: formData.email,
                        name: formData.companyName,
                        uid: docRef.id
                    });

                    await fetch(`https://send-password-link-api-6w2fko6g2a-ey.a.run.app`,
                    {
                        method: "POST",
                        body: requestBody,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        console.log('Works!')
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                });
            }

            // Add the company data to Firestore
            // const docRef = await addDoc(collection(db, 'add-company'), updatedFormData);
            // console.log("Document written with ID: ", docRef.id);

            // // alert('Form submitted successfully!');
            showToast('Company Added Successfully', 'success');

            // Reset the form after successful submission
            setFormData(initialFormData);
            setLogoFile(null);

            // Clear the file input value for the logo
            document.getElementById('logoInput').value = '';
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Company</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/companies/companies-list">Companies</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Company</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="company-card">
                                            <form id="companyForm" onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Company Legal Name <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyName"
                                                                autoComplete="off"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Display Name <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="tradingName"
                                                                autoComplete="off"
                                                                value={formData.tradingName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Email <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Phone No <span className="text-danger">*</span></label>
                                                            <PhoneInput
                                                                country={'ke'}
                                                                value={formData.phone}
                                                                onChange={handlePhoneChange}
                                                                inputClass="form-control"
                                                                containerClass="phone-input-container"
                                                                required
                                                            />
                                                            {formData.phone && !isValidPhone(formData.phone) && (
                                                                <span className="text-danger">Invalid phone number</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Business registration date </label>
                                                                <div className="cal-icon">
                                                                    <DatePicker
                                                                        selected={selectedDate}
                                                                        onChange={handleDateChange}
                                                                        className="form-control datetimepicker"
                                                                        type="date"
                                                                        maxDate={new Date()}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Company KRA PIN <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="taxNumber"
                                                                autoComplete="off"
                                                                value={formData.taxNumber}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <p className="text-primary text-xs">
                                                            Please ensure the KRA PIN format used is alphanumeric, consisting of 1 letter, followed by 9 numbers, and ending with 1 letter.
                                                        </p>
                                                    </div>
                                                    
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>NHIF No</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="nhifNo"
                                                                autoComplete="off"
                                                                value={formData.nhifNo}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>NSSF No</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="nssfNo"
                                                                autoComplete="off"
                                                                value={formData.nssfNo}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Physical Address</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="physicalAddress"
                                                                autoComplete="off"
                                                                value={formData.physicalAddress}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Postal Code</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="postCode"
                                                                autoComplete="off"
                                                                value={formData.postCode}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="city"
                                                                autoComplete="off"
                                                                value={formData.city}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button className="btn btn-primary submit-btn" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <div className="card flex-fill">
                                            <img alt="" src={logoFile ? URL.createObjectURL(logoFile) : Img_01}
                                                 className="card-img-top"/>
                                            <div className="card-body">
                                            <p className="card-text">
                                                Please upload an image of your company logo.
                                                </p>
                                                <input
                                                    className="form-control"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleLogoFileChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    );
};

export default CompanyForm;

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Profile1 from "../../../../assets/img/profiles/avatar-02.jpg";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../../../firebase";

const EmployeesTable = () => {
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editEmployeeId, setEditEmployeeId] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0);
    const [formData, setFormData] = useState({
        employeeName: '',
        phoneNo: '',
        location: '',
        email: '',
        maritalStatus: '',
        qualification: '',
        dependants: '',
        nextOfKin: '',
        membership: '',
    });

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(employee => !employee.deleted);
        } else {
            return filteredData.filter(employee => !employee.deleted);
        }
    };

    const handleStatusChange = async (employeeId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-employee', employeeId), { status: newStatus });

            const updatedData = data.map(employee => {
                if (employee.id === employeeId) {
                    return { ...employee, status: newStatus };
                }
                return employee;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'add-employee');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(employee => !employee.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                // If there is an employee ID for editing, set the form data
                if (editEmployeeId) {
                    const selectedEmployee = dataArray.find(employee => employee.id === editEmployeeId);
                    setFormData(selectedEmployee);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editEmployeeId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Employees</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(employee => (
                                <tr key={employee.id}>
                                    <td>
                                        <h2 className="table-avatar">
                                            <Link to="#"
                                                  className="avatar">
                                                <img alt="" src={employee.logoURL || Profile1}/>
                                            </Link>
                                            <Link
                                                to="#">{employee.employeeName}</Link>
                                        </h2>
                                    </td>
                                    <td>{employee.email}</td>
                                    <td>{employee.role}</td>
                                    <td>
                                        <div className="dropdown action-label mt-2">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${employee.status === 'active' ? 'success' : 'danger'}`}></i>
                                                {employee.status === 'active' ? 'Active' : 'Inactive'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(employee.id, 'active')}>
                                                    <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                </a>
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(employee.id, 'inactive')}>
                                                    <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/employees/employees-list">View all employees</Link>
                </div>
            </div>
        </div>
    )
}

export default EmployeesTable;

import React, {useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {Link} from "react-router-dom";

const CalendarTable = () => {
    const [weekendsVisible] = useState(true);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Calendar</h3>
                </div>
                <div className="card-body mt-2">
                     <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={weekendsVisible}
                    />
                </div>
                 {/* <div className="card-footer">
                     <Link to="#">View full calendar</Link>
                 </div> */}
            </div>
        </div>
    )
}

export default CalendarTable;

import React, { useContext, useState, useEffect } from "react";
import { db } from '../../../firebase.js';
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where } from 'firebase/firestore';
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import {Link} from "react-router-dom";
import { UserContext } from "../../../InitialPage/App";
import { Applogo } from "../../../Entryfile/imagepath";
import { toast, ToastContainer, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';
const auth = getAuth();

const HodLeaveReport = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState(false);
    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];
            querySnapshot.forEach((doc) => {
                const departmentData = doc.data();
                // Check if the employee is active and not deleted
                if (!departmentData.deleted && departmentData.organisationId === userData[0]?.organisationId) {
                    departmentList.push({ id: doc.id, ...departmentData });
                }
            });
            setDepartments(departmentList);
        };
        fetchDepartments();
    }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const issueLeaveRef = collection(db, 'issue-leave');
                // const leaveCategoriesRef = collection(db, 'leave-categories');

                // const issueLeaveSnapshot = await getDocs(issueLeaveRef);
                // const leaveCategoriesSnapshot = await getDocs(leaveCategoriesRef);
                const issueLeaveRef = collection(db, 'issue-leave');

                let issueLeaveSnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    issueLeaveSnapshot = await getDocs(query(issueLeaveRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    issueLeaveSnapshot = await getDocs(issueLeaveRef);
                }

                const leaveCategoriesRef     = collection(db, 'leave-categories');

                let leaveCategoriesSnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    leaveCategoriesSnapshot = await getDocs(query(leaveCategoriesRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    leaveCategoriesSnapshot = await getDocs(leaveCategoriesRef);
                }

                const issueLeaveDataArray = issueLeaveSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);

                let filteredLeaves = issueLeaveDataArray;

                if (userData && userData[0]?.role === 'hod') {
                    // Get departments where the logged-in user is the department head
                    const userDepartments = departments.filter(department => department.departmentHead === userData[0]?.employeeName);

                    // Get a list of employee names in those departments
                    const employeeNames = userDepartments.flatMap(department => department.employeeName);

                    // Filter leaves based on those employees
                    filteredLeaves = issueLeaveDataArray.filter(leave => employeeNames.includes(leave.employeeName));
                }

                const leaveCategoriesData = leaveCategoriesSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);

                // Combine data by mapping over issue-leave data
                const combinedData = filteredLeaves.map(issueLeaveItem => {
                    const matchedCategory = leaveCategoriesData.find(category =>
                        category.leaveCategory === issueLeaveItem.leaveCategory
                    );
                    // If matching category found, add maxNoOfDays to issueLeaveItem
                    if (matchedCategory) {
                        return {
                            ...issueLeaveItem,
                            maxNoOfDays: matchedCategory.maxNoOfDays
                        };
                    }
                    return issueLeaveItem;
                });

                setData(combinedData);
                setEntriesCount(combinedData.length);

                setLoading(false);

                // console.log('Fetched data:', combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading]);

    // Function to handle PDF button click
    const handlePDFExport = () => {
        const element = document.getElementById('leave-report'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .save('leave-report.pdf');
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('leave-report').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const handleCSVExport = () => {
        const csvContent = "data:text/csv;charset=utf-8,"
            + "Employee Name,Leave Category,Entitled Leave Days,Leave Days Taken,Remaining Leave Days\n"
            + data.map(item => `${item.employeeName || "All"},${item.leaveCategory},${item.maxNoOfDays},${item.noOfDays},${item.remainingLeaves}`).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "leave-report.csv");
        document.body.appendChild(link);
        link.click();
    };

    const handleEmailSend = () => { //(recipientEmail)
        const leaveReportContainer = document.getElementById('leave-report');
        
        if (!leaveReportContainer) {
            console.error('Leave report container not found.');
            showToast('Error: Leave report container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(leaveReportContainer)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "Leave report for " + format(new Date(), 'MMMM yyyy'),
                        Body: "Please find attached the Leave report for this month.",
                        Attachments: [
                            {
                                name: "leave-report.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('Leave report sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending Leave report via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Leaves</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hr/leave-management/hr-leave-reports/leave-entitlement">Leave
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Leave Report</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                            <button className="btn btn-white" onClick={handleEmailSend}><i className="fa fa-mail-bulk fa-lg"/> Email</button>
                                                <button className="btn btn-white" onClick={handleCSVExport}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                <button className="btn btn-white" onClick={handlePDFExport}><i className="fa fa-file-pdf fa-lg"/> PDF</button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" id="leave-report">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Leave Report Card</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        <img src={Applogo} className="inv-logo" alt=""/>
                                                        <p>Employer's Name: <strong>******</strong></p>
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">Leave Report</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Employee Name</th>
                                                                    <th>Leave Category</th>
                                                                    <th>Entitled Leave Days</th>
                                                                    <th>Leave Days Taken</th>
                                                                    <th>Remaining Leave Days</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data.map(item => (
                                                                <tr key={item.id}>
                                                                    <td>{item.employeeName || "All"}</td>
                                                                    <td>{item.leaveCategory}</td>
                                                                    <td>{item.maxNoOfDays}</td>
                                                                    <td>{item.noOfDays}</td>
                                                                    <td>{item.remainingLeaves}</td>
                                                                </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default HodLeaveReport;

import React, { useState, useEffect, useContext } from "react";
import { db } from '../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Header from '../common/Header.js';
import Sidebar from "../common/Sidebar.js";
import CompanyForm from './companyForm.js';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from "../../InitialPage/App.js";
import {Avatar_02} from "../../Entryfile/imagepath.js";
import Profile1 from "../../assets/img/profiles/avatar-02.jpg";

const auth = getAuth();

const CompanyList = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const navigate = useNavigate();

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const storage = getStorage();
    const [logoFile, setLogoFile] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleLogoFileChange = (e) => {
        const file = e.target.files[0];
        setLogoFile(file);
      };

    const [formData, setFormData] = useState({
        displayName: '',
        companyName: '',
        tradingName: '',
        companyNumber: '',
        businessType: '',
        phone:'',
        businessRegistrationDate: '',
        taxNumber: '',
        email: '',
        physicalAddress: '',
        postCode: '',
        address: {
            city: '',
            country: '',
        },
    })

    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editCompanyId, setEditCompanyId] = useState(null);

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item &&
            (item.displayName && item.displayName.toLowerCase().includes(input.toLowerCase())) ||
            (item.email && item.email.toLowerCase().includes(input.toLowerCase())) ||
            (item.city && item.city.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (companyId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-company', companyId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === companyId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);

            const updatedFilteredData = filteredData.map(item => {
                if (item.id === companyId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setFilteredData(updatedFilteredData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleDeleteCompany = async () => {
        try {
            await updateDoc(doc(db, 'add-company', editCompanyId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editCompanyId ? { ...item, deleted: true } : item
            ));
            
            setFilteredData(filteredData => filteredData.map(item =>
                item.id === editCompanyId ? { ...item, deleted: true } : item
            ));
        } catch (error) {
            console.error('Error deleting company:', error);
        }
    };


    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            await updateDoc(doc(db, 'add-company', editCompanyId), formData, logoFile);

            setFormData({
                legalName: '',
                displayName: '',
                email: '',
                pinNo: '',
                nhifNo: '',
                nssfNo: '',
                physicalAddress: '',
                postCode: '',
                city: '',
            });
            setLogoFile(null);
            setEditCompanyId(null)
        }catch(error){
            console.error("error updating company", error)
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                const colRef = collection(db, 'add-company');
                    
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                // const companyQuerySnapshot = await getDocs(query(collection(db, 'add-company'), where('organisationId', '==', userData.organisationId)));
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editCompanyId) {
                    const selectedCompany = dataArray.find(item => item.id === editCompanyId);
                    setFormData(selectedCompany);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, entriesPerPage, editCompanyId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Companies</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/companies/companies-list">Companies</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Company List</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Display Name</th>
                                                        <th>Legal Name</th>
                                                        {/* <th>Business Type</th>  */}
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>City</th>
                                                        <th>Status</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id} >
                                                            <td>
                                                                <h2 className="table-avatar">
                                                                    <Link to={`/companies/company-profile/${item.id}`}  className="avatar">
                                                                        <img alt="" src={item.logoURL || Profile1}/>
                                                                    </Link>
                                                                    <Link to={`/companies/company-profile/${item.id}`} >{item.displayName || item.tradingName}</Link>
                                                                </h2>
                                                            </td>
                                                            <td>{item.legalName || item.companyName}</td>
                                                            {/* <td>{item.businessType}</td> */}
                                                            <td>{item.email}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.city || item.address?.city}</td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : 'danger'}`}></i>
                                                                        {item.status === 'active' ? 'Active' : 'Inactive'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'active')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <Link
                                                                        to="#"
                                                                        className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="material-icons">more_vert</i>
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#edit_company"
                                                                            onClick={() => setEditCompanyId(item.id)}>
                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                        </Link>

                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#delete_company"
                                                                            onClick={() => setEditCompanyId(item.id)}
                                                                        >
                                                                            <i className="fa fa-trash-can m-r-5"/> Delete
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                id="edit_company"
                className="modal custom-modal fade"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Company</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="profile-img-wrap edit-img">
                                            <img
                                                className="inline-block"
                                                src={Avatar_02}
                                                alt="company"
                                            />
                                            <div className="fileupload btn">
                                                <span className="btn-text">edit</span>
                                                <input className="upload" type="file" accept=".png"
                                                       onChange={handleLogoFileChange}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-block">
                                                    <label>Display Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="displayName"
                                                        autoComplete="off"
                                                        value={formData.displayName || formData.tradingName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block">
                                                    <label>Email</label>
                                                    <div>
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            name="email"
                                                            autoComplete="off"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="input-block">
                                                    <label>Phone No<span
                                                    className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="phone"
                                                    autoComplete="off"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input-block">
                                                    <label>Physical Address/Country</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="physicalAddress"
                                                        autoComplete="off"
                                                        value={formData.physicalAddress || formData.address?.country}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input-block">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={formData.city || formData.address?.city}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal"
                                            type="submit">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal custom-modal fade"
                id="delete_company"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Company</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <Link to="" className="btn btn-primary continue-btn" onClick={handleDeleteCompany} data-bs-dismiss="modal">
                                            Delete
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <Link
                                            to=""
                                            data-bs-dismiss="modal"
                                            className="btn btn-primary cancel-btn">
                                            Cancel
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanyList;

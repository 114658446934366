import React, {useState, useContext} from "react";
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link} from "react-router-dom";
import EmployeeShiftSchedulingForm from "./employeeShiftSchedulingForm.js";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import dayjs from "dayjs";
import { UserContext } from "../../InitialPage/App.js";

const EmployeeShiftList = () => {
    const { userRole } = useContext(UserContext);

    dayjs.extend(customParseFormat);
    const onChange = (time, timeString) => {
        console.log(time, timeString);
    };

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="page-title">Shift List</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/employees/employees-list">Employees</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Shift List</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn m-r-5"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_shift">
                                                Add Shifts
                                            </Link>
                                            <Link
                                                to="#"
                                                className="btn add-btn m-r-5"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_schedule">
                                                {" "}
                                                Assign Shifts
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Shift Name</th>
                                                        <th>Min Start Time</th>
                                                        <th>Start Time</th>
                                                        <th>Max Start Time</th>
                                                        <th>Min End Time</th>
                                                        <th>End Time</th>
                                                        <th>Max End Time</th>
                                                        <th>Break Time</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>10'0 clock Shift</td>
                                                        <td>09:00:00 am</td>
                                                        <td>10:00:00 am</td>
                                                        <td>10:30:00 am</td>
                                                        <td>06:00:00 pm</td>
                                                        <td>07:00:00 pm</td>
                                                        <td>07:00:00 pm</td>
                                                        <td>30 mins</td>
                                                        <td>
                                                            <div
                                                                className="ant-table-row ant-table-row-level-0 text-end">
                                                                <Link
                                                                    className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                    to="#"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="far fa-dot-circle text-success"/> Active
                                                                </Link>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <Link to="#" className="dropdown-item">
                                                                        <i className="far fa-dot-circle text-success me-1"/> Activate
                                                                    </Link>
                                                                    <Link to="#" className="dropdown-item">
                                                                        <i className="far fa-dot-circle text-danger me-1"/> Deactivate
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown dropdown-action">
                                                                <Link
                                                                    to="#"
                                                                    className="action-icon dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="material-icons">more_vert</i>
                                                                </Link>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#edit_shift">
                                                                        <i className="fa fa-pencil m-r-5"/> Edit
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#delete_employee">
                                                                        <i className="fa fa-trash m-r-5"/> Delete
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <EmployeeShiftSchedulingForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeShiftList;
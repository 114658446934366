import React, {useContext, useEffect, useState} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import {CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LineChart} from "recharts";
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "../../../../InitialPage/App.js";
import Select from "react-select";
import {collection, getDocs, where, query} from "firebase/firestore";
import {db} from "../../../../firebase.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const PayrollDashboard = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [menu, setMenu] = useState(false);
    const [generatedP9FormUrls, setGeneratedP9FormUrls] = useState([]);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const { userRole } = useContext(UserContext);

    const [employees, setEmployees] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);

    const [formData, setFormData] = useState({
        employeeName: [],
        year: null,
        report: '',
        companyName: ''
    });

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [lineChartData, setLineChartData] = useState([]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        filterEmployees(date);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchPayrollData = async () => {
            try {
                // const payrollQuerySnapshot = await getDocs(collection(db, 'add-salary'));
                const colRef = collection(db, 'add-salary');

                let payrollQuerySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    payrollQuerySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    payrollQuerySnapshot = await getDocs(colRef);
                }

                const payrollData = payrollQuerySnapshot.docs.map(doc => doc.data());

                // console.log("payrolldata: ", payrollData)
                // Extract year and month from selectedDate
                const selectedYear = selectedDate.getFullYear();
                const selectedMonth = selectedDate.getMonth() + 1; // Note: January is 0 in JavaScript

                const currentMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === selectedYear && parseInt(month) === selectedMonth;
                    }
                    return false; // Exclude data without a defined month
                });

                const previousMonth = selectedMonth === 1 ? 12 : selectedMonth - 1;
                const previousYear = selectedMonth === 1 ? selectedYear - 1 : selectedYear;

                const previousMonthData = payrollData.filter(data => {
                    if (data.month) {
                        const [year, month] = data.month.split('-');
                        return parseInt(year) === previousYear && parseInt(month) === previousMonth;
                    }
                    return false; // Exclude data without a defined month
                });

                console.log("current data: ", currentMonthData);
                console.log("previous data: ", previousMonthData);


                const currentMonthTotal = {
                    paye: currentMonthData.reduce((total, data) => {
                        if (data.payePayable !== undefined) {
                            const value = parseFloat(data.payePayable);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid paye value:", data.payePayable);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing paye value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nssf: currentMonthData.reduce((total, data) => {
                        if (data.nssfContribution !== undefined) {
                            const value = parseFloat(data.nssfContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nssf value:", data.nssfContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nssf value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nhif: currentMonthData.reduce((total, data) => {
                        if (data.nhifContribution !== undefined) {
                            const value = parseFloat(data.nhifContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nhif value:", data.nhifContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nhif value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0)
                };

                console.log("Current Month Total:", currentMonthTotal);

                const previousMonthTotal = {
                    paye: previousMonthData.reduce((total, data) => {
                        if (data.payePayable !== undefined) {
                            const value = parseFloat(data.payePayable);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid paye value:", data.payePayable);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing paye value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nssf: previousMonthData.reduce((total, data) => {
                        if (data.nssfContribution !== undefined) {
                            const value = parseFloat(data.nssfContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nssf value:", data.nssfContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nssf value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0),
                    nhif: previousMonthData.reduce((total, data) => {
                        if (data.nhifContribution !== undefined) {
                            const value = parseFloat(data.nhifContribution);
                            if (!isNaN(value)) {
                                return total + value;
                            } else {
                                console.error("Invalid nhif value:", data.nhifContribution);
                                return total; // Exclude invalid values from the total
                            }
                        } else {
                            console.error("Missing nhif value in data:", data);
                            return total; // Exclude missing values from the total
                        }
                    }, 0)
                };

                console.log("Previous Month Total:", previousMonthTotal);

                const calculatePercentageVariance = (currentValue, previousValue) => {
                    const variance = ((currentValue - previousValue) / previousValue) * 100;
                    return isNaN(variance) ? 0 : Math.abs(variance);
                };

                const varianceData = [
                    { y: "paye", "Previous Month": previousMonthTotal.paye, "Current Month": currentMonthTotal.paye },
                    { y: "nssf", "Previous Month": previousMonthTotal.nssf, "Current Month": currentMonthTotal.nssf },
                    { y: "nhif", "Previous Month": previousMonthTotal.nhif, "Current Month": currentMonthTotal.nhif }
                ];

                console.log("Variance Data:", varianceData);

                setLineChartData(varianceData);

            } catch (error) {
                console.error("Error fetching payroll data:", error);
            }
        };

        fetchPayrollData();
    }, [userData, loading, selectedDate]);

    const navigate = useNavigate();

    const fetchAndFormatYears = async () => {
        try {
            // const payrollQuerySnapshot = await getDocs(collection(db, 'add-salary'));
            const colRef = collection(db, 'add-salary');

            let payrollQuerySnapshot;

            if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                payrollQuerySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
            } else {
                // If user is sys-admin, fetch all companies
                payrollQuerySnapshot = await getDocs(colRef);
            }

            const payrollData = payrollQuerySnapshot.docs.map(doc => doc.data());

            // Extract unique years from payrollData
            const uniqueYears = [...new Set(payrollData.map(data => new Date(data.month).getFullYear()))];

            // Filter out any invalid or unexpected values
            const filteredYears = uniqueYears.filter(year => !isNaN(year) && year >= 2000);

            // Format the filtered years
            const formattedYears = filteredYears.map(year => ({
                value: year,
                label: format(new Date(`${year}-01-01`), 'yyyy')
            }));

            return formattedYears;
        } catch (error) {
            console.error("Error fetching and formatting years:", error);
            return [];
        }
    };


    // Inside your component
    const [formattedYears, setFormattedYears] = useState([]);

    useEffect(() => {
        const fetchYears = async () => {
            const years = await fetchAndFormatYears();
            setFormattedYears(years);
        };

        fetchYears();
    }, [userData]);

    const handleAnnualSubmit = async (e) => {
        e.preventDefault();
    
        if (formData.employeeName.length === 0) {
            console.error("No employees selected.");
            return;
        }
    
        if (!formData.year) {
            console.error("No year selected.");
            return;
        }
    
        const urls = [];
    
        for (const selectedEmployeeName of formData.employeeName) {
            try {
                const selectedEmployeeIds = formData.employeeName.map(selectedEmployeeName => {
                    const selectedEmployee = employees.find(employee => employee.employeeName === selectedEmployeeName);
                    if (selectedEmployee) {
                        return selectedEmployee.id;
                    } else {
                        console.error(`Employee "${selectedEmployeeName}" not found.`);
                        return null;
                    }
                }).filter(Boolean).join(',');
    
                const p9FormUrl = `/finance/payroll/generated-p9form/${selectedEmployeeIds}?year=${formData.year.value}`;
                await navigate(p9FormUrl);
    
                urls.push({ employeeName: selectedEmployeeName, url: p9FormUrl });
            } catch (error) {
                console.error(`Error generating P9 form for ${selectedEmployeeName}:`, error);
            }
        }
    
        setGeneratedP9FormUrls(urls);
    };          

    const fetchOrganisationId = async (companyName) => {
        const colRef = collection(db, 'add-company');
        const q = query(colRef, where('companyName', '==', companyName));
        const querySnapshot = await getDocs(q);
    
        if (querySnapshot.empty) {
            console.error(`Company "${companyName}" not found.`);
            return null;
        }
    
        const companyData = querySnapshot.docs[0].data();
        return companyData.organisationId;
    };
    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        if (!selectedDate) {
            console.error("No month and year selected.");
            return;
        }
    
        // Extract form data
        const formData = new FormData(e.target);
        const selectedMonthYear = selectedDate.toISOString().substr(0, 7);
        const selectedReport = formData.get('report');
        const selectedCompanyName = formData.get('companyName');

        // Fetch organisationId based on selectedCompanyName
        const selectedCompanyId = await fetchOrganisationId(selectedCompanyName);
    
        const selectedEmployeeIds = formData.getAll('employeeName').map(selectedEmployeeName => {
            const selectedEmployee = employees.find(employee => employee.employeeName === selectedEmployeeName);
            if (selectedEmployee) {
                return selectedEmployee.id;
            } else {
                console.error(`Employee "${selectedEmployeeName}" not found.`);
                return null;
            }
        }).filter(Boolean).join(',');
    
        // Construct the path based on selected options
        let path = '';
        if (selectedReport === 'payslip') {
            if (userData && userData[0]?.role !== 'sys-admin'){
                path = `/finance/payroll/payslip/${selectedEmployeeIds}?monthYear=${selectedMonthYear}&companyId=${userData[0]?.organisationId}`;
            } else{
                path = `/finance/payroll/payslip/${selectedEmployeeIds}?monthYear=${selectedMonthYear}&companyId=${selectedCompanyId}`;
            }
            
        } else if (selectedReport === 'muster-roll') {
            if (userData && userData[0]?.role !== 'sys-admin'){
                path = `/finance/payroll/muster-roll?monthYear=${selectedMonthYear}&companyId=${userData[0]?.organisationId}`;
            } else{
                path = `/finance/payroll/muster-roll?monthYear=${selectedMonthYear}&companyId=${selectedCompanyId}`;
            }
        }
    
        // Navigate to the constructed path
        navigate(path);
    };
    
    const filterEmployees = async (selectedDate) => {
        const selectedMonthYear = selectedDate.toISOString().substr(0, 7);
    
        try {
            const colRef = collection(db, 'add-salary');
            const q = query(colRef, where('month', '==', selectedMonthYear));
            const querySnapshot = await getDocs(q);
    
            const selectedEmployeeDataList = [];
            querySnapshot.forEach((doc) => {
                const employeeSalaryData = doc.data();
                selectedEmployeeDataList.push(employeeSalaryData);
            });
    
            const employeeIdsWithSelectedDate = selectedEmployeeDataList.map(employee => employee.employeeId);
    
            const updatedEmployees = employees.filter(employee => employeeIdsWithSelectedDate.includes(employee.id));
            setEmployees(updatedEmployees);
    
        } catch (error) {
            console.error("Error fetching employee data for selected date:", error);
        }
    };    


    const payrollSummarySubmit = async(e) => {
        e.preventDefault();

        if (selectedDate) {
            const selectedYear = selectedDate.getFullYear();
            const selectedMonth = selectedDate.getMonth() + 1;
            const selectedCompanyName = formData.companyName;
            const selectedCompanyId = await fetchOrganisationId(selectedCompanyName);

            if (userData && userData[0]?.role !== 'sys-admin'){
                navigate(`/finance/payroll/generated-payrollsummary?month=${selectedMonth}&year=${selectedYear}&companyId=${userData[0]?.organisationId}`);
            } else{
                navigate(`/finance/payroll/generated-payrollsummary?month=${selectedMonth}&year=${selectedYear}&companyId=${selectedCompanyId}`);
            }
            // Pass selected month and year as query parameters
            // navigate(`/finance/payroll/generated-payrollsummary?month=${selectedMonth}&year=${selectedYear}`);
        }
    };

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Update the state for other fields
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const colRef = collection(db, 'add-salary');
                const querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));

                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'approved' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-salary'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (
                        employeeData.status === 'approved' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();
    }, [userData, selectedCompany]);
    
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Payroll Dashboard</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Payroll Dashboard</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6 text-center'>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <h3 className="card-title">Payroll Variance</h3>
                                                        <div id='chart'>
                                                            <ResponsiveContainer width="100%" height={355}>
                                                                <LineChart
                                                                    data={lineChartData}
                                                                    margin={{top: 5, right: 5, left: 5, bottom: 5}}>
                                                                    <CartesianGrid/>
                                                                    <XAxis dataKey="y"/>
                                                                    <YAxis/>
                                                                    <Tooltip/>
                                                                    <Legend/>
                                                                    <Line
                                                                        type="monotone"
                                                                        dataKey="Previous Month"
                                                                        stroke="#145c94"
                                                                        fill="#00c5fb"
                                                                        strokeWidth={3}
                                                                        dot={{r: 3}}
                                                                        activeDot={{r: 7}}
                                                                    />
                                                                    <Line
                                                                        type="monotone"
                                                                        dataKey="Current Month"
                                                                        stroke="#00aab9"
                                                                        fill="#0253cc"
                                                                        strokeWidth={3}
                                                                        dot={{r: 3}}
                                                                        activeDot={{r: 7}}
                                                                    />
                                                                </LineChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="card pricing-box">
                                                    <div className="card-body d-flex flex-column">
                                                        <div className="mb-4 text-center">
                                                            <h3>Reports List</h3>
                                                        </div>
                                                        <Link
                                                            to="#"
                                                            className="btn btn-primary btn-secondary mt-auto mb-4"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#annual_report">
                                                            Annual
                                                        </Link>
                                                        <Link
                                                            to="#"
                                                            className="btn btn-primary btn-secondary mt-auto mb-2"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#monthly_report">
                                                            Monthly
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="card pricing-box">
                                                    <div className="card-body d-flex flex-column">
                                                        <div className="mb-4 text-center">
                                                            <h3>Quick Reports</h3>
                                                        </div>
                                                        {userData[0]?.role !== 'sys-admin' && (
                                                            <Link
                                                                to="/finance/payroll/muster-roll"
                                                                className="btn btn-custom6  mt-auto mb-4"
                                                                >
                                                                Muster Roll
                                                            </Link>
                                                        )}
                                                        <Link
                                                            to="#"
                                                            className="btn btn-custom6 mt-auto mb-2"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#payroll_summary">
                                                            Payroll Summary
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Annual Report Modal */}
                            <div
                                id="annual_report"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Annual Report</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <form onSubmit={handleAnnualSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Year <span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                 options={formattedYears}
                                                                 isMulti={false}
                                                                 onChange={(selectedOption) => {
                                                                     console.log("Selected Year:", selectedOption);
                                                                     setFormData({ ...formData, year: selectedOption });
                                                                 }}
                                                                 placeholder="Select Year"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Report <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="year"
                                                            >
                                                                <option value>P9 Form</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                    {userData[0]?.role === 'sys-admin' && (  
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Organisation</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="companyName"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select an active organisation</option>
                                                                {companies.map(company => (
                                                                    <option>
                                                                        {company.companyName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Select Employees<span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                value={formData.employeeName.map(selected => ({
                                                                    value: selected,
                                                                    label: selected
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setFormData({
                                                                        ...formData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Annual Report Modal */}


                            {/* Monthly Report Modal */}
                            <div
                                id="monthly_report"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Monthly Report</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <form onSubmit={handleFormSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Month & Year <span
                                                                className="text-danger">*</span></label>
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                showMonthYearPicker
                                                                dateFormat="yyyy-MM"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Report <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="report"
                                                                onChange={(e) => setFormData({ ...formData, report: e.target.value })}
                                                            >
                                                                <option value>Select Report</option>
                                                                <option value='payslip'>Payslip</option>
                                                                <option value='muster-roll'>Muster Roll</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                            {userData[0]?.role === 'sys-admin' && (
                                                                <div className="input-block">
                                                                    <label className="col-form-label">Select Organisation</label>
                                                                    <select
                                                                        className="form-select form-control"
                                                                        name="companyName"
                                                                        value={formData.companyName}
                                                                        onChange={handleInputChange}
                                                                        required
                                                                    >
                                                                        <option value="">Select an active organisation</option>
                                                                        {companies.map(company => (
                                                                            <option>
                                                                                {company.companyName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                    </div>
                                                    {formData.report === 'payslip' && (
                                                     <>
                                                        <div className="col-sm-12">
                                                                <div className="input-block">
                                                                    <label>Select Employees<span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="employeeName"
                                                                        options={employees.map(employee => ({
                                                                            value: employee.employeeName,
                                                                            label: employee.employeeName
                                                                        }))}
                                                                        value={formData.employeeName.map(selected => ({
                                                                            value: selected,
                                                                            label: selected
                                                                        }))}
                                                                        onChange={(selectedOptions) => {
                                                                            const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                            setFormData({
                                                                                ...formData,
                                                                                employeeName: selectedValues
                                                                            });
                                                                        } }
                                                                        required />
                                                                </div>
                                                            </div>
                                                            </>
                                                )}
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Monthly Report Modal */}

                            {/* Payroll Summary Modal */}
                            <div
                                id="payroll_summary"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Payroll Summary</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <form onSubmit={(e) => payrollSummarySubmit(e, selectedDate)}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Month & Year <span
                                                                className="text-danger">*</span></label>
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                showMonthYearPicker
                                                                dateFormat="yyyy-MM"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    {userData[0]?.role === 'sys-admin' && (  
                                                        <div className="input-block">
                                                            <label className="col-form-label">Select Organisation</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="companyName"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select an active organisation</option>
                                                                {companies.map(company => (
                                                                    <option>
                                                                        {company.companyName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Payroll Summary Modal */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Render generated P9 form URLs */}
            <div>
                {generatedP9FormUrls.length > 0 && (
                    <div>
                        <h2>Generated P9 Form URLs</h2>
                        {generatedP9FormUrls.map((item, index) => (
                            <div key={index}>
                                <p>{`Generated P9 form URL for ${item.employeeName}:`}</p>
                                <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
)
}

export default PayrollDashboard;

import React, { useContext, useState, useEffect } from "react";
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import { Link } from "react-router-dom";
import { UserContext } from "../../../InitialPage/App.js";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { getAuth } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase.js";
import {
  banks,
  getCustomerWallets,
  getOrganisationWallets,
  getWalletTransactions,
  initiateStkPush,
  initiateWithdrawal,
} from "../../../lib/astra.js";

const auth = getAuth();

const Wallet = () => {
  const { userRole } = useContext(UserContext);
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [wallets, setWallets] = useState([]);
  const [walletsTransactions, setWalletsTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    walletId: "",
    phone: "",
    amount: 0,
    accountName: "",
    accountNumber: "",
    bankCode: "",
  });

  // format date time in East African Time
  function formatDateTimeInEAT(dateString) {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Africa/Nairobi", // East African Time
    };

    return date.toLocaleString("en-US", options);
  }

  // get wallet account number using wallet id
  const getWalletAccountNumber = (walletId) => {
    const wallet = wallets.find((wallet) => wallet.walletId === walletId);
    return wallet.accountNumber;
  };

  // fetch user's wallets
  const fetchUsersWallets = async () => {
    const queryUsersSnapshot = await getDocs(collection(db, "users"));
    const user = queryUsersSnapshot.docs.find(
      (doc) => doc.data().email === auth.currentUser.email
    );
    if (!user) {
      toast.error("User not found. Please login again.");
      return;
    }

    const userData = user.data();
    if (userData.customerId) {
      try {
        await getCustomerWallets(userData.customerId)
          .then((walletsResponse) => {
            if (walletsResponse.length === 0) {
              toast.error("No wallets found. Please create a wallet first on registration.");
              return;
            }
            toast.success("Wallets fetched successfully");
            toast.success("Fetching wallet transactions...");
            walletsResponse.forEach(async (wallet) => {
              await getWalletTransactions(wallet.walletId)
                .then((transactions) => {
                  setWalletsTransactions((prevTransactions) => {
                    return [...prevTransactions, ...transactions];
                  });
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            });
            toast.success("Wallet transactions fetched successfully");
            setLoading(false);
            setWallets(walletsResponse);
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.message);
          });
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else if (userData.organisationId) {
      try {
        await getOrganisationWallets(userData.organisationId)
          .then((walletsResponse) => {
            if (walletsResponse.length === 0) {
              toast.error("No wallets found for the organization.");
              return;
            }
            toast.success("Wallets fetched successfully");
            toast.success("Fetching wallet transactions...");
            walletsResponse.forEach(async (wallet) => {
              await getWalletTransactions(wallet.walletId)
                .then((transactions) => {
                  setWalletsTransactions((prevTransactions) => {
                    return [...prevTransactions, ...transactions];
                  });
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            });
            toast.success("Wallet transactions fetched successfully");
            setLoading(false);
            setWallets(walletsResponse);
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.message);
          });
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      toast.error("User not found. Please register an account on Astra first on the registration page.");
    }
  };
  useEffect(() => {
    fetchUsersWallets();
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <div className="app-container">
          <Header onMenuClick={() => toggleMobileMenu()} />
          <div className="main-content">
            <Sidebar />
            <div className="page-wrapper">
              <div className="content container-fluid">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Wallet</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="">Payroll</Link>
                        </li>
                        <li className="breadcrumb-item active">Wallet</li>
                      </ul>
                    </div>
                    {!loading && wallets.length > 0 && (
                      <div className="col-auto float-end ms-auto">
                        <button
                          className="btn add-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          <i className="fa fa-plus" /> Deposit money
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {!loading && wallets.length > 0 && (
                  <>
                    <div className="row">
                      {wallets.length > 0 &&
                        wallets.map((wallet, i) => (
                          <>
                            <div className="col-md-4">
                              <div className="card">
                                <div className="card-body">
                                  <h3 className="card-title">Wallet {i + 1}</h3>
                                  <div className="wallet-icon justify-content-center">
                                    <span className="wallet-link">
                                      <i className="la la-wallet" />
                                    </span>
                                  </div>
                                  <p id="wallet_heading" className="mt-5">
                                    {wallet.name} - {wallet.accountNumber}{" "}
                                    <sup>{wallet.friendlyId}</sup>
                                  </p>
                                  <h4 className="text-muted">
                                    Current balance - {wallet.currency}{" "}
                                    {wallet.currentBalance}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>

                    <div className="row mt-4">
                      <h4>What would you like to do today?</h4>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div
                          className="col-md-3"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#sendToMobile"
                        >
                          <div className="wallet-icon">
                            <span className="wallet-link">
                              <i className="la la-wallet" />
                            </span>
                          </div>
                          <p className="mt-2">Send To Mobile</p>
                        </div>
                        <div
                          className="col-md-3"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          <div className="wallet-icon">
                            <span className="wallet-link">
                              <i className="la la-mobile" />
                            </span>
                          </div>
                          <p className="mt-2">Mpesa to Bank</p>
                        </div>
                        <div
                          className="col-md-3"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#mpesaToBank"
                        >
                          <div className="wallet-icon">
                            <span className="wallet-link">
                              <i className="la la-credit-card" />
                            </span>
                          </div>
                          <p className="mt-2">PesaLink</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-12">
                        <h4>Recent Transactions</h4>
                        <div className="table-responsive">
                          <table className="table table-striped custom-table datatable">
                            <thead>
                              <tr>
                                <th>Transaction Id</th>
                                <th>Type</th>
                                <th>Wallet</th>
                                <th>Amount</th>
                                <th>Balance</th>
                                <th>Description</th>
                                {/* <th>Status</th> */}
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {walletsTransactions.map((transaction, i) => (
                                <tr key={i}>
                                  <td>{transaction.transactionId}</td>
                                  <td>
                                    {transaction.type ===
                                    "tfr.debit.fee.withdrawal.ke_dtb_mpesa"
                                      ? "Mpesa Withdrawal Fee"
                                      : ""}
                                    {transaction.type ===
                                    "tfr.debit.withdrawal.ke_dtb_mpesa"
                                      ? "Mpesa Withdrawal"
                                      : ""}
                                    {transaction.type ===
                                    "tfr.debit.fee.withdrawal.ke_dtb_pesalink"
                                      ? "Pesalink Withdrawal Fee"
                                      : ""}
                                    {transaction.type ===
                                    "tfr.debit.withdrawal.ke_dtb_pesalink"
                                      ? "Pesalink Withdrawal"
                                      : ""}
                                    {transaction.type ===
                                    "tfr.credit.eft.server"
                                      ? "Credit"
                                      : ""}
                                  </td>
                                  <td>
                                    {getWalletAccountNumber(
                                      transaction.walletId
                                    )}
                                  </td>
                                  <td>
                                    {transaction.currency} {transaction.amount}
                                  </td>
                                  <td>
                                    {transaction.currency} {transaction.balance}
                                  </td>
                                  <td>{transaction.description}</td>
                                  {/* <td>{transaction.status}</td>  */}
                                  <td>
                                    {formatDateTimeInEAT(transaction.date)}
                                  </td>
                                </tr>
                              ))}
                              {walletsTransactions.length === 0 && (
                                <tr>
                                  <td colSpan="7">No transactions</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {loading && (
                  <div className="text-center">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{ width: "3rem", height: "3rem" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* deposit into account using mpesa (mpesa => bank) */}
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Deposit Money into Wallet
                </h1>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (formData.walletId === "" || formData.amount === 0) {
                      toast.error("Please fill all fields");
                      return;
                    }

                    try {
                      setIsFetching(true);
                      await initiateStkPush(
                        formData.walletId,
                        formData.amount,
                        formData.phone
                      ).then((response) => {
                        setFormData({
                          walletId: "",
                          phone: "",
                          amount: 0,
                        });
                        setIsFetching(false);
                        toast.success(
                          "Payment initiated successfully. Please check your phone for payment prompt."
                        );
                      });
                    } catch (error) {
                      setIsFetching(false);
                      toast.error(error.message);
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Wallet</label>
                        <select
                          name="account"
                          id="account"
                          className="form-select"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              walletId: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Select Wallet to deposit into
                          </option>
                          {wallets.length > 0 &&
                            wallets.map((wallet, i) => (
                              <option value={wallet.walletId} key={i}>
                                {wallet.name} - {wallet.accountNumber}{" "}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="2547xxxxxxxx"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              phone: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          required
                          min={10}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              amount: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-bs-dismiss="modal"
                      disabled={isFetching}
                    >
                      {isFetching ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{ width: "1rem", height: "1rem" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Deposit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Send To Mobile */}
        <div
          class="modal fade"
          id="sendToMobile"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="sendToMobileLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="sendToMobileLabel">
                  Send Money to Mobile
                </h1>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (formData.walletId === "" || formData.amount === 0) {
                      toast.error("Please fill all fields");
                      return;
                    }
                    try {
                      setIsFetching(true);
                      await initiateWithdrawal(
                        formData.walletId,
                        formData.amount,
                        "KE_DTB_MPESA",
                        formData.phone
                      )
                        .then((response) => {
                          setFormData({
                            walletId: "",
                            phone: "",
                            amount: 0,
                          });
                          setIsFetching(false);
                          toast.success("Withdrawal initiated");
                        })
                        .catch((error) => {
                          setIsFetching(false);
                          toast.error(error.message);
                        });
                    } catch (error) {
                      setIsFetching(false);
                      toast.error(error.message);
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Wallet</label>
                        <select
                          name="account"
                          id="account"
                          className="form-select"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              walletId: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Select Wallet to send from
                          </option>
                          {wallets.length > 0 &&
                            wallets.map((wallet, i) => (
                              <option value={wallet.walletId} key={i}>
                                {wallet.name} - {wallet.accountNumber}{" "}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="2547xxxxxxxx"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              phone: e.target.value,
                            });
                          }}
                        />
                        <span>
                          <small className="text-muted">
                            For success response use test number: 254705912645
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          required
                          min={10}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              amount: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-bs-dismiss="modal"
                      disabled={isFetching}
                    >
                      {isFetching ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{ width: "1rem", height: "1rem" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Withdraw"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* PesaLink */}
        <div
          class="modal fade"
          id="mpesaToBank"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="mpesaToBankLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="mpesaToBankLabel">
                  Send Money to Bank
                </h1>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (
                      formData.walletId === "" ||
                      formData.amount === 0 ||
                      formData.accountName === "" ||
                      formData.accountNumber === "" ||
                      formData.bankCode === ""
                    ) {
                      toast.error("Please fill all fields");
                      return;
                    }
                    try {
                      setIsFetching(true);
                      await initiateWithdrawal(
                        formData.walletId,
                        formData.amount,
                        "KE_DTB_PESALINK",
                        "",
                        "",
                        formData.accountName,
                        formData.accountNumber,
                        formData.bankCode
                      )
                        .then((response) => {
                          setFormData({
                            walletId: "",
                            bankCode: "",
                            accountName: "",
                            accountNumber: "",
                            amount: 0,
                          });
                          setIsFetching(false);
                          toast.success("Withdrawal initiated successfully");
                        })
                        .catch((error) => {
                          setIsFetching(false);
                          toast.error(error.message);
                        });
                    } catch (error) {
                      setIsFetching(false);
                      toast.error(error.message);
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Wallet</label>
                        <select
                          name="account"
                          id="account"
                          className="form-select"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              walletId: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Select Wallet to send from
                          </option>
                          {wallets.length > 0 &&
                            wallets.map((wallet, i) => (
                              <option value={wallet.walletId} key={i}>
                                {wallet.name} - {wallet.accountNumber}{" "}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Bank</label>
                        <select
                          name="bankCode"
                          id="bankCode"
                          className="form-select"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              bankCode: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected disabled>
                            Select Bank to send to
                          </option>
                          {banks.length > 0 &&
                            banks
                              .sort((a, b) =>
                                a.bankName.localeCompare(b.bankName)
                              )
                              .map((bank, i) => (
                                <option value={bank.bankCode} key={i}>
                                  {bank.bankName}
                                </option>
                              ))}
                        </select>
                        <span>
                          <small className="text-muted">
                            For success response use Victoria Commercial Bank
                          </small>
                        </span>
                      </div>
                      
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Account Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Account Name"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              accountName: e.target.value,
                            });
                          }}
                        />
                        <span>
                          <small className="text-muted">
                            For success response use any random account name
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Account Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Account Number"
                          required
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              accountNumber: e.target.value,
                            });
                          }}
                        />
                        <span>
                          <small className="text-muted">
                            For success response use 1004477016
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          required
                          min={10}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              amount: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                      data-bs-dismiss="modal"
                      disabled={isFetching}
                    >
                      {isFetching ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                          style={{ width: "1rem", height: "1rem" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Withdraw"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Slide}
        />
      </div>
    </>
  );
};

export default Wallet;

import React, {useState, useEffect, useContext}  from "react";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { DefaultEditor } from "react-simple-wysiwyg";
import DatePicker from "react-datepicker";
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import ProjectForm from "./projectForm.js";
import { UserContext } from "../../../InitialPage/App.js";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const ProjectCards = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [html, setHtml] = React.useState("my <b>HTML</b>");
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);

    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput1, setSearchInput1] = useState('');
    const [searchInput2, setSearchInput2] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [selectedDate3, setSelectedDate3] = useState(null);
    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };

    const [formData, setFormData] = useState({
        projectName: '',
        projectDeadline: '',
        teamName:'',
        projectLeader:'',
        priority: '',
        projectDescription: '',
    });

    const [editProjectId, setEditProjectId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const filtered = data.filter(item =>
            (!item.deleted &&
                (item.projectName.toLowerCase().includes(searchInput1.toLowerCase()) &&
                    item.teamName.toLowerCase().includes(searchInput2.toLowerCase()))
            )
        );
        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    }, [data, searchInput1, searchInput2]);

    const handleSearchInputChange1 = (event) => {
        setSearchInput1(event.target.value);
    };

    const handleSearchInputChange2 = (event) => {
        setSearchInput2(event.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the projects data in the database
            await updateDoc(doc(db, 'projects', editProjectId), formData);

            // Clear the form data and reset the editProjectId state
            setFormData({
                projectName: '',
                startDate: '',
                projectDeadline: '',
                completionDate: '',
                projectTotalHours:'',
                projectRemainingHours:'',
                teamName:'',
                projectLeader:'',
                teamMembers:'',
                priority: '',
                projectDescription: '',
            });
            setEditProjectId(null);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const handleDeleteProject = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'projects', editProjectId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editProjectId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });

            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);


    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            const querySnapshot = await getDocs(collection(db, 'teams'));
            const teamList = [];
            querySnapshot.forEach((doc) => {
                const teamData = doc.data();
                if (!teamData.deleted && teamData.organisationId === userData[0]?.organisationId) {
                    teamList.push({ id: doc.id, ...teamData });
                }
            });
            setTeams(teamList);
        };
        fetchTeams();
    }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'projects');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'projects');

                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editProjectId) {
                    const selectedProject = dataArray.find(item => item.id === editProjectId);
                    setFormData(selectedProject);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput1, searchInput2, editProjectId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Projects</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/timesheets/submitted-timesheets">Timesheets</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Projects</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#create_project">
                                                <i className="fa fa-plus"/> Create Project
                                            </Link>
                                            <div className="view-icons">
                                                <Link
                                                    to="/hod/timesheets/project-cards"
                                                    className="grid-view btn btn-link active mx-2">
                                                    <i className="fa fa-th"/>
                                                </Link>
                                                <Link
                                                    to="/hod/timesheets/project-list"
                                                    className="list-view btn btn-link">
                                                    <i className="fa fa-bars"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-12 col-md-12">
                                        <div
                                            className={
                                                focused
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                value={searchInput1}
                                                onChange={handleSearchInputChange1}
                                            />
                                            <label className="focus-label">Project Name</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {filteredData.map(item => (
                                        <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="dropdown dropdown-action profile-action">
                                                        <Link
                                                            to="#"
                                                            className="action-icon dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i className="material-icons">more_vert</i>
                                                        </Link>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#edit_project"
                                                                onClick={() => setEditProjectId(item.id)}>
                                                                <i className="fa fa-pencil m-r-5"/> Edit
                                                            </Link>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#delete_project"
                                                                onClick={() => setEditProjectId(item.id)}>
                                                                <i className="fa fa-trash m-r-5"/> Delete
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h4 className="project-title">
                                                        <Link to="/app/projects/projects-view">
                                                            {item.projectName}
                                                        </Link>
                                                    </h4>
                                                    <p className="text-muted">
                                                        {item.projectDescription}
                                                    </p>
                                                    <div className="pro-deadline m-b-15">
                                                        <div className="sub-title">Deadline:</div>
                                                        <p className="text-muted">{item.projectDeadline}</p>
                                                    </div>
                                                    <div className="project-members m-b-15">
                                                        <div>Project Leader :</div>
                                                        <ul className="team-members">
                                                            <li className="text-muted">
                                                                {item.projectLeader || "No leader assigned"}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="project-members m-b-15">
                                                        <div>Team :</div>
                                                        <ul className="team-members">
                                                            <li className="text-muted">

                                                                {item.teamName || "No team assigned"}
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div className="project-members m-b-15">
                                                        <div>Employees :</div>
                                                        <ul className="team-members">
                                                            <li className="text-muted">
                                                                {item.employeeHours.map(employeeHour => (
                                                                    <li key={employeeHour.employeeName}>{employeeHour.employeeName}</li>
                                                                ))}
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <ProjectForm/>

                            {/* /Edit Project Modal */}
                            <div id="edit_project" className="modal custom-modal fade" role="dialog">
                                <div
                                    className="modal-dialog modal-dialog-centered modal-lg"
                                    role="document"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Project</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Project Name</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="projectName"
                                                                autoComplete="off"
                                                                value={formData.projectName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Deadline </label>
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate3}
                                                                    onChange={handleDateChange3}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Priority</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="priority"
                                                                value={formData.priority}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Select a priority</option>
                                                                <option>High</option>
                                                                <option>Medium</option>
                                                                <option>Low</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="input-block">
                                                    <label>Description</label>
                                                    <DefaultEditor
                                                        type="text"
                                                        className="form-control"
                                                        value={formData.projectDescription}
                                                        onChange={handleInputChange}
                                                        name="projectDescription"
                                                    />
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Edit Project Modal */}

                            {/* Delete Project Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_project"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Project</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              onClick={handleDeleteProject} data-bs-dismiss="modal">
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Project Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectCards;

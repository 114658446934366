import React, {useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";
import {Link} from "react-router-dom";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const LeaveSchedulingTable = () => {
    const [leaveDates, setLeaveDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const fetchLeaveDates = async () => {
            try {
                const colRef = collection(db, 'issue-leave');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setLeaveDates(dataArray);
            } catch (error) {
                console.error('Error fetching leave dates:', error);
            }
        };

        fetchLeaveDates();
    }, []);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Leave Scheduling </h3>
                </div>
                <div className="card-body">
                    <div className="card mt-2 bg-white calendarIndex">
                        <div className="card-body">
                            <FullCalendar
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin,
                                ]}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                                }}
                                initialView="dayGridMonth"
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                weekends={true}
                                events={leaveDates.map(event => ({
                                    title: (event.employeeName) || "All",
                                    start: new Date(event.startDate.seconds * 1000).toISOString(),
                                    end: new Date(event.endDate.seconds * 1000).toISOString(),
                                    allDay: true,
                                    backgroundColor: '#00AAB9',
                                }))}
                                eventContent={({event}) => (
                                    <div>
                                        {event.title}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Link to="/hr/leave-management/leave-scheduling">View full calendar</Link>
                </div>
            </div>
        </div>
    )
}

export default LeaveSchedulingTable;

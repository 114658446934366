import React, {useContext, useState, useEffect} from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, } from 'firebase/storage';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import { UserContext } from "../../../InitialPage/App.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const HodPerformanceReviews = () => {

    const [data, setData] = useState([]);
    const storage = getStorage();
    const [logoFile, setLogoFile] = useState(null);

    const { userRole } = useContext(UserContext);

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };

    const handleLogoFileChange = (e) => {
        const file = e.target.files[0];
        setLogoFile(file);
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);


    //Professional Excellence Adding and Removing Rows
    const [rows, setRows] = useState([
        {
            keyResultArea: '',
            keyPerformanceIndicator: '',
            weightage: '',
            percentageAchieved: '',
            professionalPointsScored: ''
        }
    ]);

    const addRow = () => {
        setRows([...rows, {
            keyResultArea: '',
            keyPerformanceIndicator: '',
            weightage: '',
            percentageAchieved: '',
            professionalPointsScored: ''
        }]);
    };

    const removeRow = (index) => {
        if (index === 0) return; // Prevent removing the first row
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    //Personal Excellence Adding and Removing Rows
    const [personalRows, setPersonalRows] = useState([
        {
            personalAttribute: '',
            keyIndicator: '',
            personalWeightage: '',
            personalPercentageAchieved: '',
            personalPointsScored: ''
        }
    ]);

    const addPersonalRow = () => {
        setPersonalRows([...personalRows, {
            personalAttribute: '',
            keyIndicator: '',
            personalWeightage: '',
            personalPercentageAchieved: '',
            personalPointsScored: ''
        }]);
    };

    const removePersonalRow = (index) => {
        if (index === 0) return; // Prevent removing the first row
        const updatedPersonalRows = [...personalRows];
        updatedPersonalRows.splice(index, 1);
        setPersonalRows(updatedPersonalRows);
    };

    const handlePersonalChange = (index, field, value) => {
        const updatedPersonalRows = [...personalRows];
        updatedPersonalRows[index][field] = value;
        setPersonalRows(updatedPersonalRows);
    };

    //Special Initiatives
    const [initiativesRows, setInitiativesRows] = useState([
        {
            selfInitiative: '',
            hodInitiative: ''
        }
    ]);

    const addInitiativeRow = () => {
        setInitiativesRows([...initiativesRows, {
            selfInitiative: '',
            hodInitiative: ''
        }]);
    };

    const removeInitiativeRow = (index) => {
        const updatedInitiativesRows = [...initiativesRows];
        updatedInitiativesRows.splice(index, 1);
        setInitiativesRows(updatedInitiativesRows);
    };

    const handleInitiativeChange = (index, field, value) => {
        const updatedInitiativesRows = [...initiativesRows];
        updatedInitiativesRows[index][field] = value;
        setInitiativesRows(updatedInitiativesRows);
    };

    //Comments on the role 1
    const [commentsRows, setCommentsRows] = useState([
        {
            selfComment: '',
            hodComment: ''
        }
    ]);

    const addCommentRow = () => {
        setCommentsRows([...commentsRows, {
            selfComment: '',
            hodComment: ''
        }]);
    };

    const removeCommentRow = (index) => {
        const updatedCommentsRows = [...commentsRows];
        updatedCommentsRows.splice(index, 1);
        setCommentsRows(updatedCommentsRows);
    };

    const handleCommentChange = (index, field, value) => {
        const updatedCommentsRows = [...commentsRows];
        updatedCommentsRows[index][field] = value;
        setCommentsRows(updatedCommentsRows);
    };

    //Comments on the Role 2
    const [roleCommentsRows, setRoleCommentsRows] = useState([
        {
            strengths: '',
            areasForImprovement: ''
        }
    ]);

    const addRoleCommentRow = () => {
        setRoleCommentsRows([...roleCommentsRows, {
            strengths: '',
            areasForImprovement: ''
        }]);
    };

    const removeRoleCommentRow = (index) => {
        const updatedRoleCommentsRows = [...roleCommentsRows];
        updatedRoleCommentsRows.splice(index, 1);
        setRoleCommentsRows(updatedRoleCommentsRows);
    };

    const handleRoleCommentChange = (index, field, value) => {
        const updatedRoleCommentsRows = [...roleCommentsRows];
        updatedRoleCommentsRows[index][field] = value;
        setRoleCommentsRows(updatedRoleCommentsRows);
    };

    //Appraisee's Strengths and Areas for Improvement
    const [appraiseeRows, setAppraiseeRows] = useState([
        {
            appraiseeStrengths: '',
            improvementAreas: ''
        }
    ]);

    const addAppraiseeRow = () => {
        setAppraiseeRows([...appraiseeRows, {
            appraiseeStrengths: '',
            improvementAreas: ''
        }]);
    };

    const removeAppraiseeRow = (index) => {
        const updatedAppraiseeRows = [...appraiseeRows];
        updatedAppraiseeRows.splice(index, 1);
        setAppraiseeRows(updatedAppraiseeRows);
    };

    const handleAppraiseeChange = (index, field, value) => {
        const updatedAppraiseeRows = [...appraiseeRows];
        updatedAppraiseeRows[index][field] = value;
        setAppraiseeRows(updatedAppraiseeRows);
    };

    //Personal Goals
    const [personalGoalRows, setPersonalGoalRows] = useState([
        {
            lastYearAchievement: '',
            currentYearGoal: ''
        }
    ]);

    const addPersonalGoalRow = () => {
        setPersonalGoalRows([...personalGoalRows, {
            lastYearAchievement: '',
            currentYearGoal: ''
        }]);
    };

    const removePersonalGoalRow = (index) => {
        const updatedPersonalGoalRows = [...personalGoalRows];
        updatedPersonalGoalRows.splice(index, 1);
        setPersonalGoalRows(updatedPersonalGoalRows);
    };

    const handlePersonalGoalChange = (index, field, value) => {
        const updatedPersonalGoalRows = [...personalGoalRows];
        updatedPersonalGoalRows[index][field] = value;
        setPersonalGoalRows(updatedPersonalGoalRows);
    };

    //Professional Goals for Last Year
    const [professionalGoalRows, setProfessionalGoalRows] = useState([
        {
            selfProfessionalGoalLastYear: '',
            hodProfessionalGoalLastYear: ''
        }
    ]);

    const addProfessionalGoalRow = () => {
        setProfessionalGoalRows([...professionalGoalRows, {
            selfProfessionalGoalLastYear: '',
            hodProfessionalGoalLastYear: ''
        }]);
    };

    const removeProfessionalGoalRow = (index) => {
        const updatedProfessionalGoalRows = [...professionalGoalRows];
        updatedProfessionalGoalRows.splice(index, 1);
        setProfessionalGoalRows(updatedProfessionalGoalRows);
    };

    const handleProfessionalGoalChange = (index, field, value) => {
        const updatedProfessionalGoalRows = [...professionalGoalRows];
        updatedProfessionalGoalRows[index][field] = value;
        setProfessionalGoalRows(updatedProfessionalGoalRows);
    };


    //Professional Goals for the Forthcoming Year
    const [forthcomingYearGoalRows, setForthcomingYearGoalRows] = useState([
        {
            selfProfessionalGoalForthcomingYear: '',
            hodProfessionalGoalForthcomingYear: ''
        }
    ]);

    const addForthcomingYearGoalRow = () => {
        setForthcomingYearGoalRows([...forthcomingYearGoalRows, {
            selfProfessionalGoalForthcomingYear: '',
            hodProfessionalGoalForthcomingYear: ''
        }]);
    };

    const removeForthcomingYearGoalRow = (index) => {
        const updatedForthcomingYearGoalRows = [...forthcomingYearGoalRows];
        updatedForthcomingYearGoalRows.splice(index, 1);
        setForthcomingYearGoalRows(updatedForthcomingYearGoalRows);
    };

    const handleForthcomingYearGoalChange = (index, field, value) => {
        const updatedForthcomingYearGoalRows = [...forthcomingYearGoalRows];
        updatedForthcomingYearGoalRows[index][field] = value;
        setForthcomingYearGoalRows(updatedForthcomingYearGoalRows);
    };

    //Training Requirements
    const [trainingRequirementRows, setTrainingRequirementRows] = useState([
        {
            selfTrainingRequirement: '',
            hodTrainingRequirement: ''
        }
    ]);

    const addTrainingRequirementRow = () => {
        setTrainingRequirementRows([...trainingRequirementRows, {
            selfTrainingRequirement: '',
            hodTrainingRequirement: ''
        }]);
    };

    const removeTrainingRequirementRow = (index) => {
        const updatedTrainingRequirementRows = [...trainingRequirementRows];
        updatedTrainingRequirementRows.splice(index, 1);
        setTrainingRequirementRows(updatedTrainingRequirementRows);
    };

    const handleTrainingRequirementChange = (index, field, value) => {
        const updatedTrainingRequirementRows = [...trainingRequirementRows];
        updatedTrainingRequirementRows[index][field] = value;
        setTrainingRequirementRows(updatedTrainingRequirementRows);
    };

    //General Comments
    const [generalCommentsRows, setGeneralCommentsRows] = useState([
        {
            selfGeneralComment: '',
            hodGeneralComment: ''
        }
    ]);

    const addGeneralCommentsRow = () => {
        setGeneralCommentsRows([...generalCommentsRows, {
            selfGeneralComment: '',
            hodGeneralComment: ''
        }]);
    };

    const removeGeneralCommentsRow = (index) => {
        const updatedGeneralCommentsRows = [...generalCommentsRows];
        updatedGeneralCommentsRows.splice(index, 1);
        setGeneralCommentsRows(updatedGeneralCommentsRows);
    };

    const handleGeneralCommentsChange = (index, field, value) => {
        const updatedGeneralCommentsRows = [...generalCommentsRows];
        updatedGeneralCommentsRows[index][field] = value;
        setGeneralCommentsRows(updatedGeneralCommentsRows);
    };


    //HOD's Use
    const [hodRows, setHODRows] = useState([
        {
            overallParameter: '',
            availablePoints: '',
            pointsScored: ''
        }
    ]);

    const addHODRow = () => {
        setHODRows([...hodRows, {
            overallParameter: '',
            availablePoints: '',
            pointsScored: ''
        }]);
    };

    const removeHODRow = (index) => {
        const updatedHODRows = [...hodRows];
        updatedHODRows.splice(index, 1);
        setHODRows(updatedHODRows);
    };

    const handleHODChange = (index, field, value) => {
        const updatedHODRows = [...hodRows];
        updatedHODRows[index][field] = value;
        setHODRows(updatedHODRows);
    };

    const [formData, setFormData] = useState({
        //Employee Basic Information
        employeeName: '',
        departmentName: '',
        designationName: '',
        qualification: '',
        employeeID: '',
        dateOfJoin: '',
        previousYearsOfExperience: '',

        //Professional Excellence
        totalWeightage: '',
        totalPercentageAchieved: '',
        totalPointsScored: '',

        //Personal Excellence
        totalPersonalWeightage: '',
        totalPersonalPercentageAchieved: '',
        totalPersonalPointsScored: '',

        //Personal Updates
        marriedEngaged: '',
        marriedEngagedDetails: '',
        marriagePlans: '',
        marriagePlansDetails: '',
        higherStudiesCertification: '',
        higherStudiesCertificationDetails: '',
        higherStudiesPlans: '',
        higherStudiesPlansDetails: '',
        healthIssue: '',
        healthIssueDetails: '',
        certificationPlans: '',
        certificationPlansDetails: '',
        others1: '',
        others1Details: '',
        others2: '',
        others2Details: '',

        //End
        employeeName2: '',
        employeeSignature: '',
        employeeDate: '',
        hodName: '',
        hodSignature: '',
        hodDate: '',
    });

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();

        const fetchDepartments = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'departments'));
                const departmentList = [];
                querySnapshot.forEach((doc) => {
                    const departmentData = doc.data();

                    if (!departmentData.deleted &&
                        departmentData.organisationId === userData[0]?.organisationId
                    ) {
                        departmentList.push({ id: doc.id, ...departmentData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setDepartments(departmentList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'departments'));
                const departmentList = [];
                querySnapshot.forEach((doc) => {
                    const departmentData = doc.data();

                    // Check if the employee is active and not deleted
                    if (!departmentData.deleted &&
                        departmentData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        departmentList.push({ id: doc.id, ...departmentData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setDepartments(departmentList);
            }
        };
        fetchDepartments();
        // console.log("Departments", departments);
    }, [userData, selectedCompany]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if(name === 'employeeName'){
            const selectedEmployee = employees.find(employee => employee.employeeName === value)
            const selectedDepartment = departments.find(department => department.employeeName.includes(value));

            setFormData({
                ...formData,
                [name]: value ,
                qualification: selectedEmployee ? selectedEmployee.qualification : '',
                employeeID: selectedEmployee ? selectedEmployee.id : '',
                departmentName: selectedDepartment ? selectedDepartment.departmentName : '',
            });
        } else {
            // For other fields, just update the formData as usual
            setFormData({ ...formData, [name]: value });
        }
        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        let dateOfJoinString = null;
        let signatureDateString1 = null;
        let signatureDateString2 = null;

        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            dateOfJoinString = `${year}-${month}-${day}`;
        }

        if (selectedDate2) {
            const year = selectedDate2.getFullYear();
            const month = String(selectedDate2.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate2.getDate()).padStart(2, '0');
            signatureDateString1 = `${year}-${month}-${day}`;
        }
        if (selectedDate3) {
            const year = selectedDate3.getFullYear();
            const month = String(selectedDate3.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate3.getDate()).padStart(2, '0');
            signatureDateString2 = `${year}-${month}-${day}`;
        }

        try {
            const docRef = await addDoc(collection(db, 'performance-reviews'), {
                ...formData,
                dateOfJoin: dateOfJoinString,
                employeeDate: signatureDateString1,
                hodDate: signatureDateString2,
                rows: rows.map(({ keyResultArea, keyPerformanceIndicator, weightage, percentageAchieved, professionalPointsScored }) => ({
                    keyResultArea,
                    keyPerformanceIndicator,
                    weightage,
                    percentageAchieved,
                    professionalPointsScored
                })),
                personalRows: personalRows.map(({ personalAttribute, keyIndicator, personalWeightage, personalPercentageAchieved, personalPointsScored }) => ({
                    personalAttribute,
                    keyIndicator,
                    personalWeightage,
                    personalPercentageAchieved,
                    personalPointsScored
                })),
                initiativesRows: initiativesRows.map(({ selfInitiative, hodInitiative }) => ({
                    selfInitiative,
                    hodInitiative
                })),
                commentsRows: commentsRows.map(({ selfComment, hodComment }) => ({
                    selfComment,
                    hodComment
                })),
                roleCommentsRows: roleCommentsRows.map(({ strengths, areasForImprovement }) => ({
                    strengths,
                    areasForImprovement
                })),
                appraiseeRows: appraiseeRows.map(({ appraiseeStrengths, improvementAreas }) => ({
                    appraiseeStrengths,
                    improvementAreas
                })),
                personalGoalRows: personalGoalRows.map(({ lastYearAchievement, currentYearGoal }) => ({
                    lastYearAchievement,
                    currentYearGoal
                })),
                professionalGoalRows: professionalGoalRows.map(({ selfProfessionalGoalLastYear, hodProfessionalGoalLastYear }) => ({
                    selfProfessionalGoalLastYear,
                    hodProfessionalGoalLastYear
                })),
                forthcomingYearGoalRows: forthcomingYearGoalRows.map(({ selfProfessionalGoalForthcomingYear, hodProfessionalGoalForthcomingYear }) => ({
                    selfProfessionalGoalForthcomingYear,
                    hodProfessionalGoalForthcomingYear
                })),
                trainingRequirementRows: trainingRequirementRows.map(({ selfTrainingRequirement, hodTrainingRequirement }) => ({
                    selfTrainingRequirement,
                    hodTrainingRequirement
                })),
                generalCommentsRows: generalCommentsRows.map(({ selfGeneralComment, hodGeneralComment }) => ({
                    selfGeneralComment,
                    hodGeneralComment
                })),
                hodRows: hodRows.map(({ overallParameter, availablePoints, pointsScored }) => ({
                    overallParameter,
                    availablePoints,
                    pointsScored
                })),
            });
            console.log("Document written with ID: ", docRef.id);
            showToast('Performance review added Successfully', 'success');

            // resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
            showToast('An error occurred while creating the review.', 'error');
        }
    };

    // Function to handle PDF button click
    const handlePDFExport = () => {
        const element = document.getElementById('performance-review'); // Assuming you have a container with id 'payslip-container'

        html2pdf()
            .from(element)
            .save('performance-review.pdf');
    };

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('performance-review').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        try {

            let logoURL = null;

            // Check if a logo file is selected
            if (logoFile) {
                // Upload logo file to Firebase Storage
                const logoRef = ref(storage, `performance-reviews/${logoFile.name}`);
                await uploadBytes(logoRef, logoFile);

                // Get the download URL of the uploaded logo
                logoURL = await getDownloadURL(logoRef);
            }

            // Add the logo URL to the form data
            const logoFormData = {
                logoURL,
            };
            const docRef = await addDoc(collection(db, 'performance-reviews'), logoFormData);
            console.log("Document written with ID: ", docRef.id);

            showToast('Performance Review Added Successfully', 'success');

            setLogoFile(null);

            // Clear the file input value for the logo
            document.getElementById('logoInput').value = '';
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    }

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Performance Reviews</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Performance Reviews</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_performance_review">
                                                <i className="fa fa-plus"/> Add Performance Review
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit} id="performance-review">
                                    <section className="review-section information">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Employee Basic Information</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-nowrap review-table mb-0">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                            {userData[0]?.role === 'sys-admin' && (  
                                                                    <div className="input-block">
                                                                        <label className="col-form-label">Select Organisation</label>
                                                                        <select
                                                                            className="form-select form-control"
                                                                            name="companyName"
                                                                            value={formData.companyName}
                                                                            onChange={handleInputChange}
                                                                            required
                                                                        >
                                                                            <option value="">Select an active organisation</option>
                                                                            {companies.map(company => (
                                                                                <option>
                                                                                    {company.companyName}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                )}
                                                                <div className="input-block">
                                                                    <label htmlFor="employeeName">Employee Name<span
                                                                        className="text-danger">*</span></label>
                                                                    <select
                                                                        className="form-select form-control"
                                                                        name="employeeName"
                                                                        value={formData.employeeName}
                                                                        onChange={handleInputChange}
                                                                        required
                                                                    >
                                                                        <option value="">Select an employee</option>
                                                                        {employees.map(employee => (
                                                                            <option>
                                                                                {employee.employeeName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="input-block">
                                                                    <label htmlFor="departmentName">Department</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="departmentName"
                                                                        autoComplete="off"
                                                                        value={formData.departmentName}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="input-block">
                                                                    <label htmlFor="designationName">Designation</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="designationName"
                                                                        autoComplete="off"
                                                                        value={formData.designationName}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="input-block">
                                                                    <label htmlFor="qualification">Qualification</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="qualification"
                                                                        autoComplete="off"
                                                                        value={formData.qualification}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <form>
                                                                    <div className="input-block">
                                                                        <label htmlFor="employeeID">Employee ID</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="employeeID"
                                                                            autoComplete="off"
                                                                            value={formData.employeeID}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="input-block">
                                                                        <label htmlFor="dateOfJoin">Date of Join</label>
                                                                        <div className="cal-icon">
                                                                            <DatePicker
                                                                                selected={selectedDate}
                                                                                onChange={handleDateChange}
                                                                                className="form-control datetimepicker"
                                                                                type="date"
                                                                                name="dateOfJoin"
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="input-block">
                                                                        <label htmlFor="previousYearsOfExperience">Previous
                                                                            years of Experience</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name="previousYearsOfExperience"
                                                                            autoComplete="off"
                                                                            value={formData.previousYearsOfExperience}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                </form>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="review-section professional-excellence">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Professional Excellence</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: '40px'}}>#</th>
                                                            <th>Key Result Area</th>
                                                            <th>Key Performance Indicators</th>
                                                            <th>Weightage</th>
                                                            <th>Percentage achieved <br/> ( self Score )</th>
                                                            <th>Points Scored <br/> ( self )</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.keyResultArea}
                                                                        onChange={(e) => handleChange(index, 'keyResultArea', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.keyPerformanceIndicator}
                                                                        onChange={(e) => handleChange(index, 'keyPerformanceIndicator', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.weightage}
                                                                        onChange={(e) => handleChange(index, 'weightage', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.percentageAchieved}
                                                                        onChange={(e) => handleChange(index, 'percentageAchieved', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.professionalPointsScored}
                                                                        onChange={(e) => handleChange(index, 'professionalPointsScored', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="3" className="text-center">Total</td>
                                                            <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="totalWeightage"
                                                                autoComplete="off"
                                                                value={formData.totalWeightage}
                                                                onChange={handleInputChange}
                                                            />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="totalPercentageAchieved"
                                                                    autoComplete="off"
                                                                    value={formData.totalPercentageAchieved}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="totalPointsScored"
                                                                    autoComplete="off"
                                                                    value={formData.totalPointsScored}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section personal-excellence">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Personal Excellence</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: '40px'}}>#</th>
                                                            <th>Personal Attributes</th>
                                                            <th>Key Indicators</th>
                                                            <th>Weightage</th>
                                                            <th>Percentage achieved <br/> ( self Score )</th>
                                                            <th>Points Scored <br/> ( self )</th>
                                                            <th colSpan={personalRows.length > 1 ? 2 : 1}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {personalRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.personalAttribute}
                                                                        onChange={(e) => handlePersonalChange(index, 'personalAttribute', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.keyIndicator}
                                                                        onChange={(e) => handlePersonalChange(index, 'keyIndicator', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.personalWeightage}
                                                                        onChange={(e) => handlePersonalChange(index, 'personalWeightage', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.personalPercentageAchieved}
                                                                        onChange={(e) => handlePersonalChange(index, 'personalPercentageAchieved', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.personalPointsScored}
                                                                        onChange={(e) => handlePersonalChange(index, 'personalPointsScored', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addPersonalRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removePersonalRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="3" className="text-center">Total</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="totalPersonalWeightage"
                                                                    autoComplete="off"
                                                                    value={formData.totalPersonalWeightage}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="totalPersonalPercentageAchieved"
                                                                    autoComplete="off"
                                                                    value={formData.totalPersonalPercentageAchieved}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="totalPersonalPointsScored"
                                                                    autoComplete="off"
                                                                    value={formData.totalPersonalPointsScored}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">
                                                Special Initiatives, Achievements, contributions if any
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-review review-table mb-0"
                                                        id="table_achievements">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>By Self</th>
                                                            <th>HOD's Comment</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="table_achievements_tbody">
                                                        {initiativesRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.selfInitiative}
                                                                        onChange={(e) => handleInitiativeChange(index, 'selfInitiative', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.hodInitiative}
                                                                        onChange={(e) => handleInitiativeChange(index, 'hodInitiative', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addInitiativeRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeInitiativeRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Comments on the role</h3>
                                            <p className="text-muted">
                                                alterations if any required like addition/deletion of responsibilities
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-review review-table mb-0"
                                                        id="table_alterations">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>By Self</th>
                                                            <th>HOD's Comment</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="table_alterations_tbody">
                                                        {commentsRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.selfComment}
                                                                        onChange={(e) => handleCommentChange(index, 'selfComment', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.hodComment}
                                                                        onChange={(e) => handleCommentChange(index, 'hodComment', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addCommentRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeCommentRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Comments on the role</h3>
                                            <p className="text-muted">
                                                alterations if any required like addition/deletion of responsibilities
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>Strengths</th>
                                                            <th>Area's for Improvement</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {roleCommentsRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.strengths}
                                                                        onChange={(e) => handleRoleCommentChange(index, 'strengths', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.areasForImprovement}
                                                                        onChange={(e) => handleRoleCommentChange(index, 'areasForImprovement', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addRoleCommentRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeRoleCommentRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">
                                                Appraisee's Strengths and Areas for Improvement perceived by the Head of
                                                the Department
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>Appraisee Strengths</th>
                                                            <th>Improvement Areas</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {appraiseeRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.appraiseeStrengths}
                                                                        onChange={(e) => handleAppraiseeChange(index, 'appraiseeStrengths', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.improvementAreas}
                                                                        onChange={(e) => handleAppraiseeChange(index, 'improvementAreas', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addAppraiseeRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeAppraiseeRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Personal Goals</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>Goal Achieved during last year</th>
                                                            <th>Goal set for current year</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {personalGoalRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.lastYearAchievement}
                                                                        onChange={(e) => handlePersonalGoalChange(index, 'lastYearAchievement', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={row.currentYearGoal}
                                                                        onChange={(e) => handlePersonalGoalChange(index, 'currentYearGoal', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addPersonalGoalRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removePersonalGoalRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Personal Updates</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>Last Year</th>
                                                            <th>Yes/No</th>
                                                            <th>Details</th>
                                                            <th>Current Year</th>
                                                            <th>Yes/No</th>
                                                            <th>Details</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Married/Engaged?</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="marriedEngaged"
                                                                    value={formData.marriedEngaged}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="marriedEngagedDetails"
                                                                    value={formData.marriedEngagedDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                            <td>Marriage Plans</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="marriagePlans"
                                                                    value={formData.marriagePlans}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="marriagePlansDetails"
                                                                    value={formData.marriagePlansDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Higher Studies/Certifications?</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="higherStudiesCertification"
                                                                    value={formData.higherStudiesCertification}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="higherStudiesCertificationDetails"
                                                                    value={formData.higherStudiesCertificationDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                            <td>Plans For Higher Study</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="higherStudiesPlans"
                                                                    value={formData.higherStudiesPlans}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="higherStudiesPlansDetails"
                                                                    value={formData.higherStudiesPlansDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Health Issues?</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="healthIssue"
                                                                    value={formData.healthIssue}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="healthIssueDetails"
                                                                    value={formData.healthIssueDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                            <td>Certification Plans</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="certificationPlans"
                                                                    value={formData.certificationPlans}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="certificationPlansDetails"
                                                                    value={formData.certificationPlansDetails}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Others</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="others1"
                                                                    value={formData.others1}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="others1Details"
                                                                    value={formData.others1Details}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                            <td>Others</td>
                                                            <td>
                                                                <select
                                                                    className="form-control form-select"
                                                                    name="others2"
                                                                    value={formData.others2}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="others2Details"
                                                                    value={formData.others2Details}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">
                                                Professional Goals Achieved for last year
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>By Self</th>
                                                            <th>HOD's Comment</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {professionalGoalRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.selfProfessionalGoalLastYear}
                                                                        onChange={(e) => handleProfessionalGoalChange(index, 'selfProfessionalGoalLastYear', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.hodProfessionalGoalLastYear}
                                                                        onChange={(e) => handleProfessionalGoalChange(index, 'hodProfessionalGoalLastYear', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addProfessionalGoalRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeProfessionalGoalRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">
                                                Professional Goals for the forthcoming year
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>By Self</th>
                                                            <th>HOD's Comment</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {forthcomingYearGoalRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.selfProfessionalGoalForthcomingYear}
                                                                        onChange={(e) => handleForthcomingYearGoalChange(index, 'selfProfessionalGoalForthcomingYear', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.hodProfessionalGoalForthcomingYear}
                                                                        onChange={(e) => handleForthcomingYearGoalChange(index, 'hodProfessionalGoalForthcomingYear', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addForthcomingYearGoalRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeForthcomingYearGoalRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">Training Requirements</h3>
                                            <p className="text-muted">
                                                if any to achieve the Performance Standard Targets completely
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>By Self</th>
                                                            <th>HOD's Comment</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {trainingRequirementRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.selfTrainingRequirement}
                                                                        onChange={(e) => handleTrainingRequirementChange(index, 'selfTrainingRequirement', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.hodTrainingRequirement}
                                                                        onChange={(e) => handleTrainingRequirementChange(index, 'hodTrainingRequirement', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addTrainingRequirementRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeTrainingRequirementRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">
                                                Any other general comments, observations, suggestions etc.
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-review review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "40px"}}>#</th>
                                                            <th>Self</th>
                                                            <th>HOD</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {generalCommentsRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.selfGeneralComment}
                                                                        onChange={(e) => handleGeneralCommentsChange(index, 'selfGeneralComment', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.hodGeneralComment}
                                                                        onChange={(e) => handleGeneralCommentsChange(index, 'hodGeneralComment', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addGeneralCommentsRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeGeneralCommentsRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <section className="review-section">
                                        <div className="review-header text-center">
                                            <h3 className="review-title">For HOD's Use Only</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered review-table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th>Overall Parameters</th>
                                                            <th>Available Points</th>
                                                            <th>Points Scored</th>
                                                            <th colSpan={2}>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {hodRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.overallParameter}
                                                                        onChange={(e) => handleHODChange(index, 'overallParameter', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.availablePoints}
                                                                        onChange={(e) => handleHODChange(index, 'availablePoints', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={row.pointsScored}
                                                                        onChange={(e) => handleHODChange(index, 'pointsScored', e.target.value)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={addHODRow}
                                                                            className="btn btn-primary btn-add-row"
                                                                        >
                                                                            <i className="fa fa-plus"/>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {index !== 0 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeHODRow(index)}
                                                                            className="btn btn-danger btn-add-row"
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered review-table mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th/>
                                                        <th>Name</th>
                                                        <th>Signature</th>
                                                        <th>Date</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Employee</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="employeeName2"
                                                                value={formData.employeeName2}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="employeeSignature"
                                                                value={formData.employeeSignature}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate2}
                                                                    onChange={handleDateChange2}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                    name="employeeDate"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                    <td>HOD</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="hodName"
                                                                value={formData.hodName}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="hodSignature"
                                                                value={formData.hodSignature}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate3}
                                                                    onChange={handleDateChange3}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                    name="hodDate"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>


                            {/* Add Performance Review Modal */}
                            <div
                                id="add_performance_review"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Performance Review</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form noValidate onSubmit={handleUpload}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Upload file </label>
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                accept=".pdf,.xlsx,.csv"
                                                                onChange={handleLogoFileChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Add Performance Review Modal */}
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default HodPerformanceReviews;

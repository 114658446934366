import React, {useState, useEffect, useContext}  from "react";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import {Avatar_02} from "../../../Entryfile/imagepath.js";
import HodResponsibilityForm from "./hodResponsibilityForm.js";
import { UserContext } from "../../../InitialPage/App.js";

const HodResponsibilities = () => {
    const { userRole } = useContext(UserContext);
    const [count, setCount] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };
    
    const [formData, setFormData] = useState({
        responsibility: '',
        employeeName: '',
        assignedDate:'',
        completionDate:'',
    });

    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [employees, setEmployees] = useState([]);
    const [editResponsibilityId, setEditResponsibilityId] = useState([]);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.employeeName.toLowerCase().includes(input.toLowerCase()) ||
            item.responsibility.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted); 
        }
    };

    const handleStatusChange = async (responsibilityId, newStatus) => {
        try {
            await updateDoc(doc(db, 'responsibilities', responsibilityId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === itemId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    }; 


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Update the data in the database
            await updateDoc(doc(db, 'responsibilities', editResponsibilityId), formData);
    
            // Clear the form data and reset the edit state
            setFormData({
                responsibility: '',
                employeeName: '',
                assignedDate:'',
                completionDate:'',
            });
            setEditResponsibilityId(null);
        } catch (error) {
            console.error('Error updating responsibility:', error);
        }
    };

    const handleDeleteResponsibility = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'responsibilities', editResponsibilityId), { deleted: true });
    
            // Update the local data without refetching from Firestore
            setData(data => data.map(item => 
                item.id === editResponsibilityId ? { ...item, deleted: true } : item
            ));
    
        } catch (error) {
            console.error('Error deleting responsibility:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'responsibilities');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editResponsibilityId) {
                    const selectedResponsbility = dataArray.find(item => item.id === editResponsibilityId);
                    setFormData(selectedResponsbility);
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editResponsibilityId]);
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Responsibilities</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/departments/department-list">Departments</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Responsibilities</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <a
                                                href="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_responsibility">
                                                <i className="fa fa-plus"/> Add Responsibility
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Responsibility Name</th>
                                                        <th>Assigned Employee</th>
                                                        <th>Date Assigned</th>
                                                        <th>Expected Date of Completion</th>
                                                        <th>Status</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{count + index}</td>
                                                            <td>{item.responsibility}</td>
                                                            <td>
                                                                <h2 className="table-avatar">
                                                                    <Link to="/employee-profile" className="avatar">
                                                                        <img alt="" src={Avatar_02}/>
                                                                    </Link>
                                                                    <Link to="/employee-profile">
                                                                        {item.employeeName}<span
                                                                        className="table-span"></span>
                                                                    </Link>
                                                                </h2>
                                                            </td>
                                                            <td>{item.assignedDate}</td>
                                                            <td>{item.completionDate}</td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'complete' ? 'success' : item.status === 'ongoing' ? 'purple' : 'danger'}`}></i>
                                                                        {item.status === 'complete' ? 'Complete' : item.status === 'ongoing' ? 'Ongoing' : 'Incomplete'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'complete')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Complete
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'incomplete')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Incompleted
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'ongoing')}>
                                                                            <i className="fa-regular fa-circle-dot text-purple me-1"></i> Ongoing
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown dropdown-action text-end">
                                                                    <Link
                                                                        to="#"
                                                                        className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="material-icons">more_vert</i>
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#edit_responsibility"
                                                                            onClick={() => setEditResponsibilityId(item.id)}>
                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                        </Link>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#delete_responsibility"
                                                                            onClick={() => setEditResponsibilityId(item.id)}>
                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-footer">
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_info">
                                            {searchInput
                                                ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <HodResponsibilityForm/>
                            {/* Edit Department Modal */}
                            <div
                                id="edit_responsibility"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Responsibility</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Responsibility Name <span
                                                                className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="responsibility"
                                                                autoComplete="off"
                                                                value={formData?.responsibility || ''}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Assigned Employee <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="employeeName"
                                                                value={formData?.employeeName || ''}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select an employee</option>
                                                                {employees.map(employee => (
                                                                    <option>
                                                                        {employee.employeeName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Department Modal */}

                            {/* Delete Department Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_responsibility"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Responsibility</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <a href="" className="btn btn-primary continue-btn"
                                                           onClick={handleDeleteResponsibility} data-bs-dismiss="modal">
                                                            Delete
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a
                                                            href=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Department Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HodResponsibilities;
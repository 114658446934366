import React, { useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, addDoc, where, query, getDocs, updateDoc } from "firebase/firestore";
import logo2 from "../../assets/img/rhokit-logo.png";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { countries } from "countries-list";
import { registerCustomer } from "../../lib/astra.js";
import { useNavigate, useParams } from "react-router-dom";

const Register = () => {
  const [emailError, setEmailError] = useState("");
  const [value, setValue] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countryNames, setCountryNames] = useState([]);

  useEffect(() => {
    // Extract country names from countries object
    const countryNamesArray = Object.values(countries).map(
      (country) => country.name
    );
    // Set the countryNames state with the list of country names
    setCountryNames(countryNamesArray);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    // role: "",
    // email: "",
    phone1: "",
    dateOfBirth: "",
    gender: "",
    birthCountry: "",
    birthCity: "",
    maritalStatus: "",
    status: "ACTIVE",
    nationalIdentityNumber: ""
  });

  // const getEmailForRole = (role) => {
  //   switch (role) {
  //     case "sys-admin":
  //       return "sys-admin";
  //     case "admin":
  //       return "admin";
  //     case "hr":
  //       return "hr";
  //     case "finance":
  //       return "finance";
  //     case "hod":
  //       return "hod";
  //     case "employee":
  //       return "employee";
  //     default:
  //       return "";
  //   }
  // };

  // useEffect(() => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     email: formData.email.endsWith("@sealhrms.com")
  //       ? formData.email
  //       : formData.email + "@sealhrms.com",
  //   }));
  // }, [formData.email]);

  // const handleEmailChange = (e) => {
  //   const emailValue = e.target.value;
  //   const atIndex = emailValue.indexOf("@");

  //   if (atIndex !== -1) {
  //     // If @ symbol is present in the input, ensure domain remains intact
  //     const updatedEmail = emailValue.slice(0, atIndex) + "@sealhrms.com";
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       email: updatedEmail,
  //     }));
  //   } else {
  //     // If @ symbol is not present, don't allow changes
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       email: formData.email,
  //     }));
  //   }
  // };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? formatDate(date) : ""; // Format the date
    setFormData({
      ...formData,
      dateOfBirth: formattedDate,
    });
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleInputChange1 = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //     email: getEmailForRole(value),
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!e.target.checkValidity()) {
      toast.error("Please fill in all required fields");
      return;
    }

    let dateOfBirthString = null;
    if (selectedDate) {
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
      const day = String(selectedDate.getDate()).padStart(2, "0");
      dateOfBirthString = `${year}${month}${day}`;
    }

    try {
      setLoading(true);

      // Check if the user already exists with the same nationalIdentityNumber and email
      const userQuery = await getDocs(
        query(
          collection(db, "users"),
          where("nationalIdentityNumber", "==", formData.nationalIdentityNumber),
          // where("email", "==", formData.email)
        )
      );

      if (!userQuery.empty) {
        // User already exists, show toast and navigate after delay
        setLoading(false);
        toast.error("You already have an account with us. Navigating to the next step...");

        setTimeout(() => {
          const userRef = userQuery.docs[0].id;
          navigate(`/register/${userRef}/add-address`);
        }, 3000); // 3000 milliseconds delay (adjust as needed)
        return;
      }

      // User does not exist, proceed with registration
      registerCustomer(
        formData.firstName,
        formData.lastName,
        value,
        formData.gender,
        formData.maritalStatus,
        formData.birthCountry,
        formData.birthCity,
        formData.nationalIdentityNumber,
        dateOfBirthString,
        formData.title
      )
        .then(async (data) => {
          const updatedFormData = {
            ...formData,
            customerId: data.customerId,
            phone: value,
            dateOfBirth: dateOfBirthString,
          };

          // Add user details to Firestore collection
          // const userRef = await addDoc(collection(db, "users"), {
          //   ...updatedFormData,
          // });

          const userQuery = query(
            collection(db, "users"),
            where("nationalIdentityNumber", "==", formData.nationalIdentityNumber)
          );
          
          const querySnapshot = await getDocs(userQuery);

          if (!querySnapshot.empty) {
            // Assuming nationalIdentityNumber is unique and only one document will match
            const userDoc = querySnapshot.docs[0];

            // Update the document
            await updateDoc(userDoc.ref, updatedFormData);

            setLoading(false);
            toast.success("User registered successfully");

            // Redirect to the next step
            navigate(`/register/${userDoc.id}/add-address`);
          } else {
            setLoading(false);
            toast.error("No user found, kindly check with your organisation");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="register">
        <div className="account-page">
          <div className="main-wrapper">
            <div className="account-content">
              <div className="container">
                <div className="account-logo">
                  <img src={logo2} alt="Logo" />
                </div>

                <div className="account-box">
                  <div className="account-wrapper">
                    <h3 className="account-title">Register</h3>
                    <p className="account-subtitle">Access to our dashboard</p>

                    <div>
                      <form onSubmit={handleSubmit} noValidate>
                        <h4>Personal Information</h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-block">
                              <label>Title
                              <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange2}
                              >
                                <option value>Select title</option>
                                <option value="Mr">Mr</option>
                                <option value="Ms">Ms</option>
                                <option value="Mrs">Mrs</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block">
                              <label>
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                autoComplete="off"
                                className="form-control"
                                value={formData.firstName}
                                onChange={handleInputChange2}
                                required
                                placeholder="Enter first name"
                              ></input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block">
                              <label>
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                autoComplete="off"
                                className="form-control"
                                value={formData.lastName}
                                onChange={handleInputChange2}
                                required
                                placeholder="Enter surname"
                              ></input>
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                            <div className="input-block">
                              <label>
                                Role <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="role"
                                value={formData.role}
                                onChange={handleInputChange2}
                                required
                              >
                                <option value>Select Role</option> */}
                                {/* <option value="sys-admin">System Admin</option> */}
                                {/* <option value="admin">Company Admin</option> */}
                                {/* <option value="hod">Department Head</option>
                                <option value="employee">Employee</option>
                                <option value="finance">Finance</option>
                                <option value="hr">HR</option>
                              </select>
                            </div>
                          </div> */}

                          {/* <div className="col-md-6">
                            <div className="input-block">
                              <label>
                                Email Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                name="email"
                                autoComplete="off"
                                className={`form-control ${
                                  emailError && "is-invalid"
                                }`}
                                value={formData.email}
                                onChange={handleInputChange2}
                              ></input>
                              <div className="text-danger">{emailError}</div>
                            </div>
                          </div> */}

                          <div className="col-md-6">
                            <div className="input-block">
                              <label>
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <PhoneInput
                                country={"ke"}
                                value={value}
                                onChange={setValue}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="input-block">
                              <label>
                                Date of Birth{" "} 
                                (dd/mm/yyyy)
                                <span className="text-danger">*</span>
                              </label>
                              <div className="cal-icon">
                                <DatePicker
                                  selected={selectedDate}
                                  dateFormat="dd/MM/yyyy"
                                  onChange={handleDateChange}
                                  className="form-control datetimepicker"
                                  type="date"
                                  maxDate={new Date()}
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-block">
                              <label>Gender
                              <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange2}
                              >
                                <option value="">Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="col-form-label">
                                Birth Country
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="birthCountry"
                                value={formData.birthCountry}
                                onChange={handleInputChange2}
                              >
                                <option value="">Select Country</option>
                                {countryNames.sort().map((country) => (
                                  <option key={country} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="col-form-label">
                                Birth City
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="birthCity"
                                autoComplete="off"
                                value={formData.birthCity}
                                onChange={handleInputChange2}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="col-form-label">
                                Marital Status
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-select form-control"
                                name="maritalStatus"
                                value={formData.maritalStatus}
                                onChange={handleInputChange2}
                              >
                                <option value>Select marital status</option>
                                <option value="S">Single</option>
                                <option value="M">Married</option>
                                <option value="D">Divorced</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-block">
                              <label className="col-form-label">
                                National ID
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="nationalIdentityNumber"
                                autoComplete="off"
                                value={formData.nationalIdentityNumber}
                                onChange={handleInputChange2}
                              />
                            </div>
                          </div>
                          <p className="text-primary text-xs">
                            Please ensure that the information provided matches the details as they appear on your official documents.<br />
                            You will not be able to edit these details.
                            </p>
                          <div className="input-block text-center">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? "Registering..." : "Next Step"}
                            </button>
                          </div>
                        </div>
                      </form>

                      <div className="account-footer">
                        <p>
                          Already have an account? <Link to="/">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Slide}
        />
      </div>
    </>
  );
};

export default Register;

import React, { useState, useContext } from 'react';
import Header from '../../../common/Header.js';
import Sidebar from '../../../common/Sidebar.js';
import {Link} from "react-router-dom";
import { UserContext } from '../../../../InitialPage/App.js';

// Constants
const CONSTANTS = {
  MAX_PENSION_CONTRIBUTION: 20000,
  PERSONAL_RELIEF: 2400,
  MAX_INSURANCE_RELIEF: 5000,
  MAX_NSSF_CONTRIBUTION: 1080,
  // MAX_ALLOWABLE_NONCASH_BENEFIT: 3000,
};

// Helper function to calculate NSSF contribution
function calculateNSSF(salary) {
    if (salary <= 6000) {
        return salary * 0.06;
    } else if (salary <= 18000) {
        return 360 + (salary - 6000) * 0.06;
    } else {
        return CONSTANTS.MAX_NSSF_CONTRIBUTION;
    }
}

// Helper function to calculate housing levy
function calculateHousing(salary) {
    return salary * 0.015;
}

// Helper function to calculate NHIF contribution
function calculateNHIF(salary) {
    if (salary <= 0) {
        return 0;
    }
    
    let brackets = [6000, 8000, 12000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000];
    let contributions = [150, 300, 400, 500, 600, 750, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700];

    for(let i = 0; i < brackets.length; i++){
        if(salary < brackets[i]) return contributions[i];
    }

    return contributions[contributions.length-1];
}

// Helper function to calculate taxable amount
function calculateTaxable(chargeablePay) {
    //if chargeable is less than 24000, tax is 10% of chargeable
    if (chargeablePay <= 24000) {
        return chargeablePay * 0.10;
    }
    //if chargeable is between 24001 and 32333, tax is 10% of 24000 + 25% of the amount above 24000
    else if (chargeablePay > 24000 && chargeablePay <= 32333) {
        return (24000 * 0.10) + ((chargeablePay - 24000) * 0.25);
    }
    //if chargeable is between 32334 and 500000, tax is 10% of 24000 + 25% of 8333 + 30% of the amount above 32333
    else if (chargeablePay > 32333 && chargeablePay <= 500000){
        return (24000 * 0.10) + (8333 * 0.25) + ((chargeablePay - 32333) * 0.30);
    }
    // if chargeable is between 500000 and 800000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of the amount above 500000
    else if (chargeablePay > 500000 && chargeablePay <= 800000){
        return(24000 * 0.10) + (8333 * 0.25) + (467667 * 0.30) +((chargeablePay - 500000) * 0.325);
    }
     // if chargeable is above 500000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of 300000 + 35% of the amount above 800000
    else {
        return(24000 * 0.10) + (8333 * 0.25) + (467667 * 0.30) + (300000 * 0.325 ) +((chargeablePay - 800000) * 0.35);
    }
}

function TaxCalculator() {
  const [inputValues, setInputValues] = useState({
    salary: '',
    benefits: '',
    deductions: '',
    pension: '',
    insurance: '',
  });

  const [results, setResults] = useState({
    grossPay: 0,
    payePayable: 0,
    nssfContribution: 0,
    housingLevy: 0,
    nhifContribution: 0,
    otherInsuranceContributions: 0,
    totalDeductions: 0,
    netPay: 0,
    taxablePay: 0,
    totalDeductionsForPaye: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const calculate = () => {
    const salary = parseFloat(inputValues.salary) || 0;
    const benefits = parseFloat(inputValues.benefits) || 0;
    const deductions = parseFloat(inputValues.deductions) || 0;
    const pension = parseFloat(inputValues.pension) || 0;
    const insurance = parseFloat(inputValues.insurance) || 0;

    // Check for negative values
    if (salary < 0 || benefits < 0 || deductions < 0 || pension < 0 || insurance < 0) {
      alert("Please enter positive values.");
      return;
    }

    let nssfContribution = calculateNSSF(salary);
    let housingLevy = calculateHousing(salary);
    let nhifContribution = calculateNHIF(salary);

    let totalInsuranceContribution = Math.min(nhifContribution + insurance, CONSTANTS.MAX_INSURANCE_RELIEF);
    let insuranceRelief = totalInsuranceContribution * 0.15;
    let taxablePay = salary - Math.min(nssfContribution + deductions + pension, CONSTANTS.MAX_PENSION_CONTRIBUTION);
    let totalPAYE;

    if (benefits > 3000) {
      totalPAYE = calculateTaxable(taxablePay + benefits);
    } else {
      totalPAYE = calculateTaxable(taxablePay);
    }

    totalPAYE -= (insuranceRelief + CONSTANTS.PERSONAL_RELIEF);

    let totalDeductions;
    if (totalPAYE < 0) {
      totalDeductions = Math.max(0, nssfContribution + 
        housingLevy 
        + nhifContribution + insurance);
    } else {
      totalDeductions = Math.max(totalPAYE + nssfContribution + 
        housingLevy 
        + nhifContribution + insurance, 0);
    }

    setResults({
      grossPay: salary.toFixed(2),
      payePayable: (totalPAYE > 0 ? totalPAYE.toFixed(2) : '0.00'),
      nssfContribution: nssfContribution.toFixed(2),
      housingLevy: housingLevy.toFixed(2),
      nhifContribution: nhifContribution.toFixed(2),
      otherInsuranceContributions: insurance.toFixed(2),
      totalDeductions: totalDeductions.toFixed(2),
      netPay: (salary - totalDeductions).toFixed(2),
      taxablePay: (benefits < 3000 ? taxablePay.toFixed(2) : (taxablePay + benefits).toFixed(2)),
      totalDeductionsForPaye: (deductions + nssfContribution + insurance).toFixed(2),
    });
  };

    function handleSubmit(e) {
        e.preventDefault();
    }

    const { userRole } = useContext(UserContext);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

  return (
    <>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <div className="app-container">
                <Header onMenuClick={() => toggleMobileMenu()}/>
                <div className="main-content">
                    <Sidebar/>
                    <div className="page-wrapper">
                        <div className='content container-fluid'>
                            <div className='page-header'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h3 className="page-title">Monthly Tax Calculator</h3>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                            </li>
                                            {/*<li className="breadcrumb-item">*/}
                                            {/*    <Link to="/finance/payroll/employee-salary">Payroll</Link>*/}
                                            {/*</li>*/}
                                            <li className="breadcrumb-item active">Tax Calculator</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-md-12 text-center'>
                                        <h2 className="text-3xl text-center mb-6 font-semibold">Tax Calculator (Monthly)</h2>
                                    </div>
                                </div> */}
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="calculator-card">
                                            <h3 className="text-lg mb-4 font-semibold">Calculate:</h3>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                                htmlFor="salary">Gross Pay</label>
                                                            <input
                                                                className="form-control"
                                                                id="salary"
                                                                type="number"
                                                                name="salary"
                                                                placeholder="Enter gross pay"
                                                                value={inputValues.salary}
                                                                onChange={handleChange}>
                                                            </input>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                                htmlFor="benefits">Non-Cash Benefits(excess of
                                                                3,000)</label>
                                                            <input
                                                                className="form-control"
                                                                id="benefits"
                                                                type="number"
                                                                name="benefits"
                                                                placeholder="Enter non-cash benefits"
                                                                value={inputValues.benefits}
                                                                onChange={handleChange}>
                                                            </input>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                                htmlFor="deductions">Other Tax Allowable
                                                                Deductions</label>
                                                            <input
                                                                className="form-control"
                                                                id="deductions"
                                                                type="number"
                                                                name="deductions"
                                                                placeholder="Enter deductions"
                                                                value={inputValues.deductions}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                                htmlFor="pension">Pension Contributions</label>
                                                            <input
                                                                className="form-control"
                                                                id="pension"
                                                                type="number"
                                                                name="pension"
                                                                placeholder="Enter pension contributions"
                                                                value={inputValues.pension}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                                htmlFor="insurance">Other Insurance
                                                                Contributions</label>
                                                            <input
                                                                className="form-control"
                                                                id="insurance"
                                                                type="number"
                                                                name="insurance"
                                                                placeholder="Enter other insurance contributions"
                                                                value={inputValues.insurance}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn calculate"
                                                                    onClick={calculate} id="calculate">Calculate
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="calculator-card">
                                            <h3 className="text-lg font-semibold mb-4">Results:</h3>
                                            <p>Gross Pay / Salary: <span id="result-gross-pay">{results.grossPay}</span>
                                            </p>
                                            <p>PAYE Payable: <span id="result-paye-payable">{results.payePayable}</span>
                                            </p>
                                            <p>NSSF Contribution: <span
                                                id="result-nssf">{results.nssfContribution}</span></p>
                                            <p>Housing Levy: <span id="result-housing">{results.housingLevy}</span></p>
                                            <p>NHIF Contribution: <span
                                                id="result-nhif">{results.nhifContribution}</span></p>
                                            <p>Other Insurance Contributions: <span
                                                id="result-insurance">{results.otherInsuranceContributions}</span></p>
                                            <p>Total Deductions: <span
                                                id="result-total-deductions">{results.totalDeductions}</span></p>
                                            <p>Net Pay: <span id="result-net-pay">{results.netPay}</span></p>
                                        </div>

                                        <div className="calculator-card mt-3" style={{height: "269px"}}>
                                            <h3 className="text-lg font-semibold mb-4">PAYE Information:</h3>
                                            <p className="mb-4">Gross Pay / Salary: <span
                                                id="paye-gross-pay">{results.grossPay}</span></p>
                                            <p className="mb-4">Allowable Deductions: <span
                                                id="paye-allowable-deductions">{results.totalDeductionsForPaye}</span>
                                            </p>
                                            <p className="mb-4">Taxable Pay: <span
                                                id="paye-taxable-pay">{results.taxablePay}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

// ... (rest of the code remains the same)

export default TaxCalculator;

import React, {useState, useEffect} from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HodResponsibilityForm = () => {
    const [formData, setFormData] = useState({
        responsibility: '',
        employeeName: '',
        assignedDate:'',
        completionDate:'',
    });
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [selectedDate1, setSelectedDate1] = useState(null);

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };

    const [employees, setEmployees] = useState([]);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();
                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && !employeeData.deleted) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const requiredFields =
        [
            'responsibility',
            'employeeName',
            'assignedDate',
            'completionDate',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as a toast
        if (errorMessages.length > 0) {
            const errorMessage = errorMessages.join('\n');
            showToast(errorMessage, 'error');
            return;
        }

        let assignedDateString = null;
        let completionDateString = null;

        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            assignedDateString = `${year}-${month}-${day}`;
        }

        if (selectedDate1) {
            const year = selectedDate1.getFullYear();
            const month = String(selectedDate1.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate1.getDate()).padStart(2, '0');
            completionDateString = `${year}-${month}-${day}`;
        }

        try {
            const docRef = await addDoc(collection(db, 'responsibilities'), {
                ...formData,
                assignedDate: assignedDateString,
                completionDate: completionDateString,
            });
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Responsibility Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };


    return (
        <>
            {/* Add Department Modal */}
            <div
                id="add_responsibility"
                className="modal custom-modal fade"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Responsibility</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>
                                                Responsibility Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="responsibility"
                                                autoComplete="off"
                                                value={formData.responsibility}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>
                                                Assigned Employee <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="employeeName"
                                                value = {formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Assigned Date<span className="text-danger">*</span></label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={date => {
                                                        setSelectedDate(date);
                                                        setFormData({
                                                            ...formData,
                                                            assignedDate: date
                                                        });
                                                    }}
                                                    className="form-control datetimepicker"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Expected Date of Completion</label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate1}
                                                    onChange={date => {
                                                        setSelectedDate1(date);
                                                        setFormData({
                                                            ...formData,
                                                            completionDate: date
                                                        });
                                                    }}
                                                    className="form-control datetimepicker"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Department Modal */}


            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default HodResponsibilityForm;

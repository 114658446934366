import React, {useState, useEffect} from "react";
import { db } from '../../../../firebase.js';
import { collection, addDoc } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Constants
const CONSTANTS = {
    MAX_PENSION_CONTRIBUTION: 20000,
    PERSONAL_RELIEF: 2400,
    MAX_INSURANCE_RELIEF: 5000,
    MAX_NSSF_CONTRIBUTION: 1080,
};

// Helper function to calculate NSSF contribution
function calculateNSSF(salary) {
    if (salary <= 6000) {
        return salary * 0.06;
    } else if (salary <= 18000) {
        return 360 + (salary - 6000) * 0.06;
    } else {
        return CONSTANTS.MAX_NSSF_CONTRIBUTION;
    }
}

// Helper function to calculate housing levy
function calculateHousing(salary) {
    return salary * 0.015;
}

// Helper function to calculate NHIF contribution
function calculateNHIF(salary) {
    if (salary <= 0) {
        return 0;
    }

    let brackets = [6000, 8000, 12000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000];
    let contributions = [150, 300, 400, 500, 600, 750, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700];

    for(let i = 0; i < brackets.length; i++){
        if(salary < brackets[i]) return contributions[i];
    }

    return contributions[contributions.length-1];
}

// Helper function to calculate taxable amount
function calculateTaxable(chargeablePay) {
    //if chargeable is less than 24000, tax is 10% of chargeable
    if (chargeablePay <= 24000) {
        return chargeablePay * 0.10;
    }
    //if chargeable is between 24001 and 32333, tax is 10% of 24000 + 25% of the amount above 24000
    else if (chargeablePay > 24000 && chargeablePay <= 32333) {
        return (24000 * 0.10) + ((chargeablePay - 24000) * 0.25);
    }
    //if chargeable is between 32334 and 500000, tax is 10% of 24000 + 25% of 8333 + 30% of the amount above 32333
    else if (chargeablePay > 32333 && chargeablePay <= 500000){
        return (24000 * 0.10) + (8333 * 0.25) + ((chargeablePay - 32333) * 0.30);
    }
    // if chargeable is between 500000 and 800000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of the amount above 500000
    else if (chargeablePay > 500000 && chargeablePay <= 800000){
        return(24000 * 0.10) + (8333 * 0.25) + (467667 * 0.30) +((chargeablePay - 500000) * 0.325);
    }
     // if chargeable is above 500000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of 300000 + 35% of the amount above 800000
    else {
        return(24000 * 0.10) + (8333 * 0.25) + (467667 * 0.30) + (300000 * 0.325 ) +((chargeablePay - 800000) * 0.35);
    }
}

const PayrollForm = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const dateCreated = new Date();

    const handleDateChange = (dateCreated) => {
        setSelectedDate(dateCreated);
    };

    const [formData, setFormData] = useState({
        month:'',
        currency: '',
        grossSalary:'',
        pensionContributions:'',
        staffNo:'',
        // dateCreated:'',
        frequency: '',
        remark: '',
        description: ''
    });

    const [resultsData, setResultsData] = useState({
        netSalary:0,
        nhifContribution:0,
        nssfContribution:0,
        payePayable:0,
        housingLevy: 0,
        allowableDeductions: 0,
        totalDeductions: 0,
        taxablePay: 0,
        netPay: 0,
    });

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        calculate();
    };

    useEffect(() => {
        // Call calculate() when the component mounts
        calculate();
    }, []);

    const calculate = () => {
        const salary = parseFloat(formData.grossSalary) || 0;
        const benefits = parseFloat(formData.nonCashBenefits) || 0;
        const deductions = parseFloat(formData.otherTaxAllowableDeductions) || 0;
        const pension = parseFloat(formData.pensionContributions) || 0;
        const insurance = parseFloat(formData.otherInsuranceContributions) || 0;

        // Check for negative values
        if (salary < 0 || benefits < 0 || deductions < 0 || pension < 0 || insurance < 0) {
            alert("Please enter positive values.");
            return;
        }

        let nssfContribution = calculateNSSF(salary);
        let housingLevy = calculateHousing(salary);
        let nhifContribution = calculateNHIF(salary);

        let totalInsuranceContribution = Math.min(nhifContribution + insurance, CONSTANTS.MAX_INSURANCE_RELIEF);
        let insuranceRelief = totalInsuranceContribution * 0.15;
        let taxablePay = salary - Math.min(nssfContribution + deductions + pension, CONSTANTS.MAX_PENSION_CONTRIBUTION);
        let totalPAYE;

        if (benefits > 3000) {
            totalPAYE = calculateTaxable(taxablePay + benefits);
        } else {
            totalPAYE = calculateTaxable(taxablePay);
        }

        totalPAYE -= (insuranceRelief + CONSTANTS.PERSONAL_RELIEF);

        let totalDeductions;
        if (totalPAYE < 0) {
            totalDeductions = Math.max(0, nssfContribution + housingLevy + nhifContribution + insurance);
        } else {
            totalDeductions = Math.max(totalPAYE + nssfContribution + housingLevy + nhifContribution + insurance, 0);
        }

        setResultsData({
            payePayable: (totalPAYE > 0 ? totalPAYE.toFixed(2) : '0.00'),
            nssfContribution: nssfContribution.toFixed(2),
            housingLevy: housingLevy.toFixed(2),
            nhifContribution: nhifContribution.toFixed(2),
            otherInsuranceContributions: insurance.toFixed(2),
            totalDeductions: totalDeductions.toFixed(2),
            netPay: (salary - totalDeductions).toFixed(2),
            taxablePay: (benefits < 3000 ? taxablePay.toFixed(2) : (taxablePay + benefits).toFixed(2)),
            allowableDeductions: (deductions + nssfContribution + insurance).toFixed(2),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            // Form is invalid, do not proceed with submission
            showToast('Form cannot be empty!', 'error');
            return;
        }

        let currentDateString = null;

        if (dateCreated) {
            const year = dateCreated.getFullYear();
            const month = String(dateCreated.getMonth() + 1).padStart(2, '0');
            const day = String(dateCreated.getDate()).padStart(2, '0');
            currentDateString = `${year}-${month}-${day}`;
        }

        try {
            // const combinedData = { ...formData, ...resultsData };
            const docRef = await addDoc(collection(db, 'run-payroll'), {
                ...formData,
                ...resultsData,
                dateCreated: currentDateString,
            });
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Salary Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            <div id="add_payroll" className="modal custom-modal fade" role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Payroll</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Month <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="month"
                                                value={formData.month}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select month</option>
                                                <option>January</option>
                                                <option>February</option>
                                                <option>March</option>
                                                <option>April</option>
                                                <option>May</option>
                                                <option>June</option>
                                                <option>July</option>
                                                <option>August</option>
                                                <option>September</option>
                                                <option>October</option>
                                                <option>November</option>
                                                <option>December</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Currency <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="currency"
                                                value={formData.currency}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Currency</option>
                                                <option>
                                                    Kenya Shillings (KSHS)
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Gross Salary <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="grossSalary"
                                                autoComplete="off"
                                                value={formData.grossSalary}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>PAYE <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="payePayable"
                                                value={resultsData.payePayable}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>NSSF <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nssfContribution"
                                                autoComplete="off"
                                                value={resultsData.nssfContribution}
                                                onChange={handleInputChange}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>NHIF <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nhifContribution"
                                                value={resultsData.nhifContribution}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Pension (0 if not applicable)<span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="pensionContributions"
                                                value={formData.pensionContributions}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Total Deductions <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="totalDeductions"
                                                value={resultsData.totalDeductions}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Net Salary <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="netPay"
                                                value={resultsData.netPay}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>No of Staff <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="staffNo"
                                                value={formData.staffNo}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                // required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Date Created <span
                                                className="text-danger">*</span></label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={dateCreated}
                                                    onChange={handleDateChange}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Frequency</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="frequency"
                                                value={formData.frequency}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Remark</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="remark"
                                                value={formData.remark}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                rows={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" type='submit' data-bs-dismiss="modal">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayrollForm;

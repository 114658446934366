import React, {useState} from "react";
import Header from "../common/Header.js";
import SettingsSidebar from "../common/SettingsSidebar.js";
import {Link} from "react-router-dom";
import {Applogo} from "../../Entryfile/imagepath.js";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc } from 'firebase/firestore';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { db } from "../../firebase.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

const auth = getAuth();

const ChangePassword = () => {
    const [menu, setMenu] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            showToast("Passwords do not match", 'error');
            return;
        }

        try {
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(user.email, oldPassword);
            await reauthenticateWithCredential(user, credential);

            await updatePassword(user, newPassword);

            const userDocRef = collection(db, 'users');
            await addDoc(userDocRef, {
                userId: user.uid,
                newPassword: newPassword
            });

            showToast("Password updated successfully!", 'success');

            // Clear input fields
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");

        } catch (error) {
            if (error.code === "auth/wrong-password") {
                showToast("The old password you entered is incorrect", 'error');
            } else {
                showToast(error.message, 'error');
            }
        }
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <SettingsSidebar/>
                        <div className="page-wrapper">
                            <div className="account-page">
                                <div className="main-wrapper">
                                    <div className="account-content">
                                        {/* Account Logo */}
                                        <div className="account-logo">
                                            <Link to="/admin-dashboard">
                                                <img
                                                    src={Applogo}
                                                    alt="Dreamguy's Technologies"
                                                    style={{marginTop: "30px"}}
                                                />
                                            </Link>
                                        </div>
                                        <div className="account-box">
                                            <div className="account-wrapper">
                                                <h3 className="account-title">Change Password</h3>
                                                <form onSubmit={handleChangePassword}>
                                                    <div className="input-block">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label>Old Password</label>
                                                            </div>
                                                        </div>
                                                        <div style={{ position: 'relative' }}>
                                                            <input
                                                                type={showOldPassword ? 'text' : 'password'}
                                                                className="form-control"
                                                                name="oldPassword"
                                                                value={oldPassword}
                                                                onChange={(e) => setOldPassword(e.target.value)}
                                                                required
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={showOldPassword ? faEye : faEyeSlash}
                                                                style={{ position: 'absolute', right: '5%', top: '30%', cursor: 'pointer' }}
                                                                onClick={toggleOldPasswordVisibility}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-block">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label>New Password</label>
                                                            </div>
                                                        </div>
                                                        <div style={{ position: 'relative' }}>
                                                            <input
                                                                type={showNewPassword ? 'text' : 'password'}
                                                                className="form-control"
                                                                name="newPassword"
                                                                value={newPassword}
                                                                onChange={(e) => setNewPassword(e.target.value)}
                                                                required
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={showNewPassword ? faEye : faEyeSlash}
                                                                style={{ position: 'absolute', right: '5%', top: '30%', cursor: 'pointer' }}
                                                                onClick={toggleNewPasswordVisibility}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-block">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label>Confirm Password</label>
                                                            </div>
                                                        </div>
                                                        <div style={{ position: 'relative' }}>
                                                            <input
                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                className="form-control"
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                required
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={showConfirmPassword ? faEye : faEyeSlash}
                                                                style={{ position: 'absolute', right: '5%', top: '30%', cursor: 'pointer' }}
                                                                onClick={toggleConfirmPasswordVisibility}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="submit-section mb-4">
                                                        <button className="btn btn-primary submit-btn">
                                                            Update Password
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default ChangePassword;

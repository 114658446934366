import React, {useState, useEffect, useContext} from 'react';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link, useLocation} from "react-router-dom";
import DatePicker from "react-datepicker";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import LeaveForm from './leaveForm.js';
import BulkLeaveForm from './bulkLeave.js';
import { UserContext } from '../../../InitialPage/App.js';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const LeaveRequests = () => {
    const { userRole } = useContext(UserContext);
    const location = useLocation();

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('all');

    const handleDateChange = (date) => {
        setSelectedDate3(date);
    };

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [firstApproval, setFirstApproval] = useState(false);
    const [secondApproval, setSecondApproval] = useState(false);
    const [approvals, setApprovals] = useState({});
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherLeaveType, setOtherLeaveType] = useState("")

    const [formData, setFormData] = useState({
        leaveCategory:'',
        startDate: '',
        endDate: '',
        noOfDays: 0,
        remainingLeaves: 0,
    });

    const [editLeaveId, setEditLeaveId] = useState(null);
    const [leaveCategories, setLeaveCategories] = useState([]);
    const [leaveHistory, setLeaveHistory] = useState([]);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const getUserRole = () => {
        const pathSegments = location.pathname.split('/');
        return pathSegments[1]; // Assumes the role is the first segment in the route path
      };

      const getStatusColor = (firstStatus, secondStatus) => {
        const status = firstStatus || secondStatus;
        switch (status) {
            case 'approved':
                return 'success';
            case 'pending':
                return 'warning';
            case 'denied':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchLeaveHistory = async () => {
            const querySnapshot = await getDocs(collection(db, 'issue-leave'));
            const leaveHistoryList = [];
            // querySnapshot.forEach((doc) => {
            //     leaveHistoryList.push({ id: doc.id, ...doc.data() });
            // });
            querySnapshot.forEach((doc) => {
                const leaveHistoryData = doc.data();
                
                if (leaveHistoryData.organisationId === userData[0]?.organisationId
                ) {
                    leaveHistoryList.push({ id: doc.id, ...leaveHistoryData });
                }
            });
            setLeaveHistory(leaveHistoryList);
        };

        fetchLeaveHistory();

        const fetchLeaveCategories = async () => {
            const querySnapshot = await getDocs(collection(db, 'leave-categories'));
            const leaveCategoryList = [];

            // querySnapshot.forEach((doc) => {
            //     const leaveCategoryData = doc.data();

            //     if (!leaveCategoryData.deleted) {
            //         leaveCategoryList.push({ id: doc.id, ...leaveCategoryData });
            //     }
            // });
            querySnapshot.forEach((doc) => {
                const leaveCategoryData = doc.data();
                
                // Check if the employee is active and not deleted
                if (!leaveCategoryData.deleted &&
                    leaveCategoryData.organisationId === userData[0]?.organisationId
                ) {
                    leaveCategoryList.push({ id: doc.id, ...leaveCategoryData });
                }
            });

            setLeaveCategories(leaveCategoryList);
        };

        fetchLeaveCategories();

    }, [userData]);

    useEffect(() => {
        // Update remainingLeaves when leaveHistory changes
        if (formData.employeeName && formData.leaveCategory) {
            const employeeLeaveHistory = leaveHistory.filter(leave => leave.employeeName === formData.employeeName && leave.leaveCategory === formData.leaveCategory);
            const usedLeaves = employeeLeaveHistory.reduce((total, leave) => total + leave.noOfDays, 0);
            const leaveCategoryData = leaveCategories.find(category => category.leaveCategory === formData.leaveCategory);

            if (leaveCategoryData) {
                const remainingLeaves = Math.max(0, leaveCategoryData.maxNoOfDays - usedLeaves);
                setFormData(prevData => ({ ...prevData, remainingLeaves }));
            } else {
                console.error(`Leave category data not found for ${formData.leaveCategory}`);
                // Handle the case where leaveCategoryData is undefined
                // For example, set a default value for remainingLeaves or display an error message to the user
            }
        }
    }, [leaveHistory, formData.employeeName, formData.leaveCategory, leaveCategories]);

    useEffect(() => {
        // Update noOfDays when either startDate or endDate changes
        if (formData.startDate && formData.endDate) {
            const businessDays = calculateBusinessDays(formData.startDate, formData.endDate);
            setFormData(prevData => ({ ...prevData, noOfDays: businessDays }));
        }
    }, [formData.startDate, formData.endDate]);

    useEffect(() => {
        // Update remainingLeaves based on selected leaveCategory and leaveHistory
        if (formData.leaveCategory && formData.startDate) {
            const leaveCategoryData = leaveCategories.find(category => category.leaveCategory === formData.leaveCategory);
            if (leaveCategoryData) {
                let remainingLeaves = leaveCategoryData.maxNoOfDays;
                if (formData.leaveCategory === 'Annual leave') {
                    const noOfCurrentMonth = formData.startDate.getMonth() + 1;
                    const totalNoOfMonths = 12;
                    remainingLeaves = (noOfCurrentMonth / totalNoOfMonths) * remainingLeaves;
                }

                // Calculate used leaves based on leave history for the employee
                const usedLeaves = leaveHistory.filter(leave => leave.employeeName === formData.employeeName && leave.leaveCategory === formData.leaveCategory)
                                                .reduce((total, leave) => total + leave.noOfDays, 0);
                remainingLeaves -= usedLeaves;

                setFormData(prevData => ({ ...prevData, remainingLeaves }));
            }
        }
    }, [formData.leaveCategory, formData.startDate, leaveCategories, leaveHistory]);

    useEffect(() => {
        // Update remainingLeaves when noOfDays changes
        const updatedRemainingLeaves = formData.remainingLeaves - formData.noOfDays;
        setFormData(prevData => ({ ...prevData, remainingLeaves: updatedRemainingLeaves }));
    }, [formData.noOfDays]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [oneWeekFromNow, setOneWeekFromNow] = useState(() => {
        const currentDate = new Date();
        const oneWeekFromNowDate = new Date(currentDate);
        oneWeekFromNowDate.setDate(currentDate.getDate() + 7);
        return oneWeekFromNowDate;
    });

    const handleStartDateChange = (date) => {
        if (date < oneWeekFromNow) {
            // Show an alert or any other form of feedback to indicate the error
            showToast('Please select a start date at least one week from today.', 'error');
            return;
        }

        setSelectedDate(date);

        const businessDays = calculateBusinessDays(date, formData.endDate);
        setFormData(prevData => ({ ...prevData, startDate: date, noOfDays: businessDays }));
    };

    const handleEndDateChange = (date) => {
        if (date < formData.startDate) {
            // Show an alert or any other form of feedback to indicate the error
            showToast('End date must be after the start date.', 'error');
            return;
        }

        setSelectedDate2(date);

        const businessDays = calculateBusinessDays(formData.startDate, date);
        setFormData(prevData => ({ ...prevData, endDate: date, noOfDays: businessDays }));
    };

    const calculateBusinessDays = (startDate, endDate) => {
        let currentDate = new Date(startDate);
        const finalDate = new Date(endDate);
        let businessDays = 0;

        while (currentDate <= finalDate) {
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                businessDays++;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return businessDays;
    };


    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            (item.employeeName && item.employeeName.toLowerCase().includes(input.toLowerCase())) ||
            (item.status && item.status.toLowerCase().includes(input.toLowerCase())) ||
            (item.leaveCategory && item.leaveCategory.toLowerCase().includes(input.toLowerCase()))
        );


        setFilteredData(filtered);
        setEntriesCount(filtered.length); // Update entries count for filtered data
    };

    const filterData = () => {
        const statusFilter = (item) => {
            const firstStatus = item.firstStatus || 'pending';
            const secondStatus = item.secondStatus || 'pending';

            if (!selectedStatus) {
                return true;
            }

            const { all, approved, denied, pending, attended } = selectedStatus;
            const isAttended = new Date() > (item.endDate ? item.endDate.toDate() : null);
            if (all) {
                return true;
            } else if (approved) {
                return firstStatus === 'approved' && secondStatus === 'approved';
            } else if (denied) {
                return firstStatus === 'denied' && secondStatus === 'denied';
            } else if (pending) {
                return firstStatus === 'pending' && secondStatus === 'pending';
            } else if (attended){
                return (firstStatus === 'approved' && secondStatus === 'approved') && isAttended;
            } else{
                return true;
            }
        };

        const textFilter = (item) =>
            item.employeeName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.leaveCategory.toLowerCase().includes(searchInput.toLowerCase());

        if (searchInput === '' && !selectedDate) {
            return data.filter(item => !item.deleted && statusFilter(item));
        } else {
            return filteredData.filter(item => !item.deleted && statusFilter(item) && textFilter(item));
        }
    };


    // const handleStatusChange = async (leaveId, newStatus) => {
    //     try {
    //         const role = getUserRole();
    //         const statusMessage = `${role}`;

    //         // Check for the role to determine which approval to update
    //         if (role === 'hod') {
    //             await updateDoc(doc(db, 'issue-leave', leaveId), { firstStatus: newStatus, firstStatusMessage: statusMessage });
    //             setApprovals(prevApprovals => ({ ...prevApprovals, [leaveId]: { firstApproval: { status: newStatus, message: statusMessage } } }));
    //         } else if (role === 'hr') {
    //             await updateDoc(doc(db, 'issue-leave', leaveId), { secondStatus: newStatus, secondStatusMessage: statusMessage });
    //             setApprovals(prevApprovals => ({ ...prevApprovals, [leaveId]: { ...prevApprovals[leaveId], secondApproval: { status: newStatus, message: statusMessage } } }));
    //         }else{
    //             await updateDoc(doc(db, 'issue-leave', leaveId), { thirdStatus: newStatus, thirdStatusMessage: statusMessage });
    //             setApprovals(prevApprovals => ({ ...prevApprovals, [leaveId]: { ...prevApprovals[leaveId], thirdApproval: { status: newStatus, message: statusMessage } } }));
    //         }

    //     } catch (error) {
    //         console.error('Error updating status:', error);
    //     }
    // };

    const handleStatusChange = async (leaveId, newStatus) => {
        // const { userRole } = useContext(UserContext);

        try {
            const user = auth.currentUser;
            let secondStatusMessage = '';

            if (user) {
                // Fetch the user document from the "users" collection
                const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', user.email)));
                if (!userQuerySnapshot.empty) {
                    const userData = userQuerySnapshot.docs[0].data();
                    if (userData.employeeName) {
                        secondStatusMessage = userData.employeeName;
                    } else if (userData.firstName && userData.lastName) {
                        secondStatusMessage = `${userData.firstName} ${userData.lastName}`;
                    } else if (userData.companyName) {
                        secondStatusMessage = userData.companyName;
                    } else {
                        secondStatusMessage = 'Unknown User';
                    }
                } else {
                    secondStatusMessage = 'Unknown User';
                }
            } else {
                secondStatusMessage = 'Anonymous User';
            }

            // Update the status of the timesheet document
            await updateDoc(doc(db, 'issue-leave', leaveId), { secondStatus: newStatus, secondStatusMessage });

            // Update the local data state with the updated status and role signature
            const updatedData = data.map(item => {
                if (item.id === itemId) {
                    return { ...item, secondStatus: newStatus, secondStatusMessage };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleDeleteLeave = async (leaveId) => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'issue-leave', editLeaveId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editLeaveId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting leave:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the leave data in the database
            await updateDoc(doc(db, 'issue-leave', editLeaveId), formData);

            // Clear the form data and reset the editLeaveId state
            setFormData({
                employeeName:'',
                leaveCategory:'',
                startDate: '',
                endDate: '',
                noOfDays: 0,
                remainingLeaves: 0,
            });
            setEditLeaveId(null);
        } catch (error) {
            console.error('Error updating leave:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                const colRef = collection(db, 'issue-leave');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);

                // Initialize approvals state
                const initialApprovals = dataArray.reduce((acc, item) => {
                    acc[item.id] = {
                        firstApproval: { status: item.firstStatus || 'pending', message: item.firstStatusMessage || '' },
                        secondApproval: { status: item.secondStatus || 'pending', message: item.secondStatusMessage || '' },
                    };
                    return acc;
                }, {});
                setApprovals(initialApprovals);

                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editLeaveId) {
                    const selectedLeave = dataArray.find(item => item.id === editLeaveId);
                    setFormData(selectedLeave);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, entriesPerPage, editLeaveId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Leaves</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hr/leave-management/leave-requests">Leave
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Leave Requests</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="card tab-box">
                                    <div className="row user-tabs">
                                        <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                                            <ul className="nav nav-tabs nav-tabs-bottom">
                                                <li className="nav-item">
                                                    <Link
                                                        to="#all_leaves"
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${selectedStatus && selectedStatus.all ? 'active' : ''}`}
                                                        onClick={() => setSelectedStatus({
                                                            all: true,
                                                            approved: false,
                                                            denied: false,
                                                            pending: false
                                                        })}>
                                                        Submitted
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#approved_leaves"
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${selectedStatus && selectedStatus.approved ? 'active' : ''}`}
                                                        onClick={() => setSelectedStatus({
                                                            all: false,
                                                            approved: true,
                                                            denied: false,
                                                            pending: false
                                                        })}>
                                                        Approved
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#disapproved_leaves"
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${selectedStatus && selectedStatus.denied ? 'active' : ''}`}
                                                        onClick={() => setSelectedStatus({
                                                            all: false,
                                                            approved: false,
                                                            denied: true,
                                                            pending: false
                                                        })}>
                                                        Denied
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#pending_leaves"
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${selectedStatus && selectedStatus.pending ? 'active' : ''}`}
                                                        onClick={() => setSelectedStatus({
                                                            all: false,
                                                            approved: false,
                                                            denied: false,
                                                            pending: true
                                                        })}>
                                                        Pending
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="#attended_leaves"
                                                        data-bs-toggle="tab"
                                                        className="nav-link"
                                                        onClick={() => setSelectedStatus({attended: true})}>
                                                        Attended
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-block">
                                        <div className="cal-icon">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                // value={formData.dateOfBirth}
                                                className="form-control datetimepicker"
                                                type="date"
                                                placeholderText="Filter by date"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-auto float-end ms-auto">
                                        <Link
                                            to="#"
                                            className="btn btn-custom"
                                            data-bs-toggle="modal"
                                            data-bs-target="#add_leave">
                                            Issue Leave
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn btn-custom"
                                            data-bs-toggle="modal"
                                            data-bs-target="#bulk_leave">
                                            Schedule Bulk Leave
                                        </Link>
                                        <Link
                                            to="/hr/leave-management/leave-report"
                                            className="btn btn-custom2">
                                            Leave Report
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        <th>Leave Category</th>
                                                        <th>From</th>
                                                        <th>To</th>
                                                        <th>First Approval</th>
                                                        <th>First Reliever</th>
                                                        <th>Second Approval</th>
                                                        <th>Second Reliever</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.employeeName || "All"}</td>
                                                            <td>{item.leaveCategory}</td>
                                                            <td>{item.startDate ? new Date(item.startDate.seconds * 1000).toLocaleDateString() : ''}</td>
                                                            <td>{item.endDate ? new Date(item.endDate.seconds * 1000).toLocaleDateString() : ''}</td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${getStatusColor(approvals[item.id]?.firstApproval?.status || 'pending')}`}></i>
                                                                        {approvals[item.id]?.firstApproval?.status === 'approved' ? 'Approved' : approvals[item.id]?.firstApproval?.status === 'denied' ? 'Denied' : 'Pending'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item">No access</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="signature-cell">{item.firstStatusMessage || item.thirdStatusMessage}</td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${getStatusColor(approvals[item.id]?.secondApproval?.status || 'pending')}`}></i>
                                                                        {approvals[item.id]?.secondApproval?.status === 'approved' ? 'Approved' : approvals[item.id]?.secondApproval?.status === 'denied' ? 'Denied' : 'Pending'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'approved')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Approve
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'pending')}>
                                                                            <i className="fa-regular fa-circle-dot text-warning me-1"></i> Pending
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'denied')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deny
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="signature-cell">{item.secondStatusMessage || item.thirdStatusMessage}</td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="action-icon dropdown-toggle"
                                                                       data-bs-toggle="dropdown"
                                                                       aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#edit_hrleave"
                                                                           onClick={() => setEditLeaveId(item.id)}><i
                                                                            className="fa-solid fa-pencil m-r-5"></i>
                                                                            Edit</a>
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#delete_hrleave"
                                                                           onClick={() => setEditLeaveId(item.id)}><i
                                                                            className="fa-regular fa-trash-can m-r-5"></i>
                                                                            Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>

                                        <BulkLeaveForm/>
                                        <LeaveForm/>

                                        {/* Edit Leave Modal */}
                                        <div id="edit_hrleave"
                                             className="modal custom-modal fade"
                                             role="dialog"
                                             data-bs-backdrop="static"
                                             data-bs-keyboard="false"
                                        >
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Edit Leave</h5>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form onSubmit={handleSubmit} noValidate>
                                                            <div className="input-block">
                                                                <label>
                                                                    Leave Category <span className="text-danger">*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select form-control"
                                                                    name="leaveCategory"
                                                                    value = {formData.leaveCategory}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                >
                                                                    <option value="">Select category</option>
                                                                    {leaveCategories.map(leaveCategory => (
                                                                        <option>
                                                                            {leaveCategory.leaveCategory}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="input-block">
                                                            <label>
                                                                From <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="cal-icon">
                                                            <DatePicker
                                                                className="form-control datetimepicker"
                                                                selected={selectedDate}
                                                                onChange={handleStartDateChange}
                                                                minDate={oneWeekFromNow}
                                                                value={formData.startDate}
                                                                required
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="input-block">
                                                            <label>
                                                                To <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="cal-icon">
                                                            <DatePicker
                                                                className="form-control datetimepicker"
                                                                selected={selectedDate2}
                                                                onChange={handleEndDateChange}
                                                                minDate={selectedDate}
                                                                value={formData.endDate}
                                                                required
                                                            />
                                                            </div>
                                                        </div>
                                                        <div className="input-block">
                                                            <label>
                                                                Number of days
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="noOfDays"
                                                                value={formData.noOfDays}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="input-block">
                                                            <label>
                                                                Remaining Leaves
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                readOnly
                                                                name="remainingLeaves"
                                                                value={formData.remainingLeaves}
                                                                type="text"
                                                            />
                                                        </div>
                                                            <div className="submit-section">
                                                                <button className="btn btn-primary submit-btn"
                                                                        data-bs-dismiss="modal" type="submit">Update
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /Edit Leave Modal */}

                                        {/* Delete Leave Modal */}
                                        <div className="modal custom-modal fade"
                                             id="delete_hrleave"
                                             role="dialog"
                                             data-bs-backdrop="static"
                                             data-bs-keyboard="false"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <div className="form-header">
                                                            <h3>Delete Leave</h3>
                                                            <p>Are you sure want to delete this leave?</p>
                                                        </div>
                                                        <div className="modal-btn delete-action">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <a href="" className="btn btn-primary continue-btn"
                                                                       onClick={handleDeleteLeave}
                                                                       data-bs-dismiss="modal">Delete</a>
                                                                </div>
                                                                <div className="col-6">
                                                                    <a href="" data-bs-dismiss="modal"
                                                                       className="btn btn-primary cancel-btn">Cancel</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /Delete Leave Modal */}
                                    </div>
                                </div>

                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaveRequests;

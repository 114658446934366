import React, { useState, useEffect, useContext } from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, updateDoc, doc,  getDocs } from 'firebase/firestore';
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import { UserContext } from "../../../InitialPage/App.js";

const HodAttendance = () => {
    const { userRole } = useContext(UserContext);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [punchInTime, setPunchInTime] = useState(null);
    const [punchOutTime, setPunchOutTime] = useState(null);
    const [calculatedHours, setCalculatedHours] = useState(null);
    const [currentDocument, setCurrentDocument] = useState(null);
    const [attendanceData, setAttendanceData] = useState([]);
    const [totalHours, setTotalHours] = useState(0);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const createNewDocument = async () => {
        const newDocRef = await addDoc(collection(db, 'employee-attendance'), {
            punchIn: null,
            punchOut: null,
            hours: null,
        });
        setCurrentDocument(newDocRef.id);
        return newDocRef.id;
    };

    const handlePunchIn = async () => {
        const currentTime = new Date();
        setPunchInTime(currentTime);

        // Create a new document if there's no current document
        if (!currentDocument) {
            const newDocumentId = await createNewDocument();
            setCurrentDocument(newDocumentId);
        }

        // Update the current document with punchIn time if currentDocument is not null
        if (currentDocument) {
            await updateDoc(doc(db, 'employee-attendance', currentDocument), {
                punchIn: currentTime,
            });
        }
    };


    const handlePunchOut = async () => {
        const currentTime = new Date();
        setPunchOutTime(currentTime);

        // Store punch-out time in Firestore
        await updateDoc(doc(db, 'employee-attendance', currentDocument), {
            punchOut: currentTime,
        });

        // Calculate hours in between and update the document
        if (punchInTime) {
            const hoursBetween = (currentTime - punchInTime) / (1000 * 60 * 60);
            const roundedHours = hoursBetween.toFixed(2); // Round to two decimal places

            // Update the state with calculated hours
            setCalculatedHours(roundedHours);

            // Update the current document with calculated hours
            await updateDoc(doc(db, 'employee-attendance', currentDocument), {
                hours: roundedHours,
            });
        }
    };

    const calculateTodayTotalHours = () => {
        const currentDate = new Date().toDateString();
        const todayEntries = attendanceData.filter(
            attendance => attendance.punchIn && attendance.punchIn.toDate().toDateString() === currentDate
        );

        const totalHours = todayEntries.reduce((total, entry) => {
            if (entry.punchOut) {
                const hoursWorked = (entry.punchOut.toDate() - entry.punchIn.toDate()) / (1000 * 60 * 60);
                return total + hoursWorked;
            }
            return total;
        }, 0);

        return totalHours.toFixed(2);
    };


    useEffect(() => {
        // Fetch data from Firestore when the component mounts
        const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "employee-attendance"));
            const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setAttendanceData(data);

            // Calculate total hours when data is fetched
            const total = data.reduce((acc, attendance) => acc + parseFloat(attendance.hours || 0), 0);
            setTotalHours(total.toFixed(2)); // Round to two decimal places
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
        };
        fetchData();
      }, []);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="page-title">Attendance</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Attendance</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card punch-status">
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    Timesheet{" "}
                                                    <small
                                                        className="text-muted">{new Date().toLocaleDateString()}</small>
                                                </h5>
                                                <div className="punch-btn-section">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary punch-btn"
                                                        onClick={handlePunchIn}
                                                    >
                                                        Punch In
                                                    </button>
                                                </div>
                                                {calculatedHours && (
                                                    <div className="punch-info">
                                                        <div className="punch-hours">
                                                            <span>{calculatedHours} hrs</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="punch-btn-section">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary punch-btn"
                                                        onClick={handlePunchOut}
                                                    >
                                                        Punch Out
                                                    </button>
                                                </div>
                                                <div className="statistics">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6 text-center">
                                                            <div className="stats-box">
                                                                <p>Break</p>
                                                                <h6>***</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-6 text-center">
                                                            <div className="stats-box">
                                                                <p>Overtime</p>
                                                                <h6>***</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card recent-activity">
                                            <div className="card-body">
                                                <h5 className="card-title">Today's Activity</h5>
                                                <h5>{new Date().toLocaleDateString()}</h5>
                                                <ul className="res-activity-list">
                                                    {attendanceData
                                                        .filter(attendance => {
                                                            const currentDate = new Date().toLocaleDateString();
                                                            const punchInDate = attendance.punchIn && attendance.punchIn.toDate().toLocaleDateString();
                                                            return punchInDate === currentDate;
                                                        })
                                                        .map((attendance, index) => (
                                                            <React.Fragment key={index}>
                                                                <li>
                                                                    <p className="mb-0">Punch In at</p>
                                                                    <p className="res-activity-time">
                                                                        <i className="fa fa-clock-o"/>
                                                                        {attendance.punchIn && attendance.punchIn.toDate().toLocaleTimeString()}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="mb-0">Punch Out at</p>
                                                                    <p className="res-activity-time">
                                                                        <i className="fa fa-clock-o"/>
                                                                        {attendance.punchOut && attendance.punchOut.toDate().toLocaleTimeString()}
                                                                    </p>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                </ul>
                                                <h5>Total Hours Worked: {calculateTodayTotalHours()} hrs</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card recent-activity">
                                            <div className="card-body">
                                                <h5 className="card-title">Monthly Activity</h5>
                                                <h5>{new Date().toLocaleDateString('en-KE', {month: 'long'})}</h5>
                                                <ul className="res-activity-list">
                                                    {attendanceData
                                                        .filter(attendance => {
                                                            const currentDate = new Date();
                                                            const punchInDate = attendance.punchIn && attendance.punchIn.toDate();
                                                            return punchInDate && punchInDate.getMonth() === currentDate.getMonth();
                                                        })
                                                        .map((attendance, index) => (
                                                            <React.Fragment key={index}>
                                                                <li>
                                                                    <p className="mb-0">Punch In at</p>
                                                                    <p className="res-activity-time">
                                                                        <i className="fa fa-clock-o"/>
                                                                        {attendance.punchIn && attendance.punchIn.toDate().toLocaleTimeString()}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <p className="mb-0">Punch Out at</p>
                                                                    <p className="res-activity-time">
                                                                        <i className="fa fa-clock-o"/>
                                                                        {attendance.punchOut && attendance.punchOut.toDate().toLocaleTimeString()}
                                                                    </p>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                </ul>
                                                <h5>Total Hours Worked: {totalHours} hrs</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Search Filter */}
                                <div className="row filter-row">
                                    <div className="col-sm-12">
                                        <div className="input-block form-focus select-focus">
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    type="date"
                                                    className="form-control floating datetimepicker"
                                                />
                                            </div>
                                            <label className="focus-label">Date</label>
                                        </div>
                                    </div>
                                </div>
                                {/* /Search Filter */}

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped custom-table mb-0">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>Punch In</th>
                                                    <th>Punch Out</th>
                                                    <th>Hours Worked</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {attendanceData.map((attendance, index) => (
                                                    <tr key={attendance.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{attendance.punchIn && attendance.punchIn.toDate().toLocaleDateString()}</td>
                                                        <td>{attendance.punchIn && attendance.punchIn.toDate().toLocaleTimeString()}</td>
                                                        <td>{attendance.punchOut && attendance.punchOut.toDate().toLocaleTimeString()}</td>
                                                        <td>{attendance.hours}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HodAttendance;

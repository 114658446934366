import React, { useContext, useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { Link, useParams } from 'react-router-dom';
import { UserContext } from "../../InitialPage/App";
import { Avatar_02 } from "../../Entryfile/imagepath";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const CompanyProfile = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [companyData, setCompanyData] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const { ids } = useParams();

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (ids) {
                const docRef = doc(db, 'add-company', ids);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const companyData = docSnap.data();
                    setCompanyData(companyData);
                    fetchEmployees(companyData.organisationId);
                } else {
                    console.error("No such document!");
                }
                setLoading(false);
            }
        };

        const fetchEmployees = async (organisationId) => {
            const q = query(collection(db, "add-employee"), where("organisationId", "==", organisationId));
            const querySnapshot = await getDocs(q);

            const employeesArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setEmployees(employeesArray);
        };

        fetchCompanyData();
    }, [ids]);

    if (loading) {
        return (
            <div className="text-center">
                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (!companyData) {
        return <div>No company data found</div>;
    }

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar />
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h3 className="page-title">Company Profile</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/companies/companies-list">Companies</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Company Profile</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="profile-view">
                                                    <div className="company-profile-img-wrap">
                                                        <div className="profile-img">
                                                            <Link to="#">
                                                                <img alt="img" src={companyData.logoURL || Avatar_02} />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="profile-basic">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="profile-info-left">
                                                                    <h3 className="user-name m-t-0 mb-0">
                                                                        {companyData.displayName || companyData.tradingName || 'Name not provided'}
                                                                    </h3>
                                                                    <div className="text-muted">
                                                                        {companyData.businessType || 'Business type not provided'}
                                                                    </div>
                                                                    <div className="text-muted">
                                                                        {companyData.companyName || companyData.legalName || 'Name not provided'}
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <ul className="personal-info">
                                                                    <li>
                                                                        <div className="title">Company number:</div>
                                                                        <div className="text">
                                                                            {companyData.companyNumber || 'Not provided'}
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="title">Phone:</div>
                                                                        <div className="text">
                                                                            <Link to="#">{companyData.phone || 'Not provided'}</Link>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="title">Email:</div>
                                                                        <div className="text">
                                                                            <Link to="#">{companyData.email || 'Not provided'}</Link>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="title">Address:</div>
                                                                        <div className="text">
                                                                            {companyData.city || companyData.address?.city}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div id="emp_profile" className="pro-overview tab-pane fade show active">
                                        <div className="row">
                                            <div className="col-md-12 d-flex">
                                                <div className="card profile-box flex-fill">
                                                    <div className="card-body">
                                                        <h3 className="card-title">Employees</h3>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Phone</th>
                                                                        <th>Position</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {employees.length > 0 ? (
                                                                        employees.map(employee => (
                                                                            <tr key={employee.id}>
                                                                                <td>{employee.employeeName}</td>
                                                                                <td>{employee.email}</td>
                                                                                <td>{employee.phone}</td>
                                                                                <td>{employee.role}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="4" className="text-center">No employees found</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyProfile;

import React, {useState, useEffect, useContext} from "react";
import { getAuth } from "firebase/auth";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import Header from "../../common/Header.js";
import Select from "react-select";
import Sidebar from "../../common/Sidebar.js";
import {Link, NavLink} from "react-router-dom";
import {Avatar_02} from "../../../Entryfile/imagepath.js";
import HodTeamForm from "./hodTeamForm.js";
import { UserContext } from "../../../InitialPage/App.js";
const auth = getAuth();

const HodTeamCards = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [count, setCount] = useState(1);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [formData, setFormData] = useState({
        teamName: '',
        departmentName: '',
        departmentHead: '',
        employeeName: [],
    });
    const [editTeamId, setEditTeamId] = useState(null);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];
            querySnapshot.forEach((doc) => {
                const departmentData = doc.data();
                // Check if the employee is active and not deleted
                if (!departmentData.deleted && departmentData.organisationId === userData[0]?.organisationId) {
                    departmentList.push({ id: doc.id, ...departmentData });
                }
            });
            setDepartments(departmentList);
        };
        fetchDepartments();
    }, [userData]);

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.teamName.toLowerCase().includes(input.toLowerCase()) ||
            item.departmentName.toLowerCase().includes(input.toLowerCase()) ||
            item.departmentHead.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (teamId, newStatus) => {
        try {
            await updateDoc(doc(db, 'teams', teamId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === teamId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);

            const updatedFilteredData = filteredData.map(item => {
                if (item.id === teamId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setFilteredData(updatedFilteredData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the team data in the database
            await updateDoc(doc(db, 'teams', editTeamId), formData);

            // Clear the form data and reset the editTeamId state
            setFormData({
                teamName: '',
                departmentName: '',
                departmentHead: '',
                employeeName: [],
            });
            setEditTeamId(null);
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const handleDeleteTeam = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'teams', editTeamId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editTeamId ? { ...item, deleted: true } : item
            ));

            setFilteredData(filteredData => filteredData.map(item =>
                item.id === editTeamId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'teams');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'teams');
                    
                let querySnapshot;

                if (userData && userData[0]?.role === 'hod') {
                    querySnapshot = await getDocs(query(colRef, where('departmentHead', '==', userData[0]?.employeeName)));
                } else if (userData && userData[0]?.role !== 'sys-admin'){
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                }else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editTeamId) {
                    const selectedTeam = dataArray.find(item => item.id === editTeamId);
                    setFormData(selectedTeam);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, editTeamId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Teams</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/departments/department-list">Departments</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Teams</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_team">
                                                <i className="fa fa-plus"/> Add Team
                                            </Link>
                                            <div className="view-icons">
                                                <NavLink
                                                    to="/hod/departments/teams-cards"
                                                    className="grid-view btn btn-link mx-2"
                                                    activeClassName="active"
                                                >
                                                    <i className="fa fa-th"/>
                                                </NavLink>
                                                <NavLink
                                                    to="/hod/departments/teams-list"
                                                    className="list-view btn btn-link"
                                                    activeClassName="active"
                                                >
                                                    <i className="fa fa-bars"/>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-12">
                                        <div
                                            className={
                                                focused1
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused1(true)}
                                                onBlur={() => setFocused1(false)}
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                            <label className="focus-label">Search:</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row staff-grid-row">
                                    {filterData().map(item => (
                                        <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                                            <div className="profile-widget">
                                                <div className="profile-img">
                                                    <Link to="#" className="avatar">
                                                        <img src={Avatar_02} alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="dropdown profile-action">
                                                    <Link
                                                        to="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <i className="material-icons">more_vert</i>
                                                    </Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_team"
                                                            onClick={() => setEditTeamId(item.id)}
                                                        >
                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                        </Link>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_team"
                                                            onClick={() => setEditTeamId(item.id)}
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5"/> Delete
                                                        </Link>
                                                    </div>
                                                </div>
                                                <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                                    <Link to="#">
                                                        {item.teamName}
                                                    </Link>
                                                </h4>
                                                <div className="small text-muted">{item.departmentName}</div>
                                                <div className="dropdown action-label mt-2">
                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : 'danger'}`}></i>
                                                        {item.status === 'active' ? 'Active' : 'Inactive'}
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(item.id, 'active')}>
                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                        </a>
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <HodTeamForm/>

                            {/* Edit Team Modal */}
                            <div
                                id="edit_team"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Team</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Team Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="teamName"
                                                                autoComplete="off"
                                                                value={formData.teamName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Department Name <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="departmentName"
                                                                value={formData.departmentName}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Select a department</option>
                                                                {departments.map(department => (
                                                                    <option>
                                                                        {department.departmentName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Department Head <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="departmentHead"
                                                                autoComplete="off"
                                                                value={formData.departmentHead}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Select Employees</label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                value={formData.employeeName.map(selected => ({
                                                                    value: selected,
                                                                    label: selected
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setFormData({
                                                                        ...formData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Team Modal */}

                            {/* Delete Team Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_team"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Team</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <a href="" className="btn btn-primary continue-btn"
                                                           onClick={handleDeleteTeam} data-bs-dismiss="modal">
                                                            Delete
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a
                                                            href=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Team Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HodTeamCards;

import React, { useState, useEffect } from "react";
import { db } from '../../firebase.js';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import {TimePicker} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import Select from "react-select";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const auth = getAuth();

const EmployeeShiftSchedulingForm = () => {
    const [focused, setFocused] = useState(false);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);
    const [selectedDate4, setSelectedDate4] = useState(null);

    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        employeeName: '',
        departmentName: '',
        qualification: '',
        shiftType: '',
        workDays: [],
        offDays: [],
        startTime: '00:00:00',
        endTime: '00:00:00',
        breakHours: '',
        workHoursPerDay: 0,
        workHoursPerWeek: 0,
        organisationId: 0,
        customerId: 0,
    });

    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };
    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };
    const handleDateChange4 = (date) => {
        setSelectedDate4(date);
    };

    const [selectedDate1, setSelectedDate1] = useState(null);
    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };

    dayjs.extend(customParseFormat);
    const onChange = (time, timeString) => {
        console.log(time, timeString);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const options = [
        { value: 'Sunday', label: 'Sunday' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' }
    ]

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);

    useEffect(() => {
        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];
            querySnapshot.forEach((doc) => {
                const departmentData = doc.data();
                // departmentList.push({ id: doc.id, ...doc.data() });
                if (!departmentData.deleted &&
                    departmentData.organisationId === userData[0]?.organisationId
                ) {
                    departmentList.push({ id: doc.id, ...departmentData });
                }
            });
            setDepartments(departmentList);
        };
        fetchDepartments();
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'employeeName') {
            const selectedEmployee = employees.find(employee => employee.employeeName === value);
            if (selectedEmployee) {
                setFormData({
                    ...formData,
                    [name]: value,
                    qualification: selectedEmployee.qualification || '',
                    departmentName: selectedEmployee.departmentName || '',
                    customerId: selectedEmployee.customerId || 0, // Set customerId from the selected employee
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    
    const handleOptionsChange = (selectedOptions, actionMeta) => {
        const updatedFormData = { ...formData };

        if (actionMeta.name === 'workDays') {
            updatedFormData.workDays = selectedOptions;
        } else if (actionMeta.name === 'offDays') {
            updatedFormData.offDays = selectedOptions;
        }

        setFormData(updatedFormData);
    };

    const onChangeStartTime = (time, timeString) => {
        setFormData({
            ...formData,
            startTime: timeString
        });
    };

    const onChangeEndTime = (time, timeString) => {
        setFormData({
            ...formData,
            endTime: timeString
        });
    };

    const calculateWorkHours = () => {
        const { workDays, breakHours, startTime, endTime } = formData;

        const breakTime = parseFloat(breakHours) || 0;
        const start = dayjs(startTime, "HH:mm:ss");
        const end = dayjs(endTime, "HH:mm:ss");

        const totalHours = end.diff(start, 'hours', true);
        const workHoursPerDay = totalHours - breakTime;

        const totalWorkDays = workDays.length;
        const workHoursPerWeek = workHoursPerDay * totalWorkDays;

        setFormData({
            ...formData,
            workHoursPerDay: workHoursPerDay.toFixed(2),
            workHoursPerWeek: workHoursPerWeek.toFixed(2),
        });
    };

    useEffect(() => {
        calculateWorkHours();
    }, [formData.workDays, formData.breakHours, formData.startTime, formData.endTime]);

    const resetForm = () => {
        setFormData({
            employeeName: '',
            departmentName: '',
            qualification: '',
            shiftType: '',
            workDays: [],
            offDays: [],
            startTime: '00:00:00',
            endTime: '00:00:00',
            breakHours: '',
            workHoursPerDay: 0,
            workHoursPerWeek: 0,
        });
    };

    const requiredFields =
        [
            'employeeName',
            'shiftType',
            'workDays',
            'offDays',
            'startTime',
            'endTime',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value =>
            (Array.isArray(value) ? value.length === 0 : !value)
        );

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty or using default value
            if (requiredFields.includes(key) && ((Array.isArray(value) && value.length === 0) || value === '00:00:00')) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // Check if there are any missing-required fields
        requiredFields.forEach(field => {
            if (!(field in formData) || !formData[field]) {
                errorMessages.push(`${field} cannot be empty`);
            }
        });

        // If there are errors, display them as a toast
        if (errorMessages.length > 0) {
            const errorMessage = errorMessages.join('\n');
            showToast(errorMessage, 'error');
            return;
        }

        try {
            const workDaysValues = formData.workDays.map(day => day.value);
            const offDaysValues = formData.offDays.map(day => day.value);

            // const docRef = await addDoc(collection(db, 'shifts'), {
            //     ...formData,
            //     workDays: workDaysValues,
            //     offDays: offDaysValues,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'shifts'), {
                    ...formData,
                    workDays: workDaysValues,
                    offDays: offDaysValues,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'shifts'), {
                    ...formData,
                    workDays: workDaysValues,
                    offDays: offDaysValues,
                });
            }
            console.log("Document written with ID: ", docRef.id);

            showToast('Shift Added Successfully', 'success');
            resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };


    return (
        <>
            {/* Assign Shift Modal */}
            <div id="add_schedule"
                 className="modal custom-modal fade"
                 role="dialog"
                 data-bs-backdrop="static"
                 data-bs-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Assign Shift</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Employee Name <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select form-control"
                                                name="employeeName"
                                                value = {formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Department
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="departmentName"
                                                value={formData.departmentName}
                                                onChange={handleInputChange}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='input-block'>
                                            <label>Role</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="qualification"
                                                    value={formData.qualification}
                                                    onChange={handleInputChange}
                                                    readOnly
                                                />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='input-block'>
                                            <label>Shift Type <span className="text-danger">*</span></label>

                                            <select
                                                className="form-control form-select"
                                                name='shiftType'
                                                value={formData.shiftType}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value>Select Shift Type</option>
                                                <option value="Regular Shift">Regular Shift</option>
                                                <option value="Overtime Shift">Overtime Shift</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='input-block'>
                                            <label>Work Days <span className="text-danger">*</span></label>
                                            <Select
                                                isMulti
                                                name="workDays"
                                                options={options.filter(option => !formData.offDays.map(opt => opt.value).includes(option.value))}
                                                value={formData.workDays}
                                                onChange={handleOptionsChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='input-block'>
                                            <label>Off Days <span className="text-danger">*</span></label>
                                            <Select
                                                isMulti
                                                name="offDays"
                                                options={options.filter(option => !formData.workDays.map(opt => opt.value).includes(option.value))}
                                                onChange={handleOptionsChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Start Time <span className="text-danger">*</span>
                                            </label>
                                            <div>
                                                <TimePicker
                                                    className="input-group-text"
                                                    variant="borderless"
                                                    name="startTime"
                                                    onChange={onChangeStartTime}
                                                    defaultValue={dayjs(formData.startTime, "HH:mm:ss")}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                End Time <span className="text-danger">*</span>
                                            </label>
                                            <TimePicker
                                                className="input-group-text"
                                                variant="borderless"
                                                name="endTime"
                                                onChange={onChangeEndTime}
                                                defaultValue={dayjs(formData.endTime, "HH:mm:ss")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Break Hours / Day
                                            </label>
                                            <input
                                                type='number'
                                                name='breakHours'
                                                autoComplete="off"
                                                className="form-control"
                                                value={formData.breakHours}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Work Hours / Day
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="workHoursDay"
                                                value={formData.workHoursPerDay}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>
                                                Work Hours / Week
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="workHoursWeek"
                                                value={formData.workHoursPerWeek}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Assign Shift Modal */}


            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default EmployeeShiftSchedulingForm;

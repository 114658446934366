import React, {useState, useEffect} from "react";
import { db } from '../../firebase.js';
import { collection, addDoc, query, where, getDocs  } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const EmployeeHolidayForm = () => {
    const [formData, setFormData] = useState({
        holidayName: '',
        holidayDate: '',
        organisationId: 0,
    });
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };


    const resetForm = () => {
        setFormData({
            companyName: '',
            holidayName: '',
            holidayDate: '',
        });
        setSelectedDate(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        // Check if the field is the holidayName field
        if (name === 'holidayName') {
            // If it is the holidayName field, check for invalid characters
            if (invalidPattern.test(value)) {
                // If it contains invalid characters, don't update the state and show an error message
                showToast('Invalid input: Special characters and symbols are not allowed in holiday name', 'error');
                return;
            }
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'companyName') {
            // If it's the companyName field, update the state with the selected companyName and its corresponding organisationId
            const selectedCompany = companies.find(company => company.companyName === value);
            if (selectedCompany) {
                setFormData({
                    ...formData,
                    [name]: value,
                    organisationId: selectedCompany.organisationId, // Update organisationId based on the selected company
                });
            }
        } else {
            // Update the state for other fields
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const requiredFields =
        [
            'holidayName',
            'date'
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as a toast
        if (errorMessages.length > 0) {
            const errorMessage = errorMessages.join('\n');
            showToast(errorMessage, 'error');
            return;
        }

        let holidayDateString = null;
        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            holidayDateString = `${year}-${month}-${day}`;
        }

        try {
            // const docRef = await addDoc(collection(db, 'holidays'), {
            //     ...formData,
                // holidayDate: holidayDateString,
                // organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'holidays'), {
                    ...formData,
                    holidayDate: holidayDateString,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'holidays'), {
                    ...formData,
                    holidayDate: holidayDateString,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Holiday Added Successfully', 'success');
            resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const currentYear = new Date().getFullYear();
    return (
        <>
            {/* Add Holiday Modal */}
            <div
                className="modal custom-modal fade"
                id="add_holiday"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Holiday</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select a company</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                                <div className="input-block">
                                    <label>
                                        Holiday Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="holidayName"
                                        autoComplete="off"
                                        value={formData.holidayName}
                                        onChange={handleInputChange}
                                        required
                                     />
                                </div>
                                <div className="input-block">
                                    <label>
                                        Holiday Date <span className="text-danger">*</span>
                                    </label>
                                    <div className="cal-icon">
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            className="form-control datetimepicker"
                                            name="date"
                                            required
                                            autoComplete = "off"
                                            minDate={new Date(currentYear, 0, 1)}
                                        />
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Holiday Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default EmployeeHolidayForm;

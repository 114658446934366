import React, {useState, useEffect, useContext} from "react";
import { db } from '../../../firebase.js';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Header from "../../common/Header.js";
import Sidebar from "../../common/Sidebar.js";
import {Link} from "react-router-dom";
import HodDepartmentForm from "./hodDepartmentForm.js";
import Select from "react-select";
import { UserContext } from "../../../InitialPage/App.js";
const auth = getAuth();

const HodDepartments = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(1);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        departmentName: '',
        departmentHead: '',
        employeeName: [],
    });

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const [employees, setEmployees] = useState([]);

    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editDepartmentId, setEditDepartmentId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
        (item.departmentName && item.departmentName.toLowerCase().includes(input.toLowerCase())) ||
        (item.departmentHead && item.departmentHead.toLowerCase().includes(input.toLowerCase()))
    );
        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the departments data in the database
            await updateDoc(doc(db, 'departments', editDepartmentId), formData);

            // Clear the form data and reset the editEmployeeId state
            setFormData({
                departmentName: '',
                departmentHead: '',
                employeeName:[],
            });
            setEditDepartmentId(null);
        } catch (error) {
            console.error('Error updating department:', error);
        }
    };

    const handleDeleteDepartment = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'departments', editDepartmentId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editDepartmentId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting department:', error);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();
                
                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'departments');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'departments');

                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted);
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                if (editDepartmentId) {
                    const selectedDepartment = dataArray.find(item => item.id === editDepartmentId);
                    setFormData(selectedDepartment);
                }
                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, entriesPerPage, editDepartmentId]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Departments</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/departments/department-list">Departments</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Departments List</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                        {(userRole === 'admin' || userRole === 'sys-admin' || userRole === 'hr') && (
                                            <a
                                                href="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_hod_department">
                                                <i className="fa fa-plus"/> Add Department
                                            </a>
                                        )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Department Name</th>
                                                        <th>Department Head</th>
                                                        <th>Employees</th>
                                                        {(userRole === 'admin' || userRole === 'sys-admin' || userRole === 'hr') && (
                                                            <th className="text-end">Action</th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <>
                                                        {filterData().slice(0, entriesPerPage).map((item, index) => (
                                                            <React.Fragment key={item.id}>
                                                                <tr className="clickable" data-toggle="collapse"
                                                                    id={`row${item.id}`} data-target={`.row${item.id}`}>
                                                                    <td>{count + index}</td>
                                                                    <td>{item.departmentName}</td>
                                                                    <td>{item.departmentHead}</td>
                                                                    <td>
                                                                        <Link className="btn btn-sm btn-primary" to="#">
                                                                            View Employees
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                    {(userRole === 'admin' || userRole === 'sys-admin' || userRole === 'hr') && (
                                                                        <div
                                                                            className="dropdown dropdown-action text-end">
                                                                            <a
                                                                                href="#"
                                                                                className="action-icon dropdown-toggle"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false">
                                                                                <i className="material-icons">more_vert</i>
                                                                            </a>
                                                                            <div
                                                                                className="dropdown-menu dropdown-menu-right">
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#edit_hod_department"
                                                                                    onClick={() => setEditDepartmentId(item.id)}>
                                                                                    <i className="fa fa-pencil m-r-5"/> Edit
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#delete_hod_department"
                                                                                    onClick={() => setEditDepartmentId(item.id)}
                                                                                >
                                                                                    <i className="fa fa-trash m-r-5"/> Delete
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    </td>
                                                                </tr>

                                                                <tr className={`collapse row${item.id}`}>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    {item.employeeName && (
                                                                        <div>
                                                                            <ul>
                                                                                {item.employeeName.map((name, index) => (
                                                                                    <li key={index}>{name}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <HodDepartmentForm/>

                            {/* Edit Department Modal */}
                            <div
                                id="edit_hod_department"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Department</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Department Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="departmentName"
                                                                autoComplete="off"
                                                                value={formData.departmentName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Department Head <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="departmentHead"
                                                                autoComplete="off"
                                                                value={formData.departmentHead}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Select Employee</label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                value={Array.isArray(formData.employeeName) ? formData.employeeName.map(selected => ({
                                                                    value: selected,
                                                                    label: selected
                                                                })) : []}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setFormData({
                                                                        ...formData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type="submit">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Department Modal */}

                            {/* Delete Department Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_hod_department"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Department</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <a href="" className="btn btn-primary continue-btn"
                                                           data-bs-dismiss="modal" onClick={handleDeleteDepartment}>
                                                            Delete
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a
                                                            href=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Department Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HodDepartments;

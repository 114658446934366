import React, {useEffect, useState} from "react";
import logo2 from "../../../assets/img/rhokit-logo.png";
import DatePicker from "react-datepicker";
import {Slide, toast, ToastContainer} from "react-toastify";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db, app} from "../../../firebase";
import {countries} from "countries-list";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { getAuth, createUserWithEmailAndPassword, confirmPasswordReset } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const EmployeeRegister = () => {
    const query = useQuery();
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');

    // console.log("Mode", mode);
    // console.log("oobCode", oobCode);
   
    const [loading, setLoading] = useState(false);

    const auth = getAuth(app);
    const navigate = useNavigate();
    let { userId } = useParams();

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError("");
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
        setRepeatPasswordError("");
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleRepeatPasswordVisibility = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };



    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password) {
            toast.error("Password is required.");
            return;
        }

        if (!repeatPassword) {
            toast.error("Please confirm your password.");
            return;
        }

        if (password !== repeatPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        try {
            setLoading(true);
            console.log("password:", password)
            console.log("repeat password:", repeatPassword)

            confirmPasswordReset(auth, oobCode, password)
            .then(() => {
                toast.success('Password set successfully')
                navigate('/')
            })
            .catch((err) => {
                console.log(err)
            })

            // showToast('User Added Successfully', 'success');
            // resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
            showToast('An error occurred while creating the employee.', 'error');
        }
    };

    return (
        <>
            <div className="register">
                <div className="account-page">
                    <div className="main-wrapper">
                        <div className="account-content">
                            <div className="container">
                                <div className="account-logo">
                                    <img src={logo2} alt="Logo"/>
                                </div>

                                <div className="account-box">
                                    <div className="account-wrapper">
                                        <h3 className="account-subtitle">Set your password</h3>

                                        <p className="text-primary text-small">
                                            To get a DTB wallet linked to your account, <Link to="/register">click here</Link>
                                        </p>
                                        <p className="text-primary text-xs">
                                            Otherwise proceed with setting your password
                                        </p>
                                        
                                        <div>
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label className="col-form-label">
                                                                        Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div style={{position: "relative"}}>
                                                                <input
                                                                    type={showPassword ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        passwordError && "is-invalid"
                                                                    }`}
                                                                    name="password"
                                                                    value={password}
                                                                    onChange={handlePasswordChange}
                                                                    placeholder="Enter Password"
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={showPassword ? faEye : faEyeSlash}
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "5%",
                                                                        top: "30%",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={togglePasswordVisibility}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label className="col-form-label">
                                                                        Confirm Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div style={{position: "relative"}}>
                                                                <input
                                                                    type={showRepeatPassword ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        repeatPasswordError && "is-invalid"
                                                                    }`}
                                                                    name="repeatPassword"
                                                                    value={repeatPassword}
                                                                    onChange={handleRepeatPasswordChange}
                                                                    placeholder="Confirm Password"
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={showRepeatPassword ? faEye : faEyeSlash}
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "5%",
                                                                        top: "30%",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={toggleRepeatPasswordVisibility}
                                                                />
                                                            </div>
                                                            <div className="text-danger">
                                                                {repeatPasswordError}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='input-block text-center'>
                                                    <button
                                                        className="btn btn-primary account-btn mt-2" type="submit"
                                                    >
                                                        Set Password
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default EmployeeRegister;

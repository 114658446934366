import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../InitialPage/App.js";
import {collection, doc, getDocs, updateDoc, query, where} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {db} from "../../firebase.js";
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link, NavLink} from "react-router-dom";
import {Avatar_02} from "../../Entryfile/imagepath.js";
import EmployeeForm from "./employeeForm.js";

const auth = getAuth();

const EmployeeCards = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        employeeName: '',
        phone: '',
        email: '',
        maritalStatus: '',
        qualification: '',
    });

    const [data, setData] = useState([]);
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editFormData, setEditFormData] = useState(null);
    const [editEmployeeId, setEditEmployeeId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((employee) =>
            employee &&
            (employee.employeeName && employee.employeeName.toLowerCase().includes(input.toLowerCase())) ||
            (employee.role && employee.role.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(employee => !employee.deleted);
        } else {
            return filteredData.filter(employee => !employee.deleted);
        }
    };

    const handleStatusChange = async (employeeId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-employee', employeeId), { status: newStatus });

            const updatedData = data.map(employee => {
                if (employee.id === employeeId) {
                    return { ...employee, status: newStatus };
                }
                return employee;
            });
            setData(updatedData);

            const updatedFilteredData = filteredData.map(employee => {
                if (employee.id === employeeId) {
                    return { ...employee, status: newStatus };
                }
                return employee;
            });
            setFilteredData(updatedFilteredData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };  

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the employee data in the database
            await updateDoc(doc(db, 'add-employee', editEmployeeId), formData);

            // Clear the form data and reset the editEmployeeId state
            setFormData({
                employeeName: '',
                phone: '',
                email: '',
                maritalStatus: '',
                qualification: '',
            });
            setEditEmployeeId(null);
        } catch (error) {
            console.error('Error updating employee:', error);
        }
    };


    const handleDelete = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'add-employee', editEmployeeId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(employee =>
                employee.id === editEmployeeId ? { ...employee, deleted: true } : employee
            ));

            setFilteredData(filteredData => filteredData.map(item =>
                employee.id === editEmployeeId ? { ...item, deleted: true } : employee
            ));

        } catch (error) {
            console.error('Error soft deleting employee:', error);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'add-employee');
                const colRef = collection(db, 'add-employee');
                // const querySnapshot = await getDocs(
                //     query(colRef, where('organisationId', '==', userData[0]?.organisationId))
                // );
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                // const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                // If there is an employee ID for editing, set the form data
                if (editEmployeeId) {
                    const selectedEmployee = dataArray.find(employee => employee.id === editEmployeeId);
                    setFormData(selectedEmployee);
                }

                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, editEmployeeId]);


    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Employees</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/employees/employees-list">Employees</Link>
                                                </li>
                                                <li className="breadcrumb-item active">All Employees</li>
                                            </ul>
                                        </div>

                                        <div className="col-auto float-end ms-auto">
                                        {(userRole === 'admin' || userRole === 'sys-admin' || userRole === 'hr') && (
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_employee">
                                                <i className="fa fa-plus"/> Add Employee
                                            </Link>
                                        )}
                                            <div className="view-icons">
                                                <NavLink
                                                    to="/employees/employees-cards"
                                                    className="grid-view btn btn-link mx-2"
                                                    activeClassName="active"
                                                >
                                                    <i className="fa fa-th"/>
                                                </NavLink>
                                                <NavLink
                                                    to="/employees/employees-list"
                                                    className="list-view btn btn-link"
                                                    activeClassName="active"
                                                >
                                                    <i className="fa fa-bars"/>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-6">
                                        <div
                                            className={
                                                focused1
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused1(true)}
                                                onBlur={() => setFocused1(false)}
                                                // value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                            <label className="focus-label">Employee Name</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="input-block form-focus select-focus">
                                            <select className="form-select form-control" value={searchInput}
                                                    onChange={handleSearchInputChange}>
                                                {/* {filterData().map(employee => (
                                                    <option>{employee.qualification}</option>
                                                ))} */}
                                                <option value="">Clear</option>
                                                {[...new Set(filterData().map(employee => employee.role))].map(role => (
                                                    <option key={role} value={role}>{role}</option>
                                                ))}
                                            </select>
                                            <label className="focus-label">Role</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row staff-grid-row">
                                    {filterData().map(employee => (
                                        <div key={employee.id} className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                                            <div className="profile-widget">
                                                <div className="profile-img">
                                                    <Link to="#"
                                                          className="avatar">
                                                        <img src={employee.selfieURL || Avatar_02} alt=""/>
                                                    </Link>
                                                </div>
                                                <div className="dropdown profile-action">
                                                    <Link
                                                        to="#"
                                                        className="action-icon dropdown-toggle"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <i className="material-icons">more_vert</i>
                                                    </Link>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#edit_employee"
                                                            onClick={() => setEditEmployeeId(employee.id)}>
                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                        </Link>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#delete_employee"
                                                            onClick={() => setEditEmployeeId(employee.id)}
                                                        >
                                                            <i className="fa-regular fa-trash-can m-r-5"/> Delete
                                                        </Link>
                                                    </div>
                                                </div>
                                                <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                                    <Link to='#'>
                                                        {employee.employeeName}
                                                    </Link>
                                                </h4>
                                                <div className="small text-muted">{employee.role}</div>
                                                <div className="dropdown action-label mt-2">
                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className={`fa-regular fa-circle-dot text-${employee.status === 'active' ? 'success' : 'danger'}`}></i>
                                                        {employee.status === 'active' ? 'Active' : 'Inactive'}
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(employee.id, 'active')}>
                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                        </a>
                                                        <a className="dropdown-item"
                                                           onClick={() => handleStatusChange(employee.id, 'inactive')}>
                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <EmployeeForm/>

                            {/*Edit Employee Form*/}
                            <div id="edit_employee"
                                 className="modal custom-modal fade"
                                 role="dialog"
                                 data-bs-backdrop="static"
                                 data-bs-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered modal-lg"
                                    role="document"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Employee</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                        <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Employee Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="employeeName"
                                                                autoComplete="off"
                                                                value={formData.employeeName}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Phone No</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="phone"
                                                                autoComplete="off"
                                                                value={formData.phone}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Location
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="location"
                                                                autoComplete="off"
                                                                value={formData.location}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">
                                                                Email <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Marital Status</label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="maritalStatus"
                                                            >
                                                                <option value>Single</option>
                                                                <option value={1}>Married</option>
                                                                <option value={2}>Divorced</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Qualification
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="qualification"
                                                                autoComplete="off"
                                                                value={formData.qualification}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Edit Employee Form*/}

                            {/*Delete Employee Form*/}
                            <div
                                className="modal custom-modal fade"
                                id="delete_employee"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Employee</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              onClick={handleDelete} data-bs-dismiss="modal">
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Delete Employee Form*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeCards;

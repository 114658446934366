import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../../../firebase";

const LeaveCategoriesTable = () => {
    const [searchInput, setSearchInput] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [entriesCount, setEntriesCount] = useState(0);
    const [editLeaveCategoryId, setEditLeaveCategoryId] = useState(null);
    const [formData, setFormData] = useState({
        leaveCategory: '',
        gender: '',
        maxNoOfDays: '',
        accrued: '',
        yearStarts: '',
    });

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(leaveCategory => !leaveCategory.deleted);
        } else {
            return filteredData.filter(leaveCategory => !leaveCategory.deleted);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'leave-categories');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(leaveCategory => !leaveCategory.deleted); // Filter out deleted leave categories
                setData(dataArray);
                setEntriesCount(dataArray.length);

                // If there is an ID for editing, set the form data
                if (editLeaveCategoryId) {
                    const selectedLeaveCategory = dataArray.find(leaveCategory => leaveCategory.id === editLeaveCategoryId);
                    setFormData(selectedLeaveCategory);
                }
                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editLeaveCategoryId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Leave Categories</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Leave</th>
                                <th>Accrued</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map((leaveCategory, index) => (
                                <tr key={leaveCategory.id}>
                                    <td>{leaveCategory.leaveCategory}</td>
                                    <td>{leaveCategory.accrued}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/hod/leave-management/leave-categories">View all leave categories</Link>
                </div>
            </div>
        </div>
    )
}

export default LeaveCategoriesTable;

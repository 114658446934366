import React, {useState, useEffect, useContext} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { db } from '../../../../firebase.js';
import {collection, doc, getDocs} from 'firebase/firestore';
import {Link} from "react-router-dom";
import { UserContext } from "../../../../InitialPage/App.js";
import PayrollForm from "./PayrollForm.js";

const RunPayroll = () => {
    const { userRole } = useContext(UserContext);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [data, setData] = useState([]);
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [entriesCount, setEntriesCount] = useState(0);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editPayrollId, setEditPayrollId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);
    };

    const filterData = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Convert to string and pad with leading zero if needed
        const currentMonthYear = `${currentYear}-${currentMonth}`;

        const filtered = data.filter(item =>
            !item.deleted &&
            item.month === currentMonthYear &&
            (item.employeeName.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.grossSalary.toLowerCase().includes(searchInput.toLowerCase()))
        );

        return filtered;
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const colRef = collection(db, "add-salary");
            const querySnapshot = await getDocs(colRef);
            const dataArray = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setData(dataArray);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Run Payroll</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Current Payroll</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="page-menu">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-12">
                                            <div
                                                className={
                                                    focused
                                                        ? "input-block form-focus focused"
                                                        : "input-block form-focus"
                                                }>
                                                <input
                                                    type="text"
                                                    className="form-control floating"
                                                    onFocus={() => setFocused(true)}
                                                    onBlur={() => setFocused(false)}
                                                    value={searchInput}
                                                    onChange={handleSearchInputChange}
                                                />
                                                <label className="focus-label ">Search...</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <h4>{`Payroll for ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}`}</h4>
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table datatable">
                                            <thead>
                                            <tr>
                                                <th>Employee</th>
                                                <th>Currency</th>
                                                <th>Gross</th>
                                                <th>PAYE</th>
                                                <th>NSSF</th>
                                                <th>NHIF</th>
                                                <th>Pension</th>
                                                <th>Total Deductions</th>
                                                <th>Net</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filterData().map(item => (
                                            <tr key={item.id}>
                                                <td>{item.employeeName}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.grossSalary}</td>
                                                <td>{item.payePayable}</td>
                                                <td>{item.nssfContribution}</td>
                                                <td>{item.nhifContribution}</td>
                                                <td>{item.pensionContributions}</td>
                                                <td>{item.totalDeductions}</td>
                                                <td>{item.netPay}</td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <PayrollForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RunPayroll;

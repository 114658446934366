import React, {useContext, useEffect, useState} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "../../../../InitialPage/App.js";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase.js";

const P9Forms = () => {
    const { userRole } = useContext(UserContext);
    const [focused, setFocused] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.employeeName.toLowerCase().includes(input.toLowerCase()) ||
            item.qualification.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const navigate = useNavigate();
    const handleGenerateP9Form = (employee) => {
        console.log("Generating P9 Form for employee:", employee);
        navigate(`/finance/payroll/generated-p9form/${employee.id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRefSalary = collection(db, 'add-salary');
                const querySnapshotSalary = await getDocs(colRefSalary);
                const dataArraySalary = querySnapshotSalary.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const dataWithAdditionalInfo = await Promise.all(dataArraySalary.map(async item => {
                    const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('employeeName', '==', item.employeeName)));
                    const employeeData = employeeQuerySnapshot.docs[0]?.data(); // Added check here
                    if (employeeData) {
                        return {
                            id: item.id,
                            ...item,
                            employeeName: employeeData.employeeName,
                            email: employeeData.email,
                            qualification: employeeData.qualification,
                            netSalary: item.netSalary,
                        };
                    } else {
                        return null; // Return null if no matching document is found
                    }
                }));

                const filteredData = dataWithAdditionalInfo.filter(item => item !== null); // Filter out null entries

                setData(filteredData);
                setEntriesCount(filteredData.length);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">P9 Forms</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/app/main/dashboard">Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/finance/payroll/p9-forms">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">P9 Forms</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input className="form-control form-control-sm" onChange={handleSearchInputChange}/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Salary</th>
                                                        <th>P9 Form</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.employeeName}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.qualification}</td>
                                                            <td>{item.netPay}</td>
                                                            <td>
                                                                <Link to={`/finance/payroll/generated-p9form/${item.id}`}
                                                                      onClick={() => handleGenerateP9Form(item)}
                                                                      className="btn btn-sm btn-primary">
                                                                    Generate Form
                                                                </Link>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="action-icon dropdown-toggle"
                                                                       data-bs-toggle="dropdown"
                                                                       aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#edit_p9form"><i
                                                                            className="fa-solid fa-pencil m-r-5"></i>
                                                                            Edit</a>
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#delete_p9form"><i
                                                                            className="fa-regular fa-trash-can m-r-5"></i>
                                                                            Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    Showing 1 to {entriesCount} of {entriesCount} entries
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default P9Forms;
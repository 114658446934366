import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, doc, getDocs, updateDoc, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const CompanyTable = () => {
    const [data, setData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [formData, setFormData] = useState({
        displayName: '',
        email: '',
        physicalAddress: '',
        postCode: '',
        city: '',
    })

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (companyId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-company', companyId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === companyId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'add-company');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(item => !item.deleted); // Filter out deleted leave categories
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if(editCompanyId){
                    const selectedCompany = dataArray.find(item => item.id === editCompanyId);
                    setFormData(selectedCompany)
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData, loading, editCompanyId]);

    // Rendering only the latest 4 records
    const latestFourRecords = data.slice(0, 4);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="card-title">Companies</h3>
                        </div>

                        <div className="col-auto">
                            <Link className="btn btn-primary "
                                  to="/companies/add-company">
                                Add Company
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                            <tr>
                                <th>Display Name</th>
                                <th>Email</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestFourRecords.map(item => (
                                <tr key={item.id}>
                                    <td>
                                        <h2 className="table-avatar">
                                            <Link to="#">{item.displayName || item.tradingName}</Link>
                                        </h2>
                                    </td>
                                    <td>{item.email}</td>
                                    <td>
                                        <div className="dropdown action-label">
                                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className={`fa-regular fa-circle-dot text-${item.status === 'active' ? 'success' : 'danger'}`}></i>
                                                {item.status === 'active' ? 'Active' : 'Inactive'}
                                            </a>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'active')}>
                                                    <i className="fa-regular fa-circle-dot text-success me-1"></i> Activate
                                                </a>
                                                <a className="dropdown-item"
                                                   onClick={() => handleStatusChange(item.id, 'inactive')}>
                                                    <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deactivate
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestFourRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/companies/companies-list">View my company</Link>
                </div>
            </div>
        </div>
    )
}

export default CompanyTable;

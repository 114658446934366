import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../../firebase";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const ProjectsTable = () => {
    const [editProjectId, setEditProjectId] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [searchInput1, setSearchInput1] = useState('');
    const [searchInput2, setSearchInput2] = useState('');
    const [latestTwoRecords, setLatestTwoRecords] = useState([]);
    
    const [formData, setFormData] = useState({
        projectName: '',
        projectDeadline: '',
        teamName:'',
        projectLeader:'',
        priority: '',
        projectDescription: '',
    });

    useEffect(() => {
        const filtered = data.filter(item =>
            (!item.deleted &&
                (item.projectName && item.projectName.toLowerCase().includes(searchInput1.toLowerCase()) &&
                item.teamName && item.teamName.toLowerCase().includes(searchInput2.toLowerCase()))
            )
        );
        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    }, [data, searchInput1, searchInput2]);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'projects');
                // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setLatestTwoRecords(dataArray.slice(0, 2));
                setEntriesCount(dataArray.length);

                if (editProjectId) {
                    const selectedProject = dataArray.find(item => item.id === editProjectId);
                    setFormData(selectedProject);
                }

                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userData, loading, editProjectId]);
    

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Projects</h3>
                </div>
                <div className="card-body text-center">
                {latestTwoRecords && latestTwoRecords.length > 0 && latestTwoRecords.map(item => (
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-sm-6 col-md-4 col-xl-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="project-title">
                                            <Link to="/app/projects/projects-view">
                                                {item.projectName}
                                            </Link>
                                        </h4>
                                        <p className="text-muted">
                                            {item.projectDescription}
                                        </p>
                                        <div className="project-members m-b-15">
                                            <div>Project Leader :</div>
                                            <ul className="team-members">
                                                <li className="text-muted">
                                                    {item.projectLeader || "No leader assigned"}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="project-members m-b-15">
                                            <div>Team :</div>
                                            <ul className="team-members">
                                                <li className="text-muted">
                                                    {item.teamName || "No team assigned"}
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="project-members m-b-15">
                                            <div>Employees :</div>
                                            <ul className="team-members">
                                            <li className="text-muted">
                                                {item.employeeHours && Array.isArray(item.employeeHours) ? (
                                                    item.employeeHours.map(employeeHour => (
                                                        <li key={employeeHour.employeeName}>{employeeHour.employeeName}</li>
                                                    ))
                                                ) : (
                                                    "No employee hours data available"
                                                )}
                                            </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestTwoRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/hod/timesheets/project-list">View all projects</Link>
                </div>
            </div>
        </div>
    )
}

export default ProjectsTable;

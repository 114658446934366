import React, {useContext, useState} from "react";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import {Link} from "react-router-dom";
import {UserContext} from "../InitialPage/App";
import Calendar from "@ericz1803/react-google-calendar";
import { css } from "@emotion/react";

const GoogleCalendar = () => {
    const { userRole } = useContext(UserContext);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const API_KEY = "AIzaSyBRVcU3iUF5S6-CRUHKsc5tFCYqNty9RtI";
    let calendars = [
        {
            calendarId: "https://calendar.google.com/calendar/embed?src=sealpayroll%40gmail.com&ctz=Africa%2FNairobi",
            color: "#B241D1",
        },
    ];

    let styles = {
        //you can use object styles (no import required)
        calendar: {
            borderWidth: "3px", //make outer edge of calendar thicker
            borderColor: "#00aab9"
        },

        //you can also use emotion's string styles
        today: css`
        color: white;
        background: #ffc000;
  `,
    };

    const language = "EN";

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Calendar</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Calendar</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Calendar apiKey={API_KEY} calendars={calendars}  styles={styles} language={language}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleCalendar;
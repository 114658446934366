import React, {useState, useEffect, useContext} from "react";
import { db } from '../../firebase.js';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Header from "../common/Header.js";
import Sidebar from "../common/Sidebar.js";
import {Link} from "react-router-dom";
import EmployeeDepartmentForm from "./employeeDepartmentForm.js";
import { UserContext } from "../../InitialPage/App.js";

const EmployeeDepartments = () => {
    const { userRole } = useContext(UserContext);
    const [count, setCount] = useState(1);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [formData, setFormData] = useState({
        departmentName: '',
        departmentHead: '',
    });
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editDepartmentId, setEditDepartmentId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            item.departmentName.toLowerCase().includes(input.toLowerCase()) ||
            item.departmentHead.toLowerCase().includes(input.toLowerCase())
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update the departments data in the database
            await updateDoc(doc(db, 'departments', editDepartmentId), formData);

            // Clear the form data and reset the editEmployeeId state
            setFormData({
                departmentName: '',
                departmentHead: '',
            });
            setEditDepartmentId(null);
        } catch (error) {
            console.error('Error updating department:', error);
        }
    };
    const handleDeleteDepartment = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'departments', editDepartmentId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editDepartmentId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting department:', error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'departments');
                const querySnapshot = await getDocs(colRef);
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editDepartmentId) {
                    const selectedDepartment = dataArray.find(item => item.id === editDepartmentId);
                    setFormData(selectedDepartment);
                }
                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editDepartmentId]);
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Departments</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/employees/employees-list">Employees</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Departments</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <a
                                                href="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_department">
                                                <i className="fa fa-plus"/> Add Department
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Department Name</th>
                                                        <th>Department Head</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{count + index}</td>
                                                            <td>{item.departmentName}</td>
                                                            <td>{item.departmentHead}</td>
                                                            <td>
                                                                <div className="dropdown dropdown-action text-end">
                                                                    <a
                                                                        href="#"
                                                                        className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="material-icons">more_vert</i>
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#edit_department"
                                                                            onClick={() => setEditDepartmentId(item.id)}>
                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                        </a>
                                                                        <a
                                                                            className="dropdown-item"
                                                                            href="#"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#delete_department"
                                                                            onClick={() => setEditDepartmentId(item.id)}>
                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    Showing 1 to {entriesCount} of {entriesCount} entries
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <EmployeeDepartmentForm/>

                            {/* Edit Department Modal */}
                            <div
                                id="edit_department"
                                className="modal custom-modal fade"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Department</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-block">
                                                    <label>
                                                        Department Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="departmentName"
                                                        autoComplete="off"
                                                        value={formData.departmentName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="input-block">
                                                    <label>
                                                        Department Head <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="departmentHead"
                                                        autoComplete="off"
                                                        value={formData.departmentHead}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type="submit">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Department Modal */}

                            {/* Delete Department Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_department"
                                role="dialog">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Department</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <a href="" className="btn btn-primary continue-btn"
                                                           data-bs-dismiss="modal" onClick={handleDeleteDepartment}>
                                                            Delete
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a
                                                            href=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Department Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDepartments;

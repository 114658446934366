import React, { useContext, useState, useEffect } from "react";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import CompanyTable from "./Table/CompanyTable";
import EmployeesTable from "./Table/EmployeesTable";
import DepartmentsTable from "./Table/DepartmentsTable";
import TeamsTable from "./Table/TeamsTable";
import LeaveTable from "./Table/LeaveTable";
import LeaveCategoriesTable from "./Table/LeaveCategoriesTable";
import EmployeeSalaryTable from "./Table/EmployeeSalaryTable";
import PayrollVarianceTable from "./Table/PayrollVarianceTable";
import PayrollTableShortcuts from "./Table/PayrollTableShortcuts";
import { toast } from "react-toastify";

const auth = getAuth();

function SysAdminDashboardHome(){
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!auth.currentUser) {
                toast.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                toast.error(
                    "User not found. Please register an account on astra first on registration page"
                );
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUserData(userDataArray);
        };

        fetchUserData();
    },[auth.currentUser]);

    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [leaves, setLeaves] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];

            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (companyData.status === 'active' && !companyData.deleted) {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });

            setCompanies(companyList);
        };

        fetchCompanies();

        const fetchDepartments = async () => {
            const querySnapshot = await getDocs(collection(db, 'departments'));
            const departmentList = [];

            querySnapshot.forEach((doc) => {
                const departmentData = doc.data();
                if (!departmentData.deleted) {
                    departmentList.push({ id: doc.id, ...departmentData });
                }
            });

            setDepartments(departmentList);
        };
        fetchDepartments();

        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                if (employeeData.status === 'active' && !employeeData.deleted) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });

            setEmployees(employeeList);
        };

        fetchEmployees();

        const fetchLeaves = async () => {
            const querySnapshot = await getDocs(collection(db, 'issue-leave'));
            const leaveList = [];

            querySnapshot.forEach((doc) => {
                const leaveData = doc.data();
                if (!leaveData.deleted) {
                    leaveList.push({ id: doc.id, ...leaveData });
                }
            });

            setLeaves(leaveList);
        };
        fetchLeaves();
    }, []);

    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                        {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                {/* <h3 className='page-title'>Welcome {user.companyName ? user.companyName : `${user.firstName} ${user.lastName}`}!</h3> */}
                                {/* <h3 className='page-title'>Welcome {user.firstName} {user.lastName}!</h3> */}
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-users'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{companies.length}</h3>
                                        <span>Active Companies</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-building'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{departments.length}</h3>
                                        <span>Departments</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-user'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{employees.length}</h3>
                                        <span>Active Employees</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-user-minus'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{leaves.length}</h3>
                                        <span>Leave Requests</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <CompanyTable/>
                        <EmployeesTable/>
                    </div>

                    <div className="row">
                        <DepartmentsTable/>
                        <TeamsTable/>
                    </div>

                    <div className="row">
                        <LeaveCategoriesTable/>
                        <LeaveTable/>
                    </div>

                    <div className="row">
                        <PayrollVarianceTable/>
                        <EmployeeSalaryTable/>
                    </div>

                    <div className="row">
                        <PayrollTableShortcuts/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SysAdminDashboardHome;

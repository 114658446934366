import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {db} from "../../../../firebase";

const auth = getAuth();

const HolidayTable = () => {
    const [formData, setFormData] = useState({
        holidayName: '',
        holidayDate: '',
    });
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [userData, setUserData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [editHolidayId, setEditHolidayId] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(holiday => !holiday.deleted);
        } else {
            return filteredData.filter(holiday => !holiday.deleted);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colRef = collection(db, 'holidays');
                let querySnapshot;

                if (userData.length > 0 && userData[0]?.role !== 'sys-admin') {
                    if (userData[0]?.organisationId) {
                        querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0].organisationId)));
                    } else {
                        console.error('Organisation ID is undefined');
                        return;
                    }
                } else {
                    querySnapshot = await getDocs(colRef);
                }
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(holiday => !holiday.deleted);
                // const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(dataArray);
                setEntriesCount(dataArray.length);

                if (editHolidayId) {
                    const selectedHoliday = dataArray.find(holiday => holiday.id === editHolidayId);
                    setFormData(selectedHoliday);
                }
                console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!loading) {
            fetchData();
        }
    }, [userData, loading, editHolidayId]);

    // Rendering only the latest 9 records
    const latestNineRecords = data.slice(0, 9);

    return (
        <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Holidays</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0 text-center">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Title</th>
                            </tr>
                            </thead>
                            <tbody>
                            {latestNineRecords.map((holiday, index) => (
                                <tr key={holiday.id} className="holiday-completed">
                                    <td>{count + index}</td>
                                    <td>{holiday.holidayName}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span>
                        <small>
                            Showing {latestNineRecords.length} of {entriesCount} entries
                        </small>
                    </span>
                </div>
                <div className="card-footer">
                    <Link to="/employees/employee-holidays">View all holidays</Link>
                </div>
            </div>
        </div>
    )
}

export default HolidayTable;

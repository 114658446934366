import React, {useState, useEffect}  from "react";
import { db } from '../../firebase.js';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { ToastContainer, toast, Slide  } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const EmployeeOvertimeForm = () => {
    const [formData, setFormData] = useState({
        employeeName: '',
        overtimeDate: '',
        overtimeHours: '',
        overtimeType: '',
        overtimeDescription: '',
    });

    const [employees, setEmployees] = useState([]);

    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];
            querySnapshot.forEach((doc) => {
                employeeList.push({ id: doc.id, ...doc.data() });
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            // Form is invalid, do not proceed with submission
            showToast('Form cannot be empty!', 'error');
            return;
        }

        let overtimeDateString = null;
        if (selectedDate1) {
            const year = selectedDate1.getFullYear();
            const month = String(selectedDate1.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate1.getDate()).padStart(2, '0');
            overtimeDateString = `${year}-${month}-${day}`;
        }

        try {
            const docRef = await addDoc(collection(db, 'overtime'), {
                ...formData,
                overtimeDate: overtimeDateString,
            });
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Overtime Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };


    return (
        <>
            {/* Add Overtime Modal */}
            <div
                id="add_overtime"
                className="modal custom-modal fade"
                role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Overtime</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="input-block">
                                    <label>Select Employee</label>
                                    <select
                                        className="form-select form-control"
                                        name="employeeName"
                                        value = {formData.employeeName}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select an employee</option>
                                        {employees.map(employee => (
                                             <option>
                                                {employee.employeeName}
                                                </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-block">
                                    <label>
                                        Overtime Date <span className="text-danger">*</span>
                                    </label>
                                    <div className="cal-icon">
                                        <DatePicker
                                            selected={selectedDate1}
                                            onChange={handleDateChange1}
                                            name="overtimeDate"
                                            className="form-control floating datetimepicker"
                                            type="date"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-block">
                                    <label>
                                        Overtime Hours <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="overtimeHours"
                                        value={formData.overtimeHours}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="input-block">
                                    <label>
                                        Overtime Type <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="overtimeType"
                                        value={formData.overtimeType}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="input-block">
                                    <label>
                                        Description
                                    </label>
                                    <textarea
                                        rows={4}
                                        type="text"
                                        className="form-control"
                                        name="overtimeDescription"
                                        value={formData.overtimeDescription}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Overtime Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default EmployeeOvertimeForm;
